import { DocumentNode, OperationVariables, TypedDocumentNode, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

function useGeneratedTypeQuery<T, I = {}>(query: DocumentNode | TypedDocumentNode<T, OperationVariables>, input: I) {
  const [dataFromQuery, setDataFromQuery] = useState<T>();

  const { data, refetch, fetchMore, loading, error } = useQuery<T>(query, {
    variables: {
      input,
    },
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      return console.log(error);
    }

    setDataFromQuery(data);
  }, [loading, error, data]);

  return { dataFromQuery, refetch, fetchMore };
}

export default useGeneratedTypeQuery;
