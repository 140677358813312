export const EUR = "5GxuHHLS2bEgMBr7gxAtMVoXZMHodgiuYr916ynRW1bL";
export const USD = "A5bc1juag7zbehskoUHNnPeRQvDXCkjY13H6V36tu4v3";
export const GBP = "Ept6G8KUpxduLawAMHmbDY71vVRXjj16F27RGka8pTiV";
export const THG23A = "8PgbUttAThYFYZAVDhccdbbxyte3gPXXPKtPaB8NdQPa";
export const THG23O = "HmkNUeRXKpckZ6AtmWKwQ5fWnqYczMkyDJDdUe16q9qg";
export const THG24O = "81Rf6Gb5xHEqeYCGk8irhvisQqFwZpwCJocsvAv51gF9";
export const HBE23A = "7EGKKgid6K9kn7MMJmPweywR4BUWmvmEKoQB4HRsGBPL";
export const UMEGO = "AiKzgTgvLMEjPFZ4NS6f7jX1BiE5cnRJT8rGA38gV5st";
export const HVOUCOSPOT = "AgvqWpZ9eUDPdw5cU1z898VNS5Pb4F9AXA3LaUmbqrFB";
export const UCOEXWORKS = "4HwhHVnB44gvb8at4J5bGQm4TSviULjYoWu7YsCwTGFi";
export const UCOCIFRTD = "3VLg1HRyfmi3KVr1nUN98qCiM37dxueRQ8qhcvcJuknm";
export const RTFC23NC = "9pRXjy3N5ikMFgtGwKz67gxsvhu6CZUJP7rf85ZTfaLL";
export const UER2023 = "46iw2vcefF9qpGDTaDvy26Qfnd4ZWyUYzPkxh5DXT2bk";
