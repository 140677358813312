import React, { useState, useEffect, useMemo } from "react";
import AddFormGeneric from "../../components/AddFormGeneric/AddFormGeneric";
import { gql } from "@apollo/client";
import { toast } from "react-toastify";
// import { withRouter  } from "react-router-dom";
// import "./AddDealPage.css"

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});

const AddFormProduct = (props) => {
  const graphqlQuery = useMemo(
    () => gql`
      query getProductOptions {
        categories {
          id
          name
        }
        brokers {
          id
          brokerName
        }
      }
    `,
    []
  );

  const formFieldsArray = [
    // {title: "Product Info", fieldType: "titleSection", rank: 0},
    { name: "name", label: "Product Name", rank: 1, fieldType: "text", yupType: "string", required: true, defaultValue: "" },
    { name: "newLine", rank: 2.9, fieldType: "newLine" },
    {
      name: "categoryId",
      label: "Category",
      rank: 14,
      fieldType: "select",
      yupType: "integer",
      required: true,
      defaultValue: "",
      optionObjects: "categories",
      optionFieldsToDisplay: ["id", "name"],
    },
  ];

  const [formFields, setFormFields] = useState(formFieldsArray);
  const [initialValues, setInitialValues] = useState(() => (props.initialValues ? props.initialValues : getInitialValues(formFields)), []);

  useEffect(() => {
    if (!props.newInput) {
      setFormFields([{ name: "id", label: "ID", rank: 1, fieldType: "immutable", yupType: "int", required: true, defaultValue: "0" }, ...formFields]);
    }
  }, []);

  useEffect(() => {
    let newInitialValues;
    if (props.searchInput || props.searchInput === "") {
      newInitialValues = Object.keys(initialValues).reduce((acc, initialValueKey) => {
        if (initialValueKey === "name") {
          return { ...acc, name: props.searchInput };
        }
        if (initialValueKey === "newLine") {
          return { ...acc };
        }
        const fieldValuePair = {};
        fieldValuePair[initialValueKey] = initialValues[initialValueKey];
        return { ...acc, ...fieldValuePair };
      }, {});
      setInitialValues(() => newInitialValues);
    }
  }, [props.searchInput]);

  const mutationKeys = (values) => {
    const variables = {
      name: values.name,
      categoryId: values.categoryId,
    };

    console.log("mutaitonkeys variables new product");
    console.log(variables);
    return variables;
  };

  const graphqlMutation = gql`
    mutation Product($name: String!, $categoryId: ID) {
      product(name: $name, categoryId: $categoryId) {
        id
        name
        category {
          id
          name
        }
      }
    }
  `;

  const afterSubmit = (values) => {
    toast.success(`New product submitted: ${values.name ? values.name : undefined}.`);
    if (props.closeAfterSubmit) {
      props.closeAfterSubmit();
    }
    if (props.refetch) {
      props.refetch();
    }
  };

  return (
    <>
      <AddFormGeneric
        className="addFormGeneric"
        formFields={formFields}
        initialValues={initialValues}
        newInput={true}
        graphqlQuery={graphqlQuery}
        graphqlMutation={graphqlMutation}
        mutationKeys={mutationKeys}
        graphql={true}
        afterSubmit={afterSubmit}
      />
    </>
  );
};

// export default withRouter(AddDealForm)
export default AddFormProduct;
