import { useState, useMemo, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

function useProduct() {
  const [product, setProduct] = useState([]);
  const graphqlQuery = useMemo(
    () => gql`
      query getProducts {
        products {
          id
          name
          category {
            id
            name
          }
          isActive
        }
      }
    `,
    []
  );

  const { loading, error, data, refetch } = useQuery(graphqlQuery);

  useEffect(() => {
    setProduct(() => {
      if (loading) {
        return [];
      } else if (error) {
        return `Error ${error}`;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch]);

  return [product, refetch];
}

export default useProduct;
