import React, { useMemo } from "react";
import TableExpandingBackendPagination from "../Table/TableExpandingBackendPagination";
import SingleInvoice from "./SingleInvoice";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import helpers from "../../services/helpers";
import Moment from "react-moment";

function InvoicesTableExpanding(props) {
  // filterdata by thom
  const filterData = (data) => {
    const filteredData = [...data].map((invoice) => {
      const invoiceFiltered = {
        ...invoice,
        totalAmountFormatted: helpers.formatMoney(invoice.totalAmount ? invoice.totalAmount / 100 : 0, invoice.currency.description ?? ""),
        totalAmountEuroFormatted: helpers.formatMoney(invoice.totalAmountEur ? invoice.totalAmountEur / 100 : null, "EUR"),
        dateInvoiceFormatted: invoice.dateInvoice ? <Moment format="YYYY-MM-DD">{invoice.dateInvoice}</Moment> : "",
        updatedAtFormatted: invoice.updatedAt ? <Moment format="YYYY-MM-DD HH:mm:ss">{invoice.updatedAt}</Moment> : "",
      };
      return invoiceFiltered;
    });
    return filteredData;
  };

  const columns = [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: "expander", // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
      ),
    },
    { Header: "ID", accessor: "id" },
    { Header: "OrderNumber", accessor: "orderNumber" },
    { Header: "InvoiceNumber", accessor: "invoiceNumber" },
    { Header: "Client", accessor: "client.companyName" },
    { Header: "Description", accessor: "description" },
    { Header: "DateInvoice", accessor: "dateInvoiceFormatted" },
    { Header: "Amount", accessor: "totalAmountFormatted" },
    { Header: "EUR Amount", accessor: "totalAmountEuroFormatted" },
    { Header: "Type", accessor: "type" },
    // { Header: "allocation", accessor: "allocatedToAllExpectedRevenue" },
    { Header: "Allocation", accessor: "allocationStatus" },
    { Header: "Status", accessor: "invoiceStatus.name" },
    { Header: "UpdatedAt", accessor: "updatedAtFormatted" },
    // { Header: 'Invoices Status', accessor: 'invoiceStatus' },
    // { Header: 'Invoices Actions', accessor: 'invoiceActions' },
  ];

  const renderRowSubComponent = React.useCallback((data, rowOriginal) => {
    return (
      <>
        <SingleInvoice data={rowOriginal} key={rowOriginal && rowOriginal.id ? rowOriginal.id : undefined} />
      </>
    );
  }, []);

  if (props && props.data) {
    return (
      <div>
        <TableExpandingBackendPagination renderRowSubComponent={renderRowSubComponent} data={props.data} columns={columns} filterData={filterData} />
      </div>
    );
  } else {
    return (
      <>
        <div>No data for table</div>
      </>
    );
  }
}

export default InvoicesTableExpanding;
