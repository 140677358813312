import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_BROKERS from "./query_brokers";

function useBrokers() {
  const [brokers, setBrokers] = useState(false);

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_BROKERS, { notifyOnNetworkStatusChange: false, nextFetchPolicy: "standby" });

  useEffect(() => {
    // refetch()
    console.log("useEffect useBrokers");
    // console.log(networkStatus);
    console.log(data);
    console.log(loading);
    setBrokers(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting brokers");
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch]);

  return [brokers, refetch, networkStatus];
  // return [data, refetch, networkStatus];
  // return ({ loading, error, data, refetch, networkStatus })
}

export default useBrokers;
