import format from "date-fns/format";
import eachYearOfInterval from "date-fns/eachYearOfInterval";

export default function paymentPerformaneDataFormatting(hasNegativeValue, data, thisYear) {
  if (hasNegativeValue) {
    // get all the future payments
    const futurePayments = data
      ? data
          .map((el, index) => {
            return {
              id: index + 6,
              label: new Date(el.expectedAfter).getFullYear().toString(),
              expectedRevenue: el.expectedAmountEur.totalSum / 100,
              dateAfter: el.expectedAfter,
              dateBefore: el.expectedBefore,
              type: "future",
            };
          })
          .slice(1) // do not include 2013-now (is first element of array), as this is already covered with split of currentPayments
      : [];

    const currentPayments =
      data && data.length > 0
        ? [
            { id: 1, label: "Paid", expectedRevenue: data[0].paidEur.totalSum / 100, type: "thisyear", color: "#22B0FF" },
            { id: 2, label: "Due", expectedRevenue: data[0].due.totalSum / 100, type: "thisyear", color: "#007bff" },
            { id: 3, label: "Overdue", expectedRevenue: data[0].overdue.totalSum / 100, type: "thisyear", color: "#FF0000" },
            {
              id: 4,
              label: "Overdue > 90 days",
              expectedRevenue: data[0].overdue90.totalSum / 100,
              clients: data[0].overdue90.clients,
              type: "thisyear",
              color: "#d80000",
            },
            {
              id: 5,
              label: "?",
              expectedRevenue:
                (parseInt(data[0].expectedAmountEur.totalSum) - data[0].due.totalSum - data[0].overdue.totalSum - data[0].paidEur.totalSum) / 100,
              type: "thisyear",
              color: "#e8e8e8",
            },
          ]
        : [];

    // combine the payments data
    const allPayments = [...currentPayments, ...futurePayments];

    const paidEur = {};

    return { allPayments, paidEur };
  } else {
    // get all the future payments
    const futurePayments = data
      ? data
          .map((el, index) => {
            return {
              id: index + 6,
              label: new Date(el.expectedAfter).getFullYear().toString(),
              expectedRevenue: el.expectedAmountEur.totalSum / 100,
              clients: el.expectedAmountEur.clients,
              dateAfter: el.expectedAfter,
              dateBefore: el.expectedBefore,
              type: "future",
              color: "white",
            };
          })
          .slice(1) // do not include 2013-now (is first element of array), as this is already covered with split of currentPayments
      : [];

    // get all the payments untill today
    const getId = (year) => {
      return parseInt(year) - 2000;
    };
    const years = eachYearOfInterval({ start: new Date("2019-01-02"), end: new Date() }).map((date) => format(date, "yyyy"));
    const paymentsPerInvoiceYear =
      data && data.length > 0
        ? years.map((year) => {
            const objectKey = `paidEur${year}`;
            const expectedRevenue = objectKey in data[0] && "totalSum" in data[0][objectKey] ? data[0][objectKey].totalSum / 100 : 0;
            const clients = objectKey in data[0] && "clients" in data[0][objectKey] ? data[0][objectKey].clients : [];

            return {
              id: getId(year),
              label: year,
              expectedRevenue: expectedRevenue,
              clients: clients,
              type: year === thisYear ? "thisyear" : "past",
              color: "#22B0FF",
            };
          })
        : [];

    const currentPayments =
      data && data.length > 0
        ? [
            ...paymentsPerInvoiceYear,
            { id: 2, label: "Due", expectedRevenue: data[0].due.totalSum / 100, clients: data[0].due.clients, type: "thisyear", color: "#007bff" },
            { id: 3, label: "Overdue", expectedRevenue: data[0].overdue.totalSum / 100, clients: data[0].overdue.clients, type: "thisyear", color: "#FF0000" },
            {
              id: 4,
              label: "Overdue > 90 days",
              expectedRevenue: data[0].overdue90.totalSum / 100,
              clients: data[0].overdue90.clients,
              type: "thisyear",
              color: "#d80000",
            },
            {
              id: 5,
              label: "?",
              expectedRevenue:
                (parseInt(data[0].expectedAmountEur.totalSum) -
                  data[0].due.totalSum -
                  data[0].overdue.totalSum -
                  data[0].overdue90.totalSum -
                  data[0].paidEur.totalSum) /
                100,
              type: "thisyear",
              color: "#e8e8e8",
            },
          ]
        : [];

    const paidEur =
      data && data.length > 0
        ? {
            id: 1,
            label: "Paid",
            expectedRevenue: data[0].paidEur.totalSum / 100,
            clients: data[0].paidEur.clients,
            type: "thisyear",
            color: "#22B0FF",
          }
        : {};

    // combine the payments data
    const allPayments = [...currentPayments, ...futurePayments];

    return { allPayments, paidEur };
  }
}
