import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_INVOICES from "./query_invoices";

function useInvoices(args) {
  const [invoices, setInvoices] = useState(false);

  const [previousCursors, setPreviousCursors] = useState([]);
  // const pageCursorStart = invoices ? invoices[0] : undefined;
  const pageCursorEnd = invoices && invoices.length > 0 ? invoices.slice(-1)[0].id : undefined;

  const [input, setInput] = useState({
    ids: args && args.ids ? args.ids : undefined,
    description: args && args.description ? args.description : undefined,
    take: args && args.take ? parseInt(args.take) : 30,
  });

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_INVOICES, {
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "no-cache",
    fetchPolicy: "no-cache",
    variables: {
      input: { ...input },
    },
  });

  const navigateForward = () => {
    setInput({ ...input, cursor: pageCursorEnd });
    fetchMore({ variables: { input: { ...input } } });
    if (pageCursorEnd !== 0) {
      setPreviousCursors(() => [...previousCursors, pageCursorEnd]);
    }
  };

  const navigateBack = () => {
    const previousPreviousCursorTemp = previousCursors.length <= 2 ? undefined : previousCursors.slice(-2)[0];
    setInput({ ...input, cursor: previousPreviousCursorTemp });
    fetchMore({ variables: { input: { ...input } } });
    setPreviousCursors(() => [...previousCursors.slice(0, -1)]);
  };

  useEffect(() => {
    setInvoices(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting invoices");
        return false;
      } else if (data && "invoices" in data) {
        return data.invoices;
      } else {
        return false;
      }
    });
  }, [loading, error, data, refetch, input]);

  return { invoices, navigateForward, navigateBack, input, setInput };
}

export default useInvoices;
