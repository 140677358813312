/* eslint-disable react/no-danger, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TimelinePostStandard from "./TimelinePostStandard";

import olyxbase from "../../services/olyxbase";

const useStyles = makeStyles((theme) => ({
  paper: {
    transformOrigin: "top right",
  },
  list: {
    width: theme.spacing(40),
    maxHeight: theme.spacing(40),
    overflow: "auto",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

const timelineData = [
  {
    id: 1,
    product: "EtOH (FOB Brazil)",
    title: "EB Updated EtOH (FOB Brazil)",
    text: '495 -> 500 (<span style="color: green">+1.01%</span>)',
    timeago: "29 Seconds Ago",
    icon: "up",
    who: "EB",
    direction: "up",
  },
  {
    id: 2,
    product: "UCO $ (Cif Rdt)",
    title: "SC Confirmed UCO$ (Cif Rtd)",
    text: "<B>990</B>",
    timeago: "1 Day Ago",
    icon: "same",
    who: "SC",
    direction: "same",
  },
  {
    id: 3,
    product: "TRADE",
    title: "",
    text: "997",
    timeago: "1 Day Ago",
    icon: "star",
    who: "AB",
    direction: "up",
  },
];

export default function Timeline() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    let histories = [];
    olyxbase.getHistory().then((hist) => {
      // dphsq.id, dphsq.agent, dphsq.info, dphsq.product, dphsq."productId", dphsq.timestamp, prev_timestamp, dph.price as new_price
      //,dphsq.price as old_price, dph.price - dphsq.price as price_diff, dphsq."extraInfo"

      if (hist !== undefined) {
        for (var hi = 0; hi < hist.length; hi++) {
          let currHist = hist[hi];

          let direction = "same";
          let icon = "star";
          if (currHist.new_price > currHist.old_price) {
            direction = "up";
            icon = "up";
          }
          if (currHist.new_price < currHist.old_price) {
            direction = "down";
            icon = "down";
          }

          let txtS = '<span style="color: #f2c80f; font-size: 18px">' + +currHist.new_price.toFixed(2) + "</span> ";

          if (currHist.new_price - currHist.old_price > 0) {
            txtS += '<span style="color: black; background-color: #c5f9c3; padding-left: 5px; padding-right: 5px;">+';
            txtS +=
              +(currHist.new_price - currHist.old_price).toFixed(2) +
              " ▲ " +
              +(((currHist.new_price - currHist.old_price) / currHist.old_price) * 100).toFixed(2) +
              "%</span>";
          }

          if (currHist.new_price - currHist.old_price < 0) {
            txtS += '<span style="color: black; background-color: #e7adad; padding-left: 5px; padding-right: 5px">';
            txtS +=
              +(currHist.new_price - currHist.old_price).toFixed(2) +
              " ▼ " +
              +(((currHist.new_price - currHist.old_price) / currHist.old_price) * 100).toFixed(2) +
              "%</span>";
          }

          if (currHist.new_price - currHist.old_price == 0) {
            txtS += '<span style="color: white; padding-left: 5px; padding-right: 5px">';
            txtS +=
              +(currHist.new_price - currHist.old_price).toFixed(2) +
              " - " +
              +(((currHist.new_price - currHist.old_price) / currHist.old_price) * 100).toFixed(2) +
              "%</span>";
          }

          // txtS += '<br /> <span style="color: #00ccff">' + currHist.fullName + '</span>';

          if (currHist.extraInfo) {
            txtS += "<br />" + currHist.extraInfo;
          }

          let thisHist = {
            id: currHist.id,
            title: currHist.product + " - " + currHist.info + "",
            text: txtS,
            timeago: currHist.timestamp,
            icon: icon,
            direction: direction,
            avatar: currHist.imageUrl,
          };

          histories.push(thisHist);
        }

        setTimelineData(histories);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <h1>Timeline</h1>

      {timelineData.map((item) => (
        <TimelinePostStandard timelineData={item} />
      ))}
    </React.Fragment>
  );
}
