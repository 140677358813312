import React, { useMemo } from "react";
import "./AutoCompleteInput.css";
import { useField, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CssTextField from "./CssTextField";

function TextInputLimitedOptions({ label, optionObjects, optionFieldsToDisplay, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const { values } = useFormikContext();

  // create const options
  const options = useMemo(() => {
    if (props.limitOptions && values && values.productId && optionObjects) {
      return props.limitOptions(values.productId, optionObjects, props.sortingAlgorithm ?? null);
    }
    return [];
  }, [values.productId, optionObjects, props]);

  const getCustomOptionLabel = (option) => {
    return option;
  };

  const showField = (props, values) => {
    if (!props.showOnCondition) {
      return true;
    } else if (props.showOnCondition) {
      if (props.showOnCondition(values)) {
        return true;
      } else {
        // empty field
        // setValue( "" )
        return false;
      }
    }
  };

  return (
    <>
      {showField(props, values) && (
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Typography variant="body2">
            {label}
            {props.required && "*"}
          </Typography>
          <AutoComplete
            id={props.name}
            value={value === undefined ? "" : value.toString()}
            fullWidth={true}
            options={["", ...options]}
            getOptionLabel={(option) => getCustomOptionLabel(option)}
            // style={{ maxWidth: 300}}
            onChange={(event, value) => setValue(value !== null ? value : "")}
            {...props}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label={props.label}
                inputProps={{ ...params.inputProps, style: { color: "#22b0ff" } }}
                variant="outlined"
                color="primary"
              />
            )}
            renderOption={(option) => {
              return getCustomOptionLabel(option);
            }}
            includeInputInList={true}
            autoSelect={true}
            autoComplete={true}
          />
          {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </Grid>
      )}
    </>
  );
}

export default TextInputLimitedOptions;
