import { useState } from "react";
import FormLongTermDealsProps from "../../interfaces/FormLongTermDealsProps";
import { Close as CloseIcon } from "@material-ui/icons";
import PaymentStatusIcon from "../PaymentStatusIcon/PaymentStatusIcon";
import { isValid, format } from "date-fns";
import useUpdateExpectedRevenue from "../../typedHooks/mutations/useUpdateExpectedRevenue";
import helpers from "../../services/helpers";
import { motion } from "framer-motion";

const FormLongTermDeals = (props: FormLongTermDealsProps) => {
  const defaultCurrencyId = 2;
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [dateExpected, setDateExpected] = useState<string>(props.dateExpected ? props.dateExpected : "");
  const [note, setNote] = useState<string>(props.note ? props.note : "");
  const [currencyId, setCurrencyId] = useState<number>(props.currencyId ? props.currencyId : defaultCurrencyId);
  const [totalAmount, setTotalAmount] = useState<string>(props.totalAmount || props.totalAmount === 0 ? String(props.totalAmount) : "");
  const [newExpectedRevenue, setNewExpectedRevenue] = useState<number>(0);
  const currencies = helpers.currencies;

  // sets the values to default when closing or submitting expRevenue
  const refreshToDefault = () => {
    setDateExpected("");
    setCurrencyId(defaultCurrencyId);
    setTotalAmount("");
  };

  const updateExpectedRevenue = useUpdateExpectedRevenue();

  const handleSubmit = async (totalAmount: string, clientId: string, currencyId: number, date: string, dealId: string, tradeSide: string, note: string) => {
    try {
      // validation
      const fdate = Date.parse(date);
      if (!isValid(fdate)) return alert("Date must be in dd mmm yyyy format");
      if (totalAmount === "" || isNaN(Number(totalAmount))) return alert("Amount must be a number");
      // increasing the counter to show we created one expected revenue if everything went well
      const localDate = new Date(fdate);
      const dateExpectedToUTC = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
      await updateExpectedRevenue({
        variables: {
          input: {
            id: props.expRevId,
            currencyId: currencyId,
            totalAmount: Math.floor(Number(totalAmount) * 100),
            dateExpected: dateExpectedToUTC,
            tradeSide: tradeSide,
            dealId: parseInt(dealId),
            clientId: parseInt(clientId),
            note: note,
          },
        },
      }).then((value) =>
        value.data?.createexpectedrevenue?.__typename === "ExpectedRevenue"
          ? setNewExpectedRevenue(newExpectedRevenue + 1)
          : alert("Something went wrong, try again")
      );
    } catch (err) {
      console.log(err);
      alert("Something went wrong, try again");
    }
  };

  return (
    <>
      {props.currentUserIsAdmin && (
        <div className="content-center label-text">
          {(isFormOpen || props.updatingExpRevenue) && (
            <div
              className={`relative mb-[4px] min-h-[34px] content-center grid justify-items-center w-full grid-cols-6 gap-4 col-span-6 border-gray-200 border rounded-xl py-1 relative`}
            >
              <div className="col-span-1">
                <PaymentStatusIcon size={"medium"} additionalStyle="border-gray-200 border" invoiceStatusSummary="FUTURE"></PaymentStatusIcon>
              </div>
              <div className="col-span-1 border border-gray-200 rounded-xl">
                <select
                  defaultValue={currencyId}
                  className=" text-[#7E8085] pl-2 bg-white rounded-xl text-center center focus:outline-none appearance-none hover:text-[#4c4c4e]"
                  onChange={(e) => {
                    setCurrencyId(parseInt(e.target.value) ? parseInt(e.target.value) : defaultCurrencyId);
                  }}
                >
                  {currencies.map((currency) => {
                    return (
                      <option value={currency.id} key={currency.id} onClick={() => setCurrencyId(currency.id)}>
                        {currency.symbol}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-20 pl-1 rounded-xl text-[#7E8085] focus:outline-none"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
              </div>
              <div className="col-span-2 m-auto border border-gray-200 rounded-xl ">
                <input
                  type="text"
                  className="w-24 m-auto text-[#7E8085] text-center rounded-xl px-1 focus:outline-none"
                  value={dateExpected}
                  placeholder={`${format(new Date(Date.now()), "dd MMM yyyy")}`}
                  onChange={(e) => setDateExpected(e.target.value)}
                />
              </div>
              <div className="col-span-2 text-[#7E8085]">
                <button
                  type="submit"
                  className="rounded mr-5 px-2 bg-primary-blue text-white"
                  onClick={() => {
                    handleSubmit(totalAmount, props.clientId, currencyId, dateExpected, props.dealId, props.tradeSide, note);
                    setIsFormOpen(false);
                    refreshToDefault();
                    props.setExpectedRevenueIsChanging && props.setExpectedRevenueIsChanging(0);
                  }}
                >
                  {props.updatingExpRevenue ? "Update" : "Create"}
                </button>
                <motion.button
                  onClick={() => {
                    setIsFormOpen(false);
                    refreshToDefault();
                    props.setExpectedRevenueIsChanging && props.setExpectedRevenueIsChanging(0);
                  }}
                  initial={{ scale: 1 }}
                  whileHover={{ scale: 1, rotate: 90 }}
                  whileTap={{ scale: 1 }}
                  className="transform"
                >
                  <CloseIcon className="hover:text-[#4c4c4e]" fontSize="small"></CloseIcon>
                </motion.button>
              </div>
              <div className="absolute flex w-[240px] items-center justify-center bottom-[-16px] right-[10px] h-[16px] rounded-br-[8px] rounded-bl-[8px] px-[8px] pt-[2px] bg-[#aeaeae]">
                <input
                  type="text"
                  className="leading-none h-[12px] bg-transparent text-[12px] w-full text-white placeholder-[#e6e6e6] outline-none font-smallText"
                  value={note}
                  placeholder={"Add a note"}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          )}
          {!(isFormOpen || props.updatingExpRevenue) && props.currentUserIsAdmin && (
            <div>
              <button className="text-xs" onClick={() => setIsFormOpen(true)}>
                + add expRev
              </button>
              {newExpectedRevenue !== 0 && <p> You added {newExpectedRevenue} expected revenues</p>}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FormLongTermDeals;
