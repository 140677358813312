import React, { useState } from "react";
import BidOfferEntryModal from "../../components/BidOfferEntryModal/BidOfferEntryModal";
import MentionNotificationIconButton from "../MentionNotificationIconButton/MentionNotificationIconButton";
import PriceChangeNotificationDropdown from "../PriceChangeNotificationDropdown/PriceChangeNotificationDropdown";
import Avatar from "../Avatar/Avatar";
import OlyxLogo from "../../assets/olyx-logo.png";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { AnimatePresence } from "framer-motion";
import Sidebar from "../Sidebar/Sidebar";
import ProfileMenu from "../ProfileMenu/ProfileMenu";

const Bar = ({
  user,
  localUser,
  onAboutClick,
  onSignOutClick,
  onSignInClick,
}: {
  user: any;
  localUser: { id: number; userUid: string; imageUrl: string; fullName: string; initials: string };
  onAboutClick: () => void;
  onSignOutClick: () => void;
  onSignInClick: () => void;
}) => {
  const [openSideBar, setOpenSideBar] = useState<boolean>(false);
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const history = useHistory();

  return (
    <>
      <div className="bg-[#171720] w-full overflow-x-hidden text-white flex items-center justify-between px-4 lg:px-[1.5em] py-2 relative">
        {user && (
          <>
            <div className="flex items-center gap-2 md:gap-6">
              <button
                onClick={() => {
                  setOpenSideBar(!openSideBar);
                }}
              >
                <MenuIcon />
              </button>
              <img alt="logo" src={OlyxLogo} className="hidden md:inline-block w-[6rem]" onClick={() => history.push("/")} />
              <span className="text-sm md:text-xl line-clamp-1">
                {process.env.REACT_APP_API_URL!.includes("-test.")
                  ? "TEST ENVIRONMENT"
                  : process.env.REACT_APP_API_URL!.includes("-dev.")
                  ? "DEV ENVIRONMENT"
                  : process.env.REACT_APP_API_URL!.includes("-staging.")
                  ? "STAGING ENVIRONMENT"
                  : process.env.REACT_APP_API_URL!.includes("localhost")
                  ? "LOCAL ENVIRONMENT"
                  : ""}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <BidOfferEntryModal />
              <MentionNotificationIconButton />
              <PriceChangeNotificationDropdown />
              <button
                className="w-8 h-8 md:w-10 md:h-10 ml-2 block"
                onClick={() => {
                  setOpenProfileMenu(!openProfileMenu);
                }}
              >
                <Avatar
                  alt={localUser.fullName ?? "userAvatar"}
                  src={localUser.imageUrl ?? ""}
                  className="block w-full h-full overflow-hidden rounded-full bg-gray-400"
                />
              </button>
            </div>
          </>
        )}
        {!user && (
          <>
            <img alt="logo" src={OlyxLogo} className=" w-[8rem] p-2" onClick={() => history.push("/")} />
            <button className="border border-white py-2 px-4 rounded-md hover:bg-white/10" onClick={onSignInClick}>
              Sign in
            </button>
          </>
        )}
      </div>
      {openProfileMenu && (
        <div>
          <ProfileMenu onAboutClick={onAboutClick} onSignOutClick={onSignOutClick} setOpenProfileMenu={setOpenProfileMenu}></ProfileMenu>
        </div>
      )}
      <AnimatePresence>{openSideBar && <Sidebar setOpenSideBar={setOpenSideBar}></Sidebar>}</AnimatePresence>
    </>
  );
};
export default Bar;
