import { auth } from "../firebase";
import authentication from "../services/authentication";
import helpers from "../services/helpers";
import axios from "axios";

const olyxbase = {};

olyxbase.getMe = () => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/me`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }
        return res.json();
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getAllCandles = () => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/allcandles`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }
        return res.json();
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getTickerMessages = () => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/ticker`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }
        return res.json();
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getHistory = () => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/pricehistory/latest`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(async function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }

        const responseJ = await res.json();
        return responseJ.filter((a) => a.price_diff != 0 && a.supressNotification == false);
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getProductHistory = (productId) => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/pricehistory/product?productId=${productId}`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(async function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }

        const responseJ = await res.json();

        return responseJ;
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getProductHistoryLong = (productId, days) => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/pricehistorylong/product?productId=${productId}&days=${days}`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(async function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }

        const responseJ = await res.json();

        return responseJ;
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.updateLayout = (layout) => {
  const url = `${process.env.REACT_APP_API_URL}me/updateLayout`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
        body: JSON.stringify(layout),
      }).then(function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }
        return res.json();
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.saveExactCode = async (code) => {
  const url = `${process.env.REACT_APP_API_URL}exactcode`;
  const body = {
    code: code,
    redirect_uri: `${process.env.REACT_APP_REDIRECTED_URI}`,
    grant_type: "authorization_code",
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  };

  auth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      await axios.post(url, body, { headers: { authorization: `Bearer ${idToken}` } });
    } catch (error) {
      console.error(error);
    }
  });
};

olyxbase.getAccessToken = async () => {
  const url = `${process.env.REACT_APP_API_URL}accesstokenexact`;
  const body = {
    redirect_uri: `${process.env.REACT_APP_REDIRECTED_URI}`,
    grant_type: "refresh_token",
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  };

  auth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      await axios.post(url, body, { headers: { authorization: `Bearer ${idToken}` } });
    } catch (error) {
      console.error(error);
    }
  });
};

olyxbase.createInvoice = async (expectedRevenueId) => {
  const url = `${process.env.REACT_APP_API_URL}createinvoice`;
  const body = {
    expectedRevenueId: expectedRevenueId,
  };

  const res = await auth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const res = await axios.post(url, body, { headers: { authorization: `Bearer ${idToken}` } });
      return res;
    } catch (error) {
      console.error(error);
    }
  });

  if (res === undefined) {
    return false;
  } else if (res && res.status === 200) {
    return true;
  } else {
    return false;
  }
};

olyxbase.getExpirationDate = async () => {
  const url = `${process.env.REACT_APP_API_URL}expirationdate`;

  const idToken = await auth.currentUser.getIdToken(true);
  const response = await axios
    .get(url, { headers: { authorization: `Bearer ${idToken}` } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));

  return response;
};

olyxbase.getLayout = () => {
  const url = `${process.env.REACT_APP_API_URL}me`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(function(res) {
        return res.json().then((jsonr) => {
          return jsonr.layout;
        });
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getNotificationCounts = () => {
  const url = `${process.env.REACT_APP_API_URL}me`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(function(res) {
        return res.json().then((jsonr) => {
          return jsonr;
        });
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.updateLastSeenPriceChangeId = (count) => {
  const url = `${process.env.REACT_APP_API_URL}me/updateLastSeenPriceChangeId`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
        body: JSON.stringify({ count: count }),
      }).then(function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }
        return res.json();
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getConfig = () => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/config`;

  //return auth.currentUser.getIdToken(false).then(function(idToken) {

  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'authorization': 'Bearer ' + idToken
    },
  })
    .then(function(res) {
      if (res.status === 403) {
        authentication.signOut();
      }
      return res.json();

      //   })
    })
    .catch(function(error) {
      console.log(error);
    });
};

olyxbase.getPrices = () => {
  return auth.currentUser.getIdToken(false).then(function(idToken) {
    const url = `${process.env.REACT_APP_API_URL}dashboard/pricedata`;

    return fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + idToken,
      },
    }).then(async function(res) {
      if (res.status === 403) {
        authentication.signOut();
      }

      let resJ = await res.json();

      // Update cards with personally created' order
      let layout = await olyxbase.getLayout();
      if (layout) {
        layout = JSON.parse(layout);

        for (var ss = 0; ss < resJ.length; ss++) {
          resJ[ss].order = layout[resJ[ss].productId] ?? resJ[ss].order;
        }
      }

      for (var ss = 0; ss < resJ.length; ss++) {
        resJ[ss].bid = helpers.roundPriceInt(resJ[ss].bidInt, resJ[ss].decimals, resJ[ss].decimals);
        resJ[ss].ask = helpers.roundPriceInt(resJ[ss].askInt, resJ[ss].decimals, resJ[ss].decimals);
        if (resJ[ss].bid > 0 && resJ[ss].ask > 0) {
          resJ[ss].calcline = resJ[ss].bid + " / " + resJ[ss].ask;
        }
      }

      return resJ;
    });
  });
};

olyxbase.getPrice = (productId) => {
  return auth.currentUser.getIdToken(false).then(function(idToken) {
    const url = `${process.env.REACT_APP_API_URL}dashboard/product?id=${productId}`;

    return fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + idToken,
      },
    }).then(async function(res) {
      if (res.status === 403) {
        authentication.signOut();
      }

      // Update cards with personally created order
      let layout = await olyxbase.getLayout();

      res.order = layout[thisItem.productId] ?? res.order;

      return res.json();
    });
  });
};

olyxbase.updateDiscord = (deal) => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/discordupdatedeal`;
  return auth.currentUser.getIdToken(false).then((idToken) => {
    const body = {
      olyxRef: deal.olyxRef,
      description: deal.description,
    };
    return fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + idToken,
      },
      body: JSON.stringify(body),
    }).then(function(res) {
      if (res.status === 403) {
        authentication.signOut();
        return;
      }

      return res.json().then((resJ) => {
        if (res.status != 200) {
          throw new Error(resJ.reason);
        }
        return resJ;
      });
    });
  });
};

olyxbase.updatePrice = (product, amt, extraInfo, priceType, lastTimeStamp) => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/pricedata`;

  return auth.currentUser.getIdToken(false).then(function(idToken) {
    let updateObj = {
      product: product,
      productId: product.productId,
      extraInfo: extraInfo,
      priceType: priceType,
      lastTimeStamp: lastTimeStamp,
    };

    let bidD = priceType == "bid" ? amt : product.bid;
    let askD = priceType == "ask" ? amt : product.ask;

    //extraInfo = (priceType == 'bid' ? bidD + ' / ' + ' ' : '' + ' / ' + askD + ' ') + extraInfo;

    if (priceType == "bid") {
      updateObj.bidInt = amt;
      updateObj.priceInt = ((product.askInt - amt) / 2 + amt).toFixed(0) * 1;
      updateObj.extraInfo = extraInfo;
      //updateObj.calcline = amt / (10 ** product.decimals) + ' / ' + (product.askInt / (10 ** product.decimals));
      //updateObj.bidUpdateTimeStamp = lastTimeStamp;
    }
    if (priceType == "ask") {
      updateObj.askInt = amt;
      updateObj.priceInt = ((amt - product.bidInt) / 2 + product.bidInt).toFixed(0) * 1;
      updateObj.extraInfo = extraInfo;
      //updateObj.calcline =  (product.bidInt / (10 ** product.decimals)) + ' / ' + amt / (10 ** product.decimals);
      //updateObj.askUpdateTimeStamp = lastTimeStamp;
    }
    if (priceType == "price") {
      updateObj.priceInt = amt;
      updateObj.calcline = null;
    }

    updateObj.price = helpers.roundPriceInt(updateObj.priceInt, product.decimals, product.decimals);
    return fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + idToken,
      },
      body: JSON.stringify(updateObj),
    }).then(function(res) {
      if (res.status === 403) {
        authentication.signOut();
        return;
      }

      return res.json().then((resJ) => {
        if (res.status != 200) {
          throw new Error(resJ.reason);
        }
        return resJ;
      });
    });
  });
};

olyxbase.updateUser = (fullName, email) => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/user`;

  return auth.currentUser.getIdToken(false).then(function(idToken) {
    let updateObj = {
      //imageUrl: imageUrl,
      fullName: fullName,
      email: email,
    };

    return fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + idToken,
      },
      body: JSON.stringify(updateObj),
    }).then(function(res) {
      if (res.status === 403) {
        authentication.signOut();
      }
      return res.json();
    });
  });
};

olyxbase.getStats = () => {
  const url = `${process.env.REACT_APP_API_URL}dashboard/stats`;

  return auth.currentUser
    .getIdToken(false)
    .then(function(idToken) {
      return fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + idToken,
        },
      }).then(function(res) {
        if (res.status === 403) {
          authentication.signOut();
        }
        return res.json();
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export default olyxbase;
