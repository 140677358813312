import React from "react";
import PropTypes from "prop-types";

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { scaleTime } from "d3-scale";

import { ChartCanvas, Chart } from "react-stockcharts";
import { ScatterSeries, CircleMarker, LineSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { CrossHairCursor, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";

import { fitWidth } from "react-stockcharts/lib/helper";
import { last, first } from "react-stockcharts/lib/utils";

class ProductHistoryChart extends React.Component {
  render() {
    const { data, ratio } = this.props;

    const xExtents = [new Date(first(data).timestamp), new Date(last(data).timestamp)];

    return (
      <ChartCanvas
        ratio={ratio}
        width={1000}
        height={600}
        margin={{ left: 70, right: 70, top: 20, bottom: 30 }}
        type={"hybrid"}
        pointsPerPxThreshold={1}
        seriesName="price"
        data={data}
        xAccessor={(d) => new Date(d.timestamp)}
        xScale={scaleTime()}
        xExtents={xExtents}
      >
        <Chart id={1} yExtents={(d) => [d.price]}>
          <XAxis axisAt="bottom" orient="bottom" />
          <YAxis axisAt="right" orient="right" ticks={5} />
          <MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat("%Y-%m-%d %H:%m")} />
          <MouseCoordinateY at="right" orient="right" displayFormat={format(".2f")} />

          <LineSeries yAccessor={(d) => d.price} stroke="#ff7f0e" strokeDasharray="Solid" width={3} />

          <ScatterSeries yAccessor={(d) => d.price} marker={CircleMarker} markerProps={{ r: 3 }} />
        </Chart>

        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

ProductHistoryChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

ProductHistoryChart.defaultProps = {
  type: "svg",
};
ProductHistoryChart = fitWidth(ProductHistoryChart);

export default ProductHistoryChart;
