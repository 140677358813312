import { gql } from "@apollo/client";

const GET_INVOICES = gql`
  query Invoices($input: QueryInvoiceInput!) {
    invoices(input: $input) {
      id
      invoiceNumber
      isDeleted
      description
      type
      client {
        id
        companyName
      }
      totalAmount
      totalAmountEur
      currency {
        id
        description
      }
      dateInvoice
      updatedAt
      invoiceStatus {
        id
        name
      }
      orderNumber
      allocationStatus
      allocatedToAllExpectedRevenue
      expectedRevenue {
        id
        totalAmount
        currency {
          id
          description
        }
        dateExpected
        allocatedFromParentInvoice
        allocatedFromAllInvoices
        deal {
          id
          olyxRef
        }
        expectedRevenueChanges {
          id
          amount
          reason
        }
        invoices {
          id
          totalAmount
          orderNumber
          allocatedToParentExpectedRevenue
          invoiceStatusSummary
          invoiceNumber
          dateOrder
          isDeleted
          currency {
            id
            description
          }
        }
      }
    }
  }
`;

export default GET_INVOICES;
