import { useContext, useState } from "react";
import "moment-timezone";
import { sortedDataContext } from "../../contexts/TableContexts";
import ExpRevSingle from "../ExpRevSingle/ExpRevSingle";
import { ExpRevType } from "../ExpRevSingle/ExpRevSingleInterfaces";
import FormLongTermDeals from "../FormLongTermDeals/FormLongTermDeals";
import ExpRevWithInvoicesProps from "../../interfaces/ExpRevWithInvoicesProps";

function ExpRevWithInvoices({ currentUserIsAdmin, tradeSide, dealId, enableChangesOnExpRev }: ExpRevWithInvoicesProps) {
  const sortedData = useContext<ExpRevType[]>(sortedDataContext);
  const [expRevIsChangingId, setExpectedRevenueIsChangingId] = useState(0);

  return (
    <div className={"inline-grid grid-cols-6 gap-4 gap-x-1 mt-4 w-full mb-3"}>
      {sortedData && sortedData.length > 0 ? (
        <>
          {sortedData &&
            sortedData.map((expRev: ExpRevType) => {
              const currencyId = expRev.currency.id;
              return (
                <div className={"col-span-6"} key={expRev.id}>
                  {expRevIsChangingId !== expRev.id ? (
                    <ExpRevSingle
                      key={expRev.id}
                      expRev={expRev}
                      currentUserIsAdmin={currentUserIsAdmin}
                      enableChangesOnExpRev={enableChangesOnExpRev}
                      onClick={() => setExpectedRevenueIsChangingId(expRev.id)}
                    />
                  ) : (
                    <FormLongTermDeals
                      key={expRev.id}
                      dateExpected={new Date(expRev.dateExpected).toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })}
                      totalAmount={Number(expRev.totalAmount) / 100}
                      currencyId={Number(currencyId)}
                      tradeSide={tradeSide}
                      expRevId={expRev.id}
                      note={expRev.note}
                      updatingExpRevenue={true}
                      currentUserIsAdmin={currentUserIsAdmin}
                      dealId={dealId}
                      clientId={expRev.client.id}
                      setExpectedRevenueIsChanging={setExpectedRevenueIsChangingId}
                    />
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <div>
          <span>No invoice(s)</span>
        </div>
      )}
    </div>
  );
}

export default ExpRevWithInvoices;
