import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_DEALNOTESOVERVIEWCOUNT from "./query_dealnotesoverviewcount";

function useOpenDealNoteCount() {
  const [openDealNoteCount, setOpenDealNoteCount] = useState(0);

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_DEALNOTESOVERVIEWCOUNT, {
    notifyOnNetworkStatusChange: false,
    nextFetchPolicy: "cache-and-network",
    pollInterval: 30000, // 30 seconds
  });

  useEffect(() => {
    setOpenDealNoteCount(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting OpenDealNoteCount");
        return false;
      } else if ("opendealnotecount" in data) {
        return data.opendealnotecount;
      } else {
        return false;
      }
    });
  }, [loading, error, data, refetch, JSON.stringify(data)]); // eslint-disable-line react-hooks/exhaustive-deps

  return [openDealNoteCount, refetch, networkStatus];
}

export default useOpenDealNoteCount;
