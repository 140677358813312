import React, { useState, useEffect } from "react";
import PageVisibility from "react-page-visibility";
import Ticker from "../../components/Ticker/Ticker.js";
import Link from "@material-ui/core/Link";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Offline, Online } from "react-detect-offline";
import io from "socket.io-client";
import { auth } from "../../firebase";
import ProductInfoModal from "../../components/ProductInfoModal/ProductInfoModal";
import { AnimatePresence, motion } from "framer-motion";
import olyxbase from "../../services/olyxbase";
import DashboardCard from "../../components/DashboardCard";
import "./Dashboard.css";
import PlusIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

function Dashboard({ config }) {
  const queryClient = useQueryClient();
  const prices = useQuery("prices", olyxbase.getPrices);
  const candles = useQuery("candles", olyxbase.getAllCandles);
  const tickerItems = useQuery("ticker", olyxbase.getTickerMessages);
  const [showNotifications, setShowNotifications] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [disableHoverListen, setDisableHoverListen] = useState(false);
  const [isProductInfoModalOpen, setIsProductInfoModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const [sortOrders, setSortOrders] = useState({});

  const audioElement = new Audio("/sounds/sub_loud.mp3");

  const openProductInfoModal = (productId) => {
    setSelectedProductId(productId);
    setIsProductInfoModalOpen(true);
  };

  const handleCloseProductInfoModal = () => {
    setSelectedProductId(0);
    setIsProductInfoModalOpen(false);
  };

  useEffect(() => {
    var firstInt = setInterval(() => {
      queryClient.invalidateQueries("ticker");
    }, 2000);

    var secondInt = setInterval(() => {
      queryClient.invalidateQueries("prices");
      queryClient.invalidateQueries("candles");
      setLastUpdate(new Date());
    }, 15000);

    auth.currentUser.getIdToken(false).then((token) => {
      const socketUrl = `${process.env.REACT_APP_API_URL}`;
      const socket = io(socketUrl);

      socket.on("connect", () => {
        socket.emit("authenticate", { token: token });
      });

      socket.on("priceupdate", async (data) => {
        await queryClient.cancelQueries("prices");
        const previousPrices = queryClient.getQueryData("prices");
        const modifiedPrices = previousPrices.map((x) =>
          x.productId === data.current.data.productId
            ? {
                ...x,
                priceInt: data.current.data.priceInt,
                extraInfo: data.current.data.extraInfo,
                priceUpdateTimestamp: new Date(),
                outlined: "outlined",
                calcline: null,
              }
            : x
        );

        // Optimistically update to the new value
        queryClient.setQueryData("prices", modifiedPrices);
        queryClient.invalidateQueries("ticker");
        queryClient.invalidateQueries("prices");
        queryClient.invalidateQueries("candles");
      });
    });

    return () => {
      clearInterval(firstInt);
      clearInterval(secondInt);
    };
  }, []);

  const handleVisibilityChange = (isVisible) => {
    setShowNotifications(!isVisible);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  /**
   * @param  {product, valInt, extraInfo, priceType, lastUpdateTimestamp} updateData
   */
  const updatePriceMutation = useMutation(
    (updateData) => {
      return olyxbase.updatePrice(updateData.product, updateData.valInt, updateData.extraInfo, "price", updateData.lastUpdateTimestamp);
    },
    {
      onMutate: async (updateData) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries("prices");

        const previousPrices = queryClient.getQueryData("prices");
        const modifiedPrices = previousPrices.map((x) =>
          x.productId === updateData.product.productId
            ? {
                ...x,
                priceInt: updateData.valInt,
                extraInfo: updateData.extraInfo,
                priceUpdateTimestamp: new Date(),
                outlined: "outlined",
                calcline: null,
              }
            : x
        );

        // Optimistically update to the new value
        queryClient.setQueryData("prices", modifiedPrices);

        // Update candle
        const oldCandles = queryClient.getQueryData("candles");
        let newCandles = updateTemporaryStateCandle(oldCandles, updateData.product.productId, updateData.valInt / 10 ** 2);
        queryClient.setQueryData("candles", newCandles);

        // Return a context with the previous and new todo
        return { previousPrices, oldCandles, updateData };
      },
      // If the mutation fails, use the context we returned above
      onError: (err, modifiedPrices, context) => {
        alert("Error updating price " + err);
        queryClient.setQueryData("prices", context.previousPrices);
        queryClient.setQueryData("candles", context.oldCandles);
      },
      onSettled: (modifiedPrices) => {
        queryClient.invalidateQueries("ticker");
        queryClient.invalidateQueries("prices");
        queryClient.invalidateQueries("candles");
      },
    }
  );
  //product, tmpPrice.toFixed(2)*1, exi, selectedSound, priceType);
  const nudgePrice = async (product, valInt, extraInfo, sound, priceType = "price", lastUpdateTimestamp) => {
    updatePriceMutation.mutate({
      product,
      valInt,
      extraInfo,
      priceType,
      lastUpdateTimestamp,
    });
  };

  const moveCard = async (productId, moveData, orig) => {
    let newOrder = sortOrders;
    let origVal = newOrder[productId] ?? orig;

    let movAmt = parseFloat(origVal) + parseFloat(moveData);

    if (parseFloat(moveData) == -999) {
      movAmt = 500;
    }
    newOrder[productId] = movAmt;

    setSortOrders(newOrder);
    let priceArray = prices.data.slice(0);

    priceArray.forEach((price) => {
      if (price.productId == productId) {
        {
          price.order = newOrder[productId];
        }
      }
    });

    let updateLayoutNew = {};
    priceArray
      .sort((a, b) => a.order - b.order)
      .forEach((price, index) => {
        if (price.order < 900) {
          price.order = index;
          updateLayoutNew[price.productId] = index;
        } else {
          updateLayoutNew[price.productId] = price.order;
        }
      });

    setSortOrders(updateLayoutNew);
    await queryClient.cancelQueries("prices");
    queryClient.setQueryData("prices", priceArray);

    await olyxbase.updateLayout(updateLayoutNew);
    queryClient.invalidateQueries("prices");
  };

  const getTodayComparisonDate = () => {
    let td = new Date().toISOString().split("T")[0];

    return td;
  };

  const updateTemporaryStateCandle = (oldCandles, productId, newPrice) => {
    let todayCandle = {};
    todayCandle = oldCandles.find((x) => x.date === getTodayComparisonDate() && x.productId == productId);
    if (!todayCandle) {
      todayCandle = { high: 0, low: 0 };
    }

    if (newPrice > todayCandle.high || todayCandle.high == null) todayCandle.high = newPrice;

    if (newPrice < todayCandle.low || todayCandle.low == null) todayCandle.low = newPrice;

    if (todayCandle.open == null) {
      todayCandle.open = newPrice;
    }

    todayCandle.close = newPrice;

    const modifiedCandles = oldCandles.slice(0).map((x) => (x.date === getTodayComparisonDate() && x.productId == productId ? todayCandle : x));

    return modifiedCandles;
  };

  const overlayVariants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        duration: 0.3,
        delayChildren: 0.4,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        duration: 0.3,
        delay: 0.4,
      },
    },
  };

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <div style={styles.root}>
        <img src={"/images/offline.png"} style={{ width: 1, height: 1, opacity: 0, display: "none" }} />

        {tickerItems.isSuccess && tickerItems.data && <Ticker items={tickerItems.data} />}

        <Online>
          <img
            src={"/images/live.png"}
            title={"Last Data Received: " + lastUpdate}
            style={{ textDecoration: "none", position: "absolute", marginTop: 10, width: 50, left: 10 }}
            onClick={() => audioElement.play()}
          ></img>
        </Online>

        <Offline>
          <img
            src={"/images/offline.png"}
            title={"Last Data Received: " + lastUpdate}
            style={{ textDecoration: "none", position: "absolute", marginTop: 10, width: 50, left: 10 }}
          ></img>
        </Offline>

        <Link
          onClick={toggleEditMode}
          style={{ textDecoration: "none", position: "absolute", marginTop: 5, right: 20, color: "black", fontSize: 22, fontWeight: "bold" }}
        >
          ...
        </Link>

        <div>
          <div className="cardWrapper" style={{ width: editMode ? "80%" : "100%", float: "left" }}>
            {prices.isSuccess &&
              prices.data &&
              prices.data
                .sort((a, b) => a.order - b.order)
                .filter((item) => item.order < 900)
                .map((price) => (
                  <DashboardCard
                    key={price.productId}
                    price={price}
                    disableHoverListen={disableHoverListen}
                    setDisableHoverListen={setDisableHoverListen}
                    nudgePrice={nudgePrice}
                    config={config}
                    editMode={editMode}
                    productCandles={candles.data && candles.data.filter((a) => a.productId == price.productId)}
                    moveCard={moveCard}
                    openProductInfoModal={openProductInfoModal}
                  />
                ))}
            <div style={{ clear: "both" }}></div> <br />
          </div>

          <div
            style={{
              float: "left",
              width: "18%",
              height: "100%",
              marginRight: 20,
              backgroundColor: "#323741",
              marginTop: 50,
              display: editMode ? "block" : "none",
            }}
          >
            <List>
              <ListItem key={"hc"}>
                <ListItemText primary={"Hidden Cards"} />
              </ListItem>
              <Divider />
              {prices.isSuccess &&
                prices.data &&
                prices.data
                  .filter((item) => item.order >= 900)
                  .sort((a, b) => (a.productName > b.productName ? 1 : -1))
                  .map((cardItem, index) => (
                    <ListItem button key={cardItem.productId} onClick={() => moveCard(cardItem.productId, -999, cardItem.order)}>
                      <ListItemIcon>
                        <PlusIcon />
                      </ListItemIcon>
                      <ListItemText primary={cardItem.productName} secondary={cardItem.productInfo} />
                    </ListItem>
                  ))}
            </List>
          </div>
        </div>
        <AnimatePresence>
          {isProductInfoModalOpen && (
            <motion.div
              onClick={() => {
                handleCloseProductInfoModal();
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={overlayVariants}
              className="modal-overlay z-3000 relative"
            >
              <motion.div className="modal" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                {" "}
                <motion.div
                  className="relative mx-auto w-10/12 top-28"
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0.9 }}
                  transition={{
                    duration: 0.2,
                    delay: 0.3,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {" "}
                  <ProductInfoModal setIsProductInfoModalOpen={setIsProductInfoModalOpen} productId={selectedProductId} />
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </PageVisibility>
  );
}

const styles = {
  root: {
    paddingTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#e4e9eb",
  },
  control: {
    padding: 10,
  },
  contentWrapper: {
    padding: 20,
  },
  middlePriceTitle: {
    marginLeft: 10,
  },
  chartContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 220,
  },
  selectControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 350,
    marginTop: 3,
    zIndex: 99,
  },
  entryBar: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

export default Dashboard;
