import { motion } from "framer-motion";
import { Close as CloseIcon } from "@material-ui/icons";
import ExpRevChangesFormProps from "../../interfaces/ExpRevChangesForm";
import { useState } from "react";
import useExpectedRevenueChangeOptions from "../../hooks/useExpectedRevenueChangeOptions";
import useCreateExpectedRevenueChange from "../../hooks/useCreateExpectedRevenueChange";

const ExpRevChangesForm = ({ expRevId, setCreatingChanges, creatingChanges }: ExpRevChangesFormProps) => {
  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [reasonId, setReasonId] = useState<number>(2);
  const [formOptions] = useExpectedRevenueChangeOptions();
  const createExpRevChange = useCreateExpectedRevenueChange();

  const validateFormInput = (expRevId: number, amount: string, description: string, reasonId: number) => {
    if (Number(amount)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (expRevId: number, amount: string, description: string, reasonId: number) => {
    const changeInputs = {
      expectedRevenueId: expRevId,
      amount: String(Number(amount) * 100),
      description: description,
      expectedRevenueReasonId: Number(reasonId),
    };
    createExpRevChange({
      variables: {
        input: [changeInputs],
      },
    });
  };

  return (
    <div className="relative p-2 grid max-w-xs border-gray-200 border rounded-xl py-1">
      <div className="m-1">
        <label className="text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
          Change reason:
          <select
            className="border h-6 border-gray-200 rounded text-[#7E8085] bg-white px-2 w-60 text-center center focus:outline-none hover:text-[#4c4c4e]"
            value={reasonId}
            onChange={(e) => {
              setReasonId(Number(e.target.value));
            }}
          >
            {formOptions?.expectedrevenuereason?.map((option: { id: number; name: string }) => {
              return (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </label>
      </div>
      <div className="m-1">
        <label className="text-sm h-6 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
          Description:
          <input
            className="border h-6 border-gray-200 rounded  text-[#7E8085] bg-white w-60 px-2 text-center center focus:outline-none appearance-none hover:text-[#4c4c4e]"
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
      </div>
      <div className="m-1">
        <label className="text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
          Amount:
          <input
            className="border border-gray-200 rounded text-[#7E8085] bg-white px-2 w-60 text-center center focus:outline-none appearance-none hover:text-[#4c4c4e]"
            onChange={(e) => setAmount(e.target.value)}
          />
        </label>
      </div>
      <motion.button
        onClick={() => setCreatingChanges(!creatingChanges)}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1, rotate: 90 }}
        whileTap={{ scale: 1 }}
        className="transform absolute top-0 right-0 text-xs m-2"
      >
        <CloseIcon className="hover:text-[#4c4c4e]" fontSize="small"></CloseIcon>
      </motion.button>
      <button
        className="w-20 text-xs bg-primary-blue text-white p-1 rounded m-1"
        onClick={() => {
          const formHasValideInput = validateFormInput(expRevId, amount, description, reasonId);
          if (formHasValideInput) {
            handleSubmit(expRevId, amount, description, reasonId);
            setCreatingChanges(false);
          } else {
            alert("Amount must be a number");
          }
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default ExpRevChangesForm;
