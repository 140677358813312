import React from "react";
// import PropTypes from 'prop-types';
// import styles from './AddDealQuickPage.css';
import AddDealSelect from "./../../components/AddFormDeal/AddDealSelect";
import { withRouter } from "react-router-dom";

function AddDealQuickPage(props) {
  return (
    <div>
      <AddDealSelect />
    </div>
  );
}

// AddDealQuickPage.defaultProps = {};

// AddDealQuickPage.propTypes = {
// };

export default withRouter(AddDealQuickPage);
