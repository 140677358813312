import React from "react";
import MessageIcon from "@material-ui/icons/Mail";
import useOpenDealNoteCount from "../../hooks/useOpenDealNoteCount";
import { Link } from "react-router-dom";

export default function MentionNotificationIconButton() {
  const [openDealNoteCount] = useOpenDealNoteCount();

  return (
    <div className="p-3 pr-1 rounded-full hover:bg-white hover:bg-opacity-10">
      <Link className="relative mr-2" to={"/"}>
        <MessageIcon />
        {openDealNoteCount > 0 && (
          <div
            data-testid="noti-badge"
            className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 rounded-full -top-[1.06rem] -right-3 border-gray-900"
          >
            {openDealNoteCount > 99 ? "99+" : openDealNoteCount}
          </div>
        )}
      </Link>
    </div>
  );
}
