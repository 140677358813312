const parsedStringToNumber = (parsedString?: string, defaultValue = 0): number => {
  if (parsedString === undefined) {
    return defaultValue;
  } else if (isNaN(parsedString as any)) {
    return defaultValue;
  } else {
    return Number(parsedString);
  }
};
export default parsedStringToNumber;
