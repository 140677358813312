import { USD, EUR, GBP } from "./constants";
import axios from "axios";

const helpers = {};

helpers.formatMoneyKMB = (number, decimals = 2) => {
  if (number === "") {
    return "";
  } else if (Number(number) < 0) {
    return `-${helpers.formatMoneyKMB(-1 * Number(number), decimals)}`;
  } else if (Number(number) >= Math.pow(10, 9)) {
    return Math.round(Number(number) / Math.pow(10, 7)) / Math.pow(10, 2) + "B";
  } else if (Number(number) >= Math.pow(10, 6)) {
    return Math.round(Number(number) / Math.pow(10, 4)) / Math.pow(10, 2) + "M";
  } else if (Number(number) >= Math.pow(10, 3)) {
    return Math.round(Number(number) / Math.pow(10, 3)) / Math.pow(10, 0) + "K";
  } else if (decimals === 2 && Number(number) < 0.01 && Number(number) !== 0) {
    return "0.0...";
  } else {
    return Math.round(Number(number) * 10 ** decimals) / 10 ** decimals;
  }
};

helpers.computeBalances = async (adr, assets, assetPairs) => {
  /**
   * Compute balance for each asset.
   * Result is a dictionary with asset names as keys, and balances as values.
   * There is no conversion into currency.
   */
  let balances = {};
  await Promise.all(
    assetPairs.map(async (pair) => {
      const [asset] = pair;
      const balance = await axios.get(`https://node1.crap.exchange/assets/balance/${adr}/${asset}`);
      const adjustedBalance = balance.data.balance / 10 ** assets[asset]["decimals"];
      balances[asset] = adjustedBalance;
    })
  );
  const usdBalance = await axios.get(`https://node1.crap.exchange/assets/balance/${adr}/${USD}`);
  balances[USD] = usdBalance.data.balance / 10 ** assets[USD]["decimals"];
  return balances;
};

helpers.computePosition = async (adr, assets, assetPairs, latestAssetPrices, balances, usdEurConversion, gbpEurConversion) => {
  /**
   * Computes overall position.
   */
  let position = 0;
  let assetData = {};
  let barData = { short: 0, long: 0 };

  await Promise.all(
    assetPairs.map(async (pair) => {
      const [asset, currency] = pair;
      const balance = balances[asset];

      let currencyMultiplier = 1;
      if (asset !== EUR && currency === USD) {
        currencyMultiplier = usdEurConversion;
      } else if (currency === GBP) {
        currencyMultiplier = gbpEurConversion;
      }

      // fetch address initial balance for current asset
      const init_balance_request = await axios.get(`https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/${adr}_${asset}`);
      const init = init_balance_request.data["value"];
      const net = balance - init;

      // if asset is not EUR, need to adjust using latest price and currency multiplier
      let value = net;
      if (asset !== EUR && asset !== GBP) {
        assetData[asset] = {
          Product: assets[asset]["name"],
          Initial: init,
          Current: balance,
          Net: Math.round(net * 100) / 100,
        };
        // long, so use bid price
        if (net > 0) {
          const price = latestAssetPrices[asset]["bid"] * currencyMultiplier;
          value *= price;
          assetData[asset]["Current Price"] = Math.round(price * 100) / 100;
          assetData[asset]["Value"] = value;
          barData["long"] += value;
          // short, so use ask price
        } else if (net < 0) {
          const price = latestAssetPrices[asset]["ask"] * currencyMultiplier;
          value *= price;
          assetData[asset]["Current Price"] = Math.round(price * 100) / 100;
          assetData[asset]["Value"] = value;
          barData["short"] += value;
          // neutral balance, use average of bid and ask, set value to zero
        } else {
          assetData[asset]["Current Price"] =
            Math.round((1 / 2) * currencyMultiplier * (latestAssetPrices[asset]["ask"] + latestAssetPrices[asset]["bid"]) * 100) / 100;
          assetData[asset]["Value"] = 0;
        }
      } else if (asset === EUR) {
        assetData[asset] = {
          Product: "Euro Cash",
          Initial: init,
          Current: balance,
          Net: net,
          "Current Price": 1,
          Value: value,
        };
        barData["EUR"] = value;
      } else if (asset === GBP) {
        value = (balances[GBP] - init) * gbpEurConversion;
        assetData[asset] = {
          Product: "GBP",
          Initial: init,
          Current: balance,
          Net: net,
          "Current Price": Math.round(gbpEurConversion * 100) / 100,
          Value: value,
        };
        barData["GBP"] = value;
      }
      position += value;
    })
  );

  // also factor in the USD position converted into EUR
  const init_usd_request = await axios.get(`https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/${adr}_${USD}`);
  const USDinit = init_usd_request.data["value"];
  const USDvalue = (balances[USD] - USDinit) * usdEurConversion;
  assetData[USD] = {
    Product: assets[USD]["name"],
    Initial: USDinit,
    Current: balances[USD],
    Net: Math.round((balances[USD] - USDinit) * 100) / 100,
    "Current Price": Math.round(usdEurConversion * 100) / 100,
    Value: USDvalue,
  };

  position += USDvalue;

  if (balances[USD] - USDinit > 0) {
    barData["long"] += USDvalue;
  } else if (balances[USD] - USDinit < 0) {
    barData["short"] += USDvalue;
  }

  const amountSum = Math.max(1, barData["long"] - barData["short"] + Math.abs(barData["EUR"]));
  barData["longProp"] = Math.round((barData["long"] / amountSum) * 100);
  barData["shortProp"] = Math.round((-barData["short"] / amountSum) * 100);
  barData["EURProp"] = Math.round((Math.abs(barData["EUR"]) / amountSum) * 100);
  barData["balanceMetric"] = Math.abs(barData["long"] + barData["short"]) / Math.max(1, barData["long"] - barData["short"]);

  // format balances
  barData["short"] = helpers.formatMoneyKMB(barData["short"], 3);
  barData["long"] = helpers.formatMoneyKMB(barData["long"], 3);
  barData["EUR"] = helpers.formatMoneyKMB(barData["EUR"], 3);

  return [position, assetData, barData];
};

export default helpers;
