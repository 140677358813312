import React from "react";
import "./BidOfferEntryPriceHeader.css";

export default function BidOfferEnntryPriceHeader({ category }) {
  return (
    <div className="priceheaderrow-row">
      <div className="priceheaderrow-category priceheaderrow">{category}</div>
      <div className="priceheaderrow-price priceheaderrow">Bid</div>
      <div className="priceheaderrow-price priceheaderrow">Ask</div>
    </div>
  );
}
