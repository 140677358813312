import React, { useState, useEffect } from "react";
import DealsTableExpanding from "../Table/DealsTableExpanding";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import useDeals from "../../hooks/useDeals";
import useBrokers from "../../hooks/useBrokers";
import FilterForm from "../CommissionAndInvoice/FilterForm";

function Deals() {
  // const brokerIds = [];
  const [allBrokers, refetchBrokers, networkStatusBrokers] = useBrokers();
  const [dateAfter, setDateAfter] = useState(null);
  const [dateBefore, setDateBefore] = useState(null);
  const [broker, setBroker] = useState("0");
  const [olyxRef, setOlyxRef] = useState("");

  const queryDealsInput = {
    brokerIds: broker === "0" || broker === null ? [] : [broker],
    olyxRefs: olyxRef !== "" && parseInt(olyxRef) > 100 ? [olyxRef] : undefined,
    after: dateAfter,
    before: dateBefore,
  };

  const { deals, navigateForward, navigateBack } = useDeals(queryDealsInput ?? undefined);

  const onChangeBrokerFunction = (event, value) => {
    setBroker(value);
  };

  const formFields = {
    dateAfter: {
      label: "After",
      name: "after",
      defaultValue: "2021-01-01",
      value: dateAfter,
      onChange: (value) => setDateAfter(value),
    },
    dateBefore: {
      label: "Before",
      name: "before",
      defaultValue: "2021-02-01",
      value: dateBefore,
      onChange: (value) => setDateBefore(value),
    },
    broker: {
      label: "Broker",
      name: "broker",
      defaultValue: "0",
      value: broker,
      onChange: onChangeBrokerFunction,
      options:
        allBrokers && allBrokers.brokers && allBrokers.brokers.length > 0
          ? allBrokers.brokers
          : [
              { id: 0, brokerName: "name0" },
              { id: 1, brokerName: "name1" },
            ],
    },
    olyxRef: {
      label: "OlyxRef",
      name: "olyxRef",
      defaultValue: "",
      value: olyxRef,
      onChange: (event) => {
        setOlyxRef(event.target.value);
      },
    },
  };
  return (
    <>
      <div style={styles.textHolder}>
        <h1>
          <center>Deals</center>
        </h1>
      </div>
      <div style={styles.componentHolder}>
        <FilterForm formFields={formFields} />
      </div>
      {deals && deals.dealslist && deals.dealslist.length > 0 && <DealsTableExpanding data={deals} />}
      {true && (
        <Button
          onClick={() => {
            navigateBack();
            window.scrollTo({ top: 250, behavior: "smooth" });
          }}
        >
          <NavigateBeforeIcon />
        </Button>
      )}
      {true && (
        <Button
          onClick={() => {
            navigateForward();
            window.scrollTo({ top: 250, behavior: "smooth" });
          }}
        >
          <NavigateNextIcon />
        </Button>
      )}
    </>
  );
}

const styles = {
  componentHolder: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#142a41",
    // textAlign: "center",
  },
  textHolder: {
    paddingTop: "1em",
    paddingBottom: "0.01em",
    // paddingLeft: 40,
    // paddingRight: 40,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#142a41",
    textAlign: "center",
  },
};
export default Deals;
