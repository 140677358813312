import React, { useState } from "react";
import AddFormDeal from "./../AddFormDeal/AddFormDeal";
import Grid from "@material-ui/core/Grid";
import useDeal from "../../hooks/useDeal";
import useDealOptions from "../../hooks/useDealOptions";
import Button from "@material-ui/core/Button";
import helpers from "../../services/helpers";

const SingleDeal = (props) => {
  const [showAddDealForm, setShowAddDealForm] = useState(false);
  const [data] = useDeal({ dealId: props.dealId?.toString() ?? "0" });
  const [dealOptions] = useDealOptions();

  return (
    <>
      <Grid container spacing={3}>
        {false && (
          <>
            {Object.keys(props.data).map((item) => {
              console.log(item);
              if (typeof props.data[item] !== "object") {
                return (
                  <>
                    <Grid item sm={4} xs={12}>
                      <strong>{item}: </strong>
                      <div>{props.data[item]}</div>
                    </Grid>
                  </>
                );
              } else if (props.data[item] !== null) {
                return (
                  <>
                    <Grid item sm={4} xs={12}>
                      <strong>{item}: </strong>

                      {Object.keys(props.data[item]).map((subitem) => {
                        console.log(subitem);
                        return (
                          <>
                            <div>
                              {subitem}: {JSON.stringify(props.data[item][subitem])}
                            </div>
                          </>
                        );
                      })}
                    </Grid>
                  </>
                );
              }
            })}
          </>
        )}
        <div style={styles.root}>
          <Button variant="outlined" onClick={() => setShowAddDealForm((showAddDealForm) => !showAddDealForm)}>
            {showAddDealForm ? "Hide Deal Form" : "Update Deal Information"}
          </Button>
          {showAddDealForm && data && data.deals && (
            <AddFormDeal newInput={false} initialValues={helpers.getInitialValuesDeal(data.deals[0])} dealOptions={dealOptions} />
          )}
        </div>
      </Grid>
    </>
  );
};

const styles = {
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#115980",
  },
};
export default SingleDeal;
