import { ProductStatistics } from "../../codegen/gql/graphql";
import _ from "lodash";
import helpers from "../../services/helpers";

function productStatisticsToIcicleData(productStatistics: ProductStatistics[]) {
  const productStatisticsByCategory = _.groupBy(productStatistics, (productStatistic: ProductStatistics) => productStatistic?.product?.category?.id ?? 0);

  const childrenOfIcicleData = Object.keys(productStatisticsByCategory).map((categoryId) => {
    return {
      type: "leaf",
      id: productStatisticsByCategory[categoryId][0].product.category?.id,
      category: productStatisticsByCategory[categoryId][0].product.category?.name,
      name: productStatisticsByCategory[categoryId][0].product.category?.name,
      color: `${helpers.getColorForCategoryIcicleGraph(categoryId)}`,
      children: productStatisticsByCategory[categoryId].map((productStatisticForSingleCategory) => {
        return {
          type: "leafChild",
          category: productStatisticForSingleCategory.product.category?.name,
          id: productStatisticForSingleCategory.product.id,
          name: productStatisticForSingleCategory.product.name,
          value: productStatisticForSingleCategory.commissionInEur,
          color: `${helpers.getColorForCategoryIcicleGraph(categoryId)}`,
        };
      }),
    };
  });

  const icicleData = {
    type: "node",
    name: "Weekly Total",
    color: "#ccc",
    children: childrenOfIcicleData,
  };

  return icicleData;
}

export default productStatisticsToIcicleData;
