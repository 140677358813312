import TextFieldForFiltersProps from "../../../interfaces/TextFieldForFiltersProps";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
/*
all the logic is handled in the parent component
  - when we type something here, a state in the parent component is updated
  - based on that:
    * the value to display is updated
    * we check if an element is selected to have different style and to have a cross displayed
*/
const TextField = (props: TextFieldForFiltersProps) => {
  const selectedStyleDiv = !props.isElementSelected
    ? "bg-transparent text-opacity-50"
    : "bg-light-blue-filters rounded-lg border border-solid border-light-blue-filters";
  return (
    <div className={`w-full h-full text-dark-gray-filters grow relative flex items-center flex-row flex-nowrap ${selectedStyleDiv}`}>
      <input
        required={props.isRequired}
        ref={props.inputRef}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        tabIndex={props.tabIndex}
        id={props.label}
        style={{ width: "100%", textOverflow: "ellipsis" }}
        onClick={props.onClick}
        className="outline-none h-full rounded-[6px] text-dark-gray-filters pl-2 pr-2 bg-transparent md:text-base
        border-[1px] border-transparent invalid:border-red-200 invalid:bg-red-100"
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      {props.isElementSelected ? <ClearIcon className="scale-60 mr-[4px] cursor-pointer" onClick={props.onClickCrossButton}></ClearIcon> : ""}
    </div>
  );
};

export default TextField;
