import React from "react";
// import styles from './InvoiceStatus.css';

function InvoiceStatus(props) {
  const invoicesSent = props.invoices.map((invoice) => {
    let invoiceStatus = "";
    if (invoice.invoiceSent) {
      if (invoice.invoiceSentDate) {
        invoiceStatus += invoice.client.companyName + " : Sent on " + invoice.invoiceSentDate.slice().substring(0, 10) + "\n";
      } else {
        invoiceStatus += invoice.client.companyName + " : Sent, date unknown \n";
      }
    }
    return invoiceStatus;
  });

  const invoicesPaid = props.invoices.map((invoice) => {
    let invoiceStatus = "";
    if (invoice.invoicePaid) {
      if (invoice.invoicePaidDate) {
        invoiceStatus += invoice.client.companyName + " : Paid on " + invoice.invoicePaidDate.slice().substring(0, 10);
      } else {
        invoiceStatus += invoice.client.companyName + " : Paid, \n date unknown";
      }
    }
    return invoiceStatus;
  });

  const invoicesAll = props.invoices.map((invoice) => {
    let invoiceStatus = "";

    if (!invoice.invoiceSent && !invoice.invoicePaid) {
      invoiceStatus += invoice.client.companyName + " : To be confirmed";
    }

    if (invoice.invoiceSent && !invoice.invoicePaid) {
      if (invoice.invoiceSentDate) {
        invoiceStatus += invoice.client.companyName + " : Sent on " + invoice.invoiceSentDate.slice().substring(0, 10) + "\n";
      } else {
        invoiceStatus += invoice.client.companyName + " : Sent, date unknown \n";
      }
    }

    if (invoice.invoicePaid) {
      if (invoice.invoicePaidDate) {
        invoiceStatus += invoice.client.companyName + " : Paid on " + invoice.invoicePaidDate.slice().substring(0, 10);
      } else {
        invoiceStatus += invoice.client.companyName + " : Paid";
      }
    }
    return invoiceStatus;
  });
  return (
    <>
      <div>
        {/* {invoicesSent.map( (invoice) => { */}
        {/*     return( <div>{invoice}</div> ) */}
        {/* } )} */}
        {/* <br/> */}
        {/* {invoicesPaid.map( (invoice) => { */}
        {/*     return( <div>{invoice}</div> ) */}
        {/* } )} */}
        {invoicesAll.map((invoice) => {
          return <div>{invoice}</div>;
        })}
      </div>
    </>
  );
}

export default InvoiceStatus;
