import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import LinkIcon from "@mui/icons-material/Link";
import { motion } from "framer-motion";

const Sidebar = ({ setOpenSideBar }) => {
  const history = useHistory();

  const onOutsideBarClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setOpenSideBar(!setOpenSideBar);
    }
  };

  useEffect(() => {
    // Applying on mount
    document.body.style.overflowY = "hidden";

    // Applying on unmount
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [setOpenSideBar]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        ease: "linear",
      }}
      className="fixed w-screen h-screen top-0 left-0 z-[100] bg-black/20"
      onClick={(e) => {
        onOutsideBarClick(e);
      }}
    >
      <motion.div
        initial={{ x: -320 }}
        animate={{ x: 0 }}
        exit={{ x: -320 }}
        transition={{
          ease: "linear",
        }}
        className="bg-[#424242] w-[250px] h-full"
      >
        <div className="absolute top-0 w-[250px]" style={{ backgroundColor: "#171720", color: "#e7ecee" }}>
          <ul>
            <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <HomeIcon />
              </button>
              <span className="text-base">Home</span>
            </li>
          </ul>
          <h3 className="p-4">Price Assesment</h3>
          <ul>
            <li
              className="flex gap-6 px-4 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/dashboard");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <DashboardIcon />
              </button>
              <span className="text-base">Dashboard</span>
            </li>
            <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/mydeals");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <ListAltIcon />
              </button>
              <span className="text-base">Deals</span>
            </li>
            <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/clients");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <PeopleIcon />
              </button>
              <span className="text-base">Clients</span>
            </li>
            {/* <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/leaderboard");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <EmojiEventsIcon />
              </button>
              <span className="text-base">Leaderboard</span>
            </li> */}
            <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/externallinks");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <LinkIcon />
              </button>
              <span className="text-base">External links</span>
            </li>
            <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/reporting");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <AssessmentIcon />
              </button>
              <span className="text-base">Reporting</span>
            </li>
          </ul>
          <div className="h-[1px] bg-white/10 w-full"></div>
          <ul>
            <li
              className="flex gap-6 p-4 hover:bg-white/10 cursor-pointer"
              onClick={() => {
                history.push("/feedback");
                setOpenSideBar(!setOpenSideBar);
              }}
            >
              <button>
                <ThumbsUpDownIcon />
              </button>
              <span className="text-base">Feedback</span>
            </li>
          </ul>
        </div>
        <div className="text-white" style={{ position: "absolute", bottom: 0, margin: 10 }}>
          version: {process.env.REACT_APP_VERSION}
        </div>
      </motion.div>
    </motion.div>
  );
};
export default Sidebar;
