import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { QueryDealsInput, NetworkgraphdataQuery } from "../../codegen/gql/graphql";

const GET_NETWORKGRAPH_DATA = graphql(`
  query networkgraphdata($input: QueryDealsInput) {
    networkgraphdata(input: $input) {
      nodes {
        name
        value
        id
        img
        olyxRefs
      }
      links {
        source
        target
      }
    }
  }
`);

const useNetworkGraphData = (input: QueryDealsInput) => useGeneratedTypeQuery<NetworkgraphdataQuery, QueryDealsInput>(GET_NETWORKGRAPH_DATA, input);

export default useNetworkGraphData;
