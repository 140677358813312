import { useHistory, withRouter } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import AnalyticsBar from "../../components/AnalyticsBar";
import CommissionPerClientList from "../../components/CommissionPerClientList/CommissionPerClientList";
import productStatisticsToIcicleData from "../../components/ReportingIcicle/productStatisticsToIcicleData";
import ReportingIcicleTree from "../../components/ReportingIcicle/ReportingIcicleTree";
import useDealStatistics from "../../typedHooks/queries/queryDealStatistics";
import useNetworkGraphData from "../../typedHooks/queries/queryNetworkGraph";
import usePaymentPerformanceInEur from "../../hooks/usePaymentPerformanceInEur";
import { startOfQuarter, endOfQuarter, startOfWeek, endOfWeek, sub, format } from "date-fns";
import queryString from "query-string";
import FormGenerator from "../../components/FormGenerator/FormGenerator";
import NetworkGraph from "../../components/NetworkGraph/NetworkGraph";

function ReportingPage() {
  const queryParams = queryString.parse(window.location.search);
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(ref && ref.current ? (ref.current.clientWidth as number) : null);
  const [height, setHeight] = useState(ref && ref.current ? (ref.current.clientHeight as number) : 600);

  const updateDimensions = () => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth - 40);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setHeight(ref.current?.clientHeight ? ref.current.clientHeight : 600);
    setWidth(ref.current?.clientWidth ? ref.current.clientWidth : null);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  const rawToday = new Date();
  const rawStartOfWeek = startOfWeek(rawToday, { weekStartsOn: 0 });
  const rawEndOfWeek = endOfWeek(rawToday, { weekStartsOn: 0 });

  const timeFrameOptions = [
    {
      id: 1,
      name: "This week",
      value: { from: format(rawStartOfWeek, "yyyy-MM-dd"), to: format(rawEndOfWeek, "yyyy-MM-dd") },
    },
    { id: 2, name: "Last week", value: { from: format(sub(rawStartOfWeek, { weeks: 1 }), "yyyy-MM-dd"), to: format(rawStartOfWeek, "yyyy-MM-dd") } },
    {
      id: 3,
      name: "Last quarter",
      value: { from: format(startOfQuarter(sub(rawToday, { months: 3 })), "yyyy-MM-dd"), to: format(endOfQuarter(sub(rawToday, { months: 3 })), "yyyy-MM-dd") },
    },
    {
      id: 4,
      name: "This quarter",
      value: { from: format(startOfQuarter(rawToday), "yyyy-MM-dd"), to: format(endOfQuarter(rawToday), "yyyy-MM-dd") },
    },
  ];

  const getTimeFrameById = (id: number | undefined, timeFrameOptions: any[]) => {
    const selectedTimeFrame = timeFrameOptions.find((option) => option.id === id);
    if (selectedTimeFrame) return selectedTimeFrame;
    else return timeFrameOptions[0];
  };

  const [timeFrameId, setTimeFrameId] = useState<number | undefined>(getTimeFrameById(Number(queryParams.timeFrameId), timeFrameOptions).id);
  const [timeFrame, setTimeFrame] = useState(getTimeFrameById(timeFrameId, timeFrameOptions));

  useEffect(() => {
    const selectedTimeFrame = getTimeFrameById(timeFrameId, timeFrameOptions);
    history.push({ search: queryString.stringify({ timeFrameId: selectedTimeFrame.id }) });
    setTimeFrame(selectedTimeFrame);
  }, [timeFrameId]);

  const formFields: any = [
    {
      initialValue: timeFrameId,
      isRequired: false,
      label: "Time frame",
      onChange: (timeFrameId: string) => {
        if (timeFrameId && timeFrameId !== "0") {
          setTimeFrameId(getTimeFrameById(Number(timeFrameId), timeFrameOptions).id);
        }
      },
      options: timeFrameOptions,
      order: "order-1",
      placeholder: "Select an option",
      type: "dropdown",
    },
  ];

  const [currentProductIds, setCurrentProductIds] = useState<string[]>([]);
  const [color, setColor] = useState("#CCCCCC");

  const { dataFromQuery } = useDealStatistics({
    after: timeFrame.value.from,
    before: timeFrame.value.to,
    productIds: currentProductIds,
  });

  const staticData = useDealStatistics({ after: timeFrame.value.from, before: timeFrame.value.to });
  const networkGraphData = useNetworkGraphData({ productIds: currentProductIds, after: timeFrame.value.from, before: timeFrame.value.to });
  const { paymentPerformanceInEur } = usePaymentPerformanceInEur({
    dealSearch: { productIds: currentProductIds, after: timeFrame.value.from, before: timeFrame.value.to },
  });

  const nodes = networkGraphData?.dataFromQuery?.networkgraphdata?.nodes ?? [];
  const links = networkGraphData?.dataFromQuery?.networkgraphdata?.links ?? [];

  let icicleData;
  if (staticData.dataFromQuery && staticData.dataFromQuery.dealstatistics && staticData.dataFromQuery.dealstatistics.productStatistics) {
    icicleData = productStatisticsToIcicleData(staticData.dataFromQuery.dealstatistics.productStatistics);
  }

  let clientStatistics;
  if (dataFromQuery && dataFromQuery.dealstatistics && dataFromQuery.dealstatistics.clientStatistics) {
    clientStatistics = dataFromQuery.dealstatistics.clientStatistics;
  }

  return (
    <div className="root">
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 md:h-[calc(100vh-156px)]">
        <div className={`w-full h-full`}>
          {icicleData && (
            <ReportingIcicleTree data={icicleData} colorSetter={setColor} idSetter={setCurrentProductIds} timeFrame={timeFrame}></ReportingIcicleTree>
          )}
        </div>
        <div className="col-span-1 lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-2 md:h-[calc(100vh-156px)] w-full">
          <div className="w-full rounded-xl col-span-2">
            <div className="h-[43px] w-[400px]">
              <FormGenerator
                customFormStyles={{
                  parent: " w-full border-box flex flex-wrap justify-start h-auto",
                  children: "w-full",
                }}
                formFields={formFields}
                layoutOptions={{ hasLabelsOnTop: false, hasCloseButton: false }}
              ></FormGenerator>
            </div>
            <AnalyticsBar
              onClick={() =>
                history.push(
                  `networkgraph?${queryString.stringify({
                    client: 0,
                    broker: 0,
                    product: 0,
                    olyxRef: 0,
                    dateAfter: timeFrame.value.from,
                    dateBefore: timeFrame.value.to,
                    dealInvoiceStatus: 0,
                  })}`
                )
              }
              formFields={undefined}
              dealStatistics={
                dataFromQuery && dataFromQuery.dealstatistics && dataFromQuery.dealstatistics.clientStatistics ? dataFromQuery.dealstatistics : undefined
              }
              paymentPerformanceInEur={paymentPerformanceInEur}
              tooltipExpandsOnTop={false}
            />
          </div>
          {(timeFrameId === 1 || timeFrameId === 2) && (
            <div className="w-full h-screen md:h-[738px] rounded-xl col-span-2 lg:col-span-1" ref={ref}>
              {nodes && links && nodes?.length !== 0 && links?.length !== 0 && (
                <NetworkGraph location="reporting" nodes={[...nodes]} links={[...links]} height={height} width={width}></NetworkGraph>
              )}
            </div>
          )}
          <div className={`w-full h-full md:h-[738px] col-start-1 col-end-2 col-span-2 lg:col-span-1`}>
            {clientStatistics && <CommissionPerClientList barColor={color} clientList={clientStatistics ?? []} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ReportingPage);
