import React from "react";

import { Google as GoogleIcon } from "mdi-material-ui";

const authProviders = [
  {
    providerId: "google.com",
    color: "#4285f4",
    icon: <GoogleIcon />,
    name: "Google",
  },
];

export default authProviders;
