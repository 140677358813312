import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

function DateSelect(props) {
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {/* <label htmlFor={props.id || props.name}> */}
        {/*   {props.label} */}
        {/*   {props.required && "*"} */}
        {/* </label> */}
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <Grid container justify="space-around"> */}
            <KeyboardDatePicker
              disableToolbar
              variant="outlined"
              fullWidth
              format="yyyy-MM-dd"
              margin="normal"
              id={props.name}
              label={props.label}
              value={props.value}
              onChange={props.onChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ maxWidth: 300 }}
            />
            {/* </Grid> */}
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
    </>
  );
}

export default DateSelect;
