import React from "react";
import helpers from "../../services/helpers";
import ExpRevWithInvoices from "../ExpRevWithInvoices";
import Pagination from "../Pagination";
import TableSort from "../TableSort";
import FormLongTermDeals from "../FormLongTermDeals/FormLongTermDeals";

const SortedPaginatedExpRevWithInv = ({ expRevAgainstInv, currentUserIsAdmin, dealId, clientId, tradeSide, enableChangesOnExpRev }) => {
  // sort the data
  const expRevs = [...expRevAgainstInv.expectedRevenue];

  const columns = [
    { name: "Status", label: "Status", columnSpan: 1, sortingFunction: "", type: "filter" },
    { name: "totalAmount", label: "Amount", columnSpan: 1, sortingFunction: helpers.sortNumber, type: "sort" },
    { name: "dateExpected", label: "Exp. invoice date", columnSpan: 2, sortingFunction: helpers.sortOnDate, type: "sort" },
    currentUserIsAdmin ? { name: "id", label: "Transaction ID", columnSpan: 2, sortingFunction: helpers.sortNumber, type: "sort" } : {},
  ];

  const filteredColumns = columns.filter((obj) => Object.keys(obj).length !== 0);

  return (
    <>
      <TableSort data={expRevs} columns={filteredColumns} currentUserIsAdmin={currentUserIsAdmin}>
        <Pagination>
          <ExpRevWithInvoices currentUserIsAdmin={currentUserIsAdmin} tradeSide={tradeSide} dealId={dealId} enableChangesOnExpRev={enableChangesOnExpRev} />
          {enableChangesOnExpRev && (
            <FormLongTermDeals currentUserIsAdmin={currentUserIsAdmin} tradeSide={tradeSide} dealId={dealId} clientId={clientId}></FormLongTermDeals>
          )}
        </Pagination>
      </TableSort>
    </>
  );
};

export default SortedPaginatedExpRevWithInv;
