const parsedStringToBoolean = (parsedString?: string, defaultValue = false): boolean => {
  if (parsedString === undefined) {
    return defaultValue;
  } else if (parsedString === "true") {
    return true;
  } else if (parsedString === "false") {
    return false;
  } else {
    return defaultValue;
  }
};
export default parsedStringToBoolean;
