import { useState } from "react";
import IcicleLegendTooltip from "./IcicleLegendTooltip";
import { AnimatePresence } from "framer-motion";

interface IcicleLegendProps {
  data: any;
  setClickedLeaf: any;
  idSetter: (id: string[]) => void;
  colorSetter: (color: string) => void;
  clickedLeaf: any;
}

const IcicleLegend = ({ data, setClickedLeaf, idSetter, colorSetter, clickedLeaf }: IcicleLegendProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);

  const clicked = (event: any, leaf: any) => {
    setClickedLeaf(clickedLeaf && leaf.data.color === clickedLeaf.data.color ? null : leaf);
    const ids = leaf.data.children.map((child: { id: any }) => child.id);
    idSetter(clickedLeaf && leaf.data.color === clickedLeaf.data.color ? [] : ids);
    colorSetter(clickedLeaf && leaf.data.color === clickedLeaf.data.color ? "#CCCCCC" : leaf.data.color);
  };

  return (
    <div className="flex bg-white justify-start items-center border border-gray-light p-1 rounded-xl mb-2 w-full flex-wrap min-h-[50px] gap-[10px]">
      {data.descendants().map((child: any) => {
        if (child.data.type === "leaf" && child.value !== 0) {
          return (
            <div className="relative w-[calc(25%-8px)]" key={child.data.name}>
              <button
                onClick={(event: any) => clicked(event, child)}
                onMouseOver={(event: any) => {
                  setOpen(true);
                }}
                className={`flex gap-2 w-full justify-start items-center legend-item cursor-pointer py-2 px-2 rounded-lg Paid hover:bg-[#d8edf4] transition-all ${
                  open && child.data.category === hoveredCategory ? "show" : "hide"
                }`}
                style={{ background: `${clickedLeaf && child.data.color === clickedLeaf.data.color ? "#d8edf4" : ""}` }}
                onMouseEnter={() => setHoveredCategory(child.data.category)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <div className="rounded-[2px] legendBlock pointer-events-none	w-[9px] h-[9px]" style={{ background: `${child.data.color}` }}></div>
                <span className="whitespace-nowrap max-w-[64px] text-ellipsis overflow-hidden text-xs">{child.data.category}</span>
              </button>

              <AnimatePresence>{open && child.data.category === hoveredCategory && <IcicleLegendTooltip data={child}></IcicleLegendTooltip>}</AnimatePresence>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default IcicleLegend;
