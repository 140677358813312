import React from "react";

import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PageVisibility from "react-page-visibility";
import CommissionCounter from "../../components/Sandbox/CommissionCounter";

const CommissionPage = () => {
  const xsSize = 8;
  const smSize = 6;
  const mdSize = 4;
  const lgSize = 3;
  const xlSize = 2;
  return (
    <PageVisibility>
      <>
        <div style={styles.root}>
          <div>
            <Grid container spacing={0}>
              <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize} xl={xlSize}>
                <div className="cardWrapper">
                  <CommissionCounter currency="EUR" />
                </div>
              </Grid>
              <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize} xl={xlSize}>
                <div className="cardWrapper">
                  <CommissionCounter currency="USD" />
                </div>
              </Grid>
              <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize} xl={xlSize}>
                <div className="cardWrapper">
                  <CommissionCounter currency="GBP" />
                </div>
              </Grid>
              <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize} xl={xlSize}>
                <div className="cardWrapper">
                  <CommissionCounter currency="CNY" />
                </div>
              </Grid>

              <div
                style={{
                  float: "left",
                  width: "18%",
                  height: "100%",
                  marginRight: 20,
                  backgroundColor: "#323741",
                  marginTop: 50,
                  display: "none",
                }}
              ></div>
            </Grid>
          </div>
        </div>
      </>
    </PageVisibility>
  );
};

const styles = {
  root: {
    paddingTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#142a41",
  },
  control: {
    padding: 10,
  },
  contentWrapper: {
    padding: 20,
  },
  middlePriceTitle: {
    marginLeft: 10,
  },
  chartContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 220,
  },
  selectControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 350,
    marginTop: 3,
    zIndex: 99,
  },
  entryBar: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

CommissionPage.propTypes = {
  // Properties
  // user: PropTypes.object,
};

export default withRouter(CommissionPage);
