import React from "react";

export default function TickerPriceChange({ tickerData }) {
  return (
    <div className={'whitespace-nowrap text-sm m-auto text-black font-["Gilroy SemiBold"]'}>
      <img src={tickerData.image} className={"w-12 mr-1 rounded-full p-1 pt-0.5 float-left"} />
      <span className={"text-lg font-bold"}>{tickerData.productName}</span>
      <span className={"text-xs ml-2"}>{tickerData.productInfo}</span>
      <span className={"text-cyan-400 ml-2 text-lg"}>{tickerData.priceStr}</span>
      <span className={(tickerData.changeDirWow == "-" ? "bg-red-400 " : "bg-green-400 ") + " text-xs ml-2"}>
        +{tickerData.changeAmtWow} {tickerData.changeDirWow == "-" ? "▼" : "▲"} {tickerData.changePctWow}%
      </span>
      <br />
      <span className={"text-sm"}>{tickerData.extraInfo}</span>
    </div>
  );
}
