import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& label.MuiInput-Input": {
      color: "green",
    },
    "& .MuiInput-underline-root": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#171720",
      },
      "&:hover fieldset": {
        borderColor: "#171720",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#171720",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#171720",
      },
    },
    // '&.MuiInput-underline': {
    //   borderBottomColor: 'yellow',
    // }
  },
})(TextField);

export default CssTextField;
