import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";
import { useState } from "react";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import DealConfirmationForm from "../DealConfirmationForm/DealConfirmationForm";

function DealConfirmation({ clientName, confirmation }: { clientName: string; confirmation: any }) {
  const [formIsOpen, setFormIsOpen] = useState(false);

  return (
    <div>
      {confirmation && confirmation.isConfirmed && (
        <div className="w-full flex flex-wrap h-[35px]">
          <div className="w-full flex flex-wrap gap-2 items-center">
            <div className="w-[27px] h-[27px] block rounded-full">
              <AvatarTooltip
                broker={confirmation.updatedBy.fullName ? confirmation.updatedBy.fullName : ""}
                img={confirmation.updatedBy.imageUrl ? confirmation.updatedBy.imageUrl : "default.png"}
                hover={true}
              />
            </div>
            <p className="font-text text-xs no-leading">{format(new Date(confirmation.updatedAt ?? null), "d MMM yyyy")}</p>
          </div>
          <div className="w-[2px] h-[8px] ml-[13px] bg-[#f4eced]"></div>
        </div>
      )}
      <div
        className={`w-full h-fit min-h-[36px] overflow-hidden rounded-xl border-[1px] border-[[#f4eced]] flex flex-wrap items-center gap-x-4 ${
          confirmation && confirmation.isConfirmed ? "" : " hover:bg-[#d8edf4]"
        }`}
      >
        <div
          onClick={() => confirmation && !confirmation.isConfirmed && setFormIsOpen(!formIsOpen)}
          className={`w-full flex flex-wrap p-[10px] gap-2 ${confirmation.isConfirmed ? "cursor-default" : "cursor-pointer"}`}
        >
          <button
            className={`self-center relative p-[3px] ${confirmation.isConfirmed ? "bg-[#22B0FF] p-[6px] cursor-default" : "bg-[#DC2334] m-[3px]"} rounded-full`}
          >
            {confirmation.isConfirmed && <CheckIcon className="absolute top-[1px] left-[1px]" sx={{ fontSize: 10, color: "white" }} />}
          </button>
          <p className="font-smallText">
            {confirmation.isConfirmed ? "Confirmed by " : "To be confirmed by "}
            {clientName}
          </p>
        </div>
        {formIsOpen && !confirmation.isConfirmed && <DealConfirmationForm confirmationId={confirmation.id} />}
      </div>
    </div>
  );
}

export default DealConfirmation;
