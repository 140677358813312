import { gql } from "@apollo/client";

const UPDATE_DEAL = gql`
  mutation Deal($input: DealInput) {
    deal(input: $input) {
      ... on Deal {
        id
        olyxRef
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

export default UPDATE_DEAL;
