import React from "react";
import { motion } from "framer-motion";
import CloseIcon from "@material-ui/icons/Close";

function FilterLabels(props) {
  return (
    <>
      {props && props.data && props.data.value > 0 && props.data.value !== "" && (
        <div key={JSON.stringify(props)} className="bg-white py-1 pr-1 pl-2 rounded-lg inline-block border-solid border border-light-blue-500">
          <span className="">
            {props.allData && props.allData.length > 0 && props.allData.find((item) => item.id === props.data.value) ? (
              <>
                {props.data.key === "client" && props.allData.find((item) => item.id === props.data.value).companyName}
                {props.data.key === "broker" && props.allData.find((item) => item.id === props.data.value).brokerName}
                {props.data.key === "product" && props.allData.find((item) => item.id === props.data.value).name}
                {props.data.key === "dealInvoiceStatus" && props.allData.find((item) => item.id === props.data.value).label}
              </>
            ) : (
              <>
                {props.data.key === "client" && "Client doesn't exist"}
                {props.data.key === "broker" && "Broker doesn't exist"}
                {props.data.key === "product" && "Product doesn't exist"}
                {props.data.key === "dealInvoiceStatus" && "Invoice status doesn't exist"}
              </>
            )}
            {props.data.key === "olyxRef" && props.data.value}
          </span>
          <motion.button
            onClick={() => {
              if (props.data.key === "olyxRef") {
                props.setRemoveFilter("");
              } else {
                props.setRemoveFilter("0");
              }
            }}
            initial={{ scale: 0.75 }}
            whileHover={{ scale: 0.75, rotate: 90 }}
            whileTap={{ scale: 0.8 }}
            className="transform scale-75 "
          >
            <CloseIcon />
          </motion.button>
        </div>
      )}
    </>
  );
}

export default FilterLabels;
