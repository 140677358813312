import React from "react";
import Moment from "react-moment";
import useDeals from "../../hooks/useDeals";
import helpers from "../../services/helpers";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import { motion } from "framer-motion";

const SingleClient = (props) => {
  const { deals } = useDeals({ clientIds: [props.data.id ?? null], take: 6 });

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.2,
            duration: 0.1,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.1,
          },
        }}
        className="p-4 lg:p-8 bg-gray-100"
      >
        {props.data && (
          <>
            <h3 className="text-xl">Client info</h3>
            <div className="flex flex-wrap w-full gap-4">
              <div className="w-full">
                <div>
                  <div className="w-full mt-5 border-collapse mb-7 flex flex-wrap">
                    <div className="flex w-full flex-col lg:flex-row mb-2">
                      <div className=" w-[80px]">
                        <p className="label-text">Name </p>
                      </div>
                      <div className=" w-full lg:w-[calc(100%-88px)] flex items-center">
                        <p className="lg:ml-4">{props.data.companyName}</p>
                      </div>
                    </div>
                    <div className="flex w-full flex-col lg:flex-row mb-2">
                      <div className=" w-[80px]">
                        <p className="label-text">Client Group</p>
                      </div>
                      <div className=" w-full lg:w-[calc(100%-88px)] flex items-center">
                        <p className="lg:ml-4">{props.data.clientGroup.clientGroupName}</p>
                      </div>
                    </div>
                    <div className="flex w-full flex-col lg:flex-row mb-2">
                      <div className=" w-[80px]">
                        <p className="label-text">Country</p>
                      </div>
                      <div className=" w-full lg:w-[calc(100%-88px)] flex items-center">
                        <p className="lg:ml-4">{props.data.country.name}</p>
                      </div>
                    </div>
                    <div className="flex w-full flex-col lg:flex-row mb-2">
                      <div className=" w-[80px]">
                        <p className="label-text">First deal</p>
                      </div>
                      <div className=" w-full lg:w-[calc(100%-88px)] flex items-center">
                        <p className="lg:ml-4">
                          <Moment format="D MMM YYYY">{new Date(props.data.firstDealDate)}</Moment>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {props?.data?.productWithBrokers && (
                <>
                  <div className="flex-1 p-4 bg-gray-100 rounded-lg">
                    <h3 className="mb-4 text-xl ">Products</h3>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                      <div>
                        <h4 className="mb-4">Sells:</h4>
                        <div className="flex flex-wrap gap-8">
                          {props.data &&
                            props.data.productWithBrokers &&
                            props.data.productWithBrokers
                              .filter((value) => value.side === "SELL")
                              .sort((first, second) => {
                                if (first && first.product && first.product.name && second && second.product && second.product.name) {
                                  return first.product.name.localeCompare(second.product.name);
                                }
                                return false;
                              })
                              .map((productWithBrokers, index) => (
                                <div key={index} className="w-full p-4 border border-gray-300 rounded-lg">
                                  <div className="flex gap-2">
                                    <span className="block">{productWithBrokers.product.name}</span>
                                  </div>
                                  <div className="w-full my-2 border-b border-gray-300"></div>
                                  <div className="flex flex-wrap w-full gap-2 pt-2">
                                    {productWithBrokers.brokers.map((broker) => (
                                      <>
                                        <div className="w-7 h-7">
                                          <AvatarTooltip
                                            img={broker && broker.userInfo && broker.userInfo.imageUrl ? broker.userInfo.imageUrl : ""}
                                            broker={broker.brokerName}
                                            hover={true}
                                          ></AvatarTooltip>
                                        </div>
                                      </>
                                    ))}{" "}
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-4">Buys:</h4>
                        <div className="flex flex-wrap gap-8">
                          {props.data &&
                            props.data.productWithBrokers &&
                            props.data.productWithBrokers
                              .filter((value) => value.side === "BUY")
                              .sort((first, second) => {
                                if (first && first.product && first.product.name && second && second.product && second.product.name) {
                                  return first.product.name.localeCompare(second.product.name);
                                }
                                return false;
                              })
                              .map((productWithBrokers, index) => (
                                <div key={index} className="w-full p-4 border border-gray-300 rounded-lg">
                                  <div className="flex gap-2">
                                    <span className="block">{productWithBrokers.product.name}</span>
                                  </div>
                                  <div className="w-full my-2 border-b border-gray-300"></div>
                                  <div className="flex flex-wrap w-full gap-2 pt-2">
                                    {productWithBrokers.brokers.map((broker) => (
                                      <div className="w-7 h-7">
                                        <AvatarTooltip
                                          img={broker && broker.userInfo && broker.userInfo.imageUrl ? broker.userInfo.imageUrl : ""}
                                          broker={broker.brokerName}
                                          hover={true}
                                        ></AvatarTooltip>
                                      </div>
                                    ))}{" "}
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="w-full mb-4 rounded-lg lg:flex-1">
                <h3 className="text-xl mb-9">Deals</h3>
                <div className="flex flex-wrap gap-y-4 justify-between">
                  {deals &&
                    deals.dealslist &&
                    deals.dealslist.slice(0, 6).map((deal, index) => (
                      <div key={index} className="relative bg-white w-full p-4 rounded-lg lg:mt-4 lg:grow lg:max-w-[calc(50%-16px)]">
                        <div>
                          <div className="flex justify-between align-center">
                            <div className="text-lg text-primary-blue ">
                              <a className="cursor-pointer link-deal" href={`/mydeals/${deal.id ?? ""}`} target="_blank">
                                {deal.olyxRef ?? ""}
                              </a>
                            </div>
                            <div className="mt-1 text-xs text-gray-500">
                              <Moment format="D MMM YYYY">{new Date(deal.dealDate)}</Moment>
                            </div>
                          </div>
                          <div className="w-full mb-2 border-b border-gray-300"></div>
                          <span className="pb-2">
                            {deal.volume ? Number(deal.volume).toLocaleString("en-US") + " " : ""}
                            {deal.unit
                              ? deal.unit.descriptionShort && deal.unit.descriptionShort !== "n/a"
                                ? deal.unit.descriptionShort + " "
                                : deal.unit.description && deal.unit.description !== "n/a"
                                ? deal.unit.description + " "
                                : " "
                              : " "}
                          </span>
                          <span className="pb-2">
                            {deal.productSpec && deal.productSpec.product && deal.productSpec.product.name ? deal.productSpec.product.name : ""}
                          </span>
                          <div>
                            <span className="pb-2">
                              {helpers.formatMoney(
                                deal.pricePerUnit ?? "",
                                deal.currencyDeal && deal.currencyDeal.description ? deal.currencyDeal.description : ""
                              )}
                            </span>
                          </div>
                          <div>
                            <span className="pb-2">
                              {" "}
                              {deal.seller && deal.seller.companyName ? helpers.shortenCompanyName(deal.seller.companyName) + " - " : ""}
                              {deal.buyer && deal.buyer.companyName ? helpers.shortenCompanyName(deal.buyer.companyName) : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </motion.div>
    </>
  );
};

export default SingleClient;
