import React from "react";
import MyDeals from "../../components/MyDeals";

function MyDealsPage() {
  return (
    <>
      <MyDeals />
    </>
  );
}

export default MyDealsPage;
