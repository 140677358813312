import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_DEALS_LIST from "./query_dealslist";

function useDeals(args) {
  const [deals, setDeals] = useState(false);

  const pageCursorEnd = deals && deals.dealslist && deals.dealslist.length > 0 ? deals.dealslist.slice(-1)[0].id : undefined;

  const [previousCursors, setPreviousCursors] = useState([]);

  const input = {
    take: args && args.take ? parseInt(args.take) : 12,
    brokerIds: args && args.brokerIds ? args.brokerIds : undefined,
    clientIds: args && args.clientIds ? args.clientIds : undefined,
    productIds: args && args.productIds ? args.productIds : undefined,
    olyxRefs: args && args.olyxRefs ? args.olyxRefs : undefined,
    after: args && args.after ? args.after : undefined,
    before: args && args.before ? args.before : undefined,
    dealInvoiceStatus: args && args.dealInvoiceStatus ? args.dealInvoiceStatus : undefined,
    hasOpenDealNote: args && args.hasOpenDealNote ? args.hasOpenDealNote : false,
  };

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(GET_DEALS_LIST, {
    fetchPolicy: "network-only",
    variables: {
      input: input,
    },
    pollInterval: args && args.pollInterval ? args.pollInterval : undefined,
  });

  const navigateForward = () => {
    fetchMore({ variables: { input: { cursor: pageCursorEnd, ...(input ?? undefined) } } });
    if (pageCursorEnd && pageCursorEnd !== 0 && pageCursorEnd !== "0") {
      setPreviousCursors(() => [...previousCursors, pageCursorEnd]);
    }
  };

  const navigateBack = () => {
    const previousPreviousCursorTemp = previousCursors.length <= 1 ? undefined : previousCursors.slice(-2)[0];
    fetchMore({ variables: { input: { cursor: previousPreviousCursorTemp, ...(input ?? undefined) } } });
    setPreviousCursors(() => [...previousCursors.slice(0, -1)]);
  };

  useEffect(() => {
    setDeals(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting deals");
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch]);

  return { deals, networkStatus, navigateForward, navigateBack };
}

export default useDeals;
