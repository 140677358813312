import { gql } from "@apollo/client";

const CREATE_EXP_REV_CHANGE = gql`
  mutation expectedRevenueChange($input: [ExpectedRevenueChangeInput!]) {
    expectedrevenuechange(input: $input) {
      ... on Error {
        errorCode
        message
      }
      ... on ExpectedRevenueChange {
        id
        amount
        description
        expectedRevenue {
          id
        }
      }
    }
  }
`;

export default CREATE_EXP_REV_CHANGE;
