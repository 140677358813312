import { FormEvent, useState } from "react";
import FormGenerator from "../FormGenerator/FormGenerator";
import useCreateOrUpdateInvoicesOnExpRev from "../../typedHooks/mutations/useCreateOrUpdateInvoicesOnExpRev";
import { toast } from "react-toastify";

export default function MatchExpRevInvForm({ invoiceId, closeFormFunction }: { invoiceId: number; closeFormFunction: () => void }) {
  const createOrUpdateExpRev = useCreateOrUpdateInvoicesOnExpRev();
  const [invoiceAmount, setInvoiceAmount] = useState<number>();
  const [expectedRevenueId, setExpectedRevenueId] = useState<number>();

  const matchInvoicesHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (!expectedRevenueId || invoiceAmount === undefined || !invoiceId) {
        throw new Error(`Missing information to match the invoice. ExpRev : ${expectedRevenueId} Amount: ${invoiceAmount} Invoice: ${invoiceId}`);
      }

      const createOrUpdateExpRevResult = await createOrUpdateExpRev({
        variables: { input: { expectedRevenueId, invoiceAmount: Math.round(invoiceAmount * 100), invoiceId } },
        refetchQueries: ["Invoices"],
      });

      if (createOrUpdateExpRevResult.data?.createorupdateinvoicesonexpectedrevenue?.__typename === "Error") {
        throw new Error(createOrUpdateExpRevResult.data.createorupdateinvoicesonexpectedrevenue.message?.toString());
      }

      toast.success("Successfully matched invoice to expRev", { autoClose: 5000 });
      closeFormFunction();
    } catch (e) {
      if (e instanceof Error && e.message.includes("Missing or wrong information to match the invoice.")) {
        toast.error(e.message, { autoClose: 5000 });
      } else if (
        e instanceof Error &&
        e.message?.includes("No 'ExpectedRevenue' record(s) (needed to inline the relation on 'InvoicesOnExpectedRevenue' record(s)) was found")
      ) {
        toast.error("ExpectedRevenue ID not found. Please check the ID.", { autoClose: 5000 });
      } else {
        toast.error("Something went wrong while matching invoice to expRev. Please contact support.", { autoClose: 5000 });
      }
    }
  };

  return (
    <FormGenerator
      customFormStyles={{
        parent: "relative w-1/3 bg-white flex flex-wrap p-5 justify-between pt-[56px]",
        children: "w-[100%] my-5",
      }}
      formFields={[
        {
          isRequired: true,
          label: "Transaction ID",
          onChange: (newExpRevId: string) => setExpectedRevenueId(parseInt(newExpRevId)),
          order: "order-1",
          showDefaultValue: false,
          step: "1",
          type: "number",
        },
        {
          isRequired: true,
          label: "Amount",
          onChange: (newAmount: string) => setInvoiceAmount(parseFloat(newAmount)),
          order: "order-1",
          showDefaultValue: false,
          step: "0.01",
          type: "number",
        },
        {
          label: "Submit",
          order: "order-1",
          type: "submit",
        },
      ]}
      closeFormFunction={closeFormFunction}
      layoutOptions={{ hasCloseButton: true, hasLabelsOnTop: true }}
      onSubmitFromParent={matchInvoicesHandler}
    ></FormGenerator>
  );
}
