import React, { Component } from "react";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class FeedbackPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.contentWrapper}>
        <h2 style={{ marginTop: 20 }}>Feedback</h2>
        <br />

        <p>
          We are always working hard on the dashboard and its new features, but if you have any feedback, suggestions, bug reports, please send an email to;
          <br /> <br />
          <a style={{ color: "lightgray" }} href="mailto: justjamesdev+nhsvfkv1fk27ug0hbrlr@boards.trello.com">
            {" "}
            Dashboard Feedback{" "}
          </a>{" "}
          <br />(<span style={{ color: "lightgray" }}>justjamesdev+nhsvfkv1fk27ug0hbrlr@boards.trello.com</span>)
          <br /> <br />
          To add a new ticket to our workflow!
        </p>
      </div>
    );
  }
}

const styles = {
  contentWrapper: {
    padding: 20,
    height: 300,
    marginLeft: 50,
  },
  middlePriceTitle: {
    marginLeft: 10,
  },
  chartContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 220,
  },
  selectControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 350,
    marginTop: 3,
    zIndex: 99,
  },
  entryBar: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

FeedbackPage.propTypes = {
  // Properties
  user: PropTypes.object,
};

export default withRouter(FeedbackPage);
