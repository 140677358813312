import React, { Component } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, Button, Snackbar } from "@material-ui/core";
import { auth, firestore } from "../../firebase";
import authentication from "../../services/authentication";
import appearance from "../../services/appearance";
import LaunchScreen from "../LaunchScreen";
import Bar from "../Bar/Bar.tsx";
import Router from "../../Router";
import DialogHost from "../DialogHost";
import { ToastContainer } from "react-toastify";
import olyxbase from "../../services/olyxbase";

const initialState = {
  ready: false,
  performingAction: false,
  theme: appearance.defaultTheme,
  user: null,
  userData: null,
  roles: [],
  aboutDialog: { open: false },
  signUpDialog: { open: false },
  signInDialog: { open: false },
  settingsDialog: { open: false },
  deleteAccountDialog: { open: false },
  signOutDialog: { open: false },
  snackbar: { autoHideDuration: 0, message: "", open: false },
  config: { cardTimeout1: 5, cardTimeout2: 120, cardTimeout3: 300 },
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  resetState = (callback) => {
    this.setState({ ready: true, theme: appearance.defaultTheme, user: null, userData: null, roles: [] }, callback);
  };

  setTheme = (theme, callback) => {
    if (!theme) {
      this.setState({ theme: appearance.defaultTheme }, callback);
      return;
    }
    this.setState({ theme: appearance.createTheme(theme) }, callback);
  };

  openDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];
    if (!dialog || dialog.open === undefined || null) return;
    dialog.open = true;
    this.setState({ dialog }, callback);
  };

  closeDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];
    if (!dialog || dialog.open === undefined || null) return;
    dialog.open = false;
    this.setState({ dialog }, callback);
  };

  closeAllDialogs = (callback) => {
    this.setState(
      {
        aboutDialog: { open: false },
        signUpDialog: { open: false },
        signInDialog: { open: false },
        settingsDialog: { open: false },
        deleteAccountDialog: { open: false },
        signOutDialog: { open: false },
      },
      callback
    );
  };

  deleteAccount = () => {
    this.setState({ performingAction: true }, () => {
      authentication
        .deleteAccount()
        .then(() => {
          this.closeAllDialogs(() => {
            this.openSnackbar("Deleted account");
          });
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;
          switch (code) {
            default:
              this.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.setState({
            performingAction: false,
          });
        });
    });
  };

  signOut = () => {
    this.setState({ performingAction: true }, () => {
      authentication
        .signOut()
        .then(() => {
          this.closeAllDialogs(() => {
            this.openSnackbar("Signed out");
          });
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              this.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.setState({
            performingAction: false,
          });
        });
    });
  };

  openSnackbar = (message, autoHideDuration = 2, callback) => {
    this.setState({ snackbar: { autoHideDuration: 1500 * autoHideDuration, message, open: true } }, () => {
      if (callback && typeof callback === "function") {
        callback();
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;
    this.setState({ snackbar: { message: clearMessage ? "" : snackbar.message, open: false } });
  };

  render() {
    const { ready, performingAction, theme, user, localUser, userData, roles } = this.state;
    const { aboutDialog, signUpDialog, signInDialog, settingsDialog, deleteAccountDialog, signOutDialog } = this.state;
    const { snackbar } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <CssBaseline />
        {/* <ErrorBoundary> */}
        {!ready && <LaunchScreen />}
        {ready && (
          <>
            <Router
              user={user}
              roles={roles}
              config={this.state.config}
              bar={
                <Bar
                  user={user}
                  localUser={localUser}
                  onSignInClick={() => this.openDialog("signInDialog")}
                  onAboutClick={() => this.openDialog("aboutDialog")}
                  onSignOutClick={() => this.openDialog("signOutDialog")}
                />
              }
              openSnackbar={this.openSnackbar}
            />
            <DialogHost
              performingAction={performingAction}
              theme={theme}
              user={user}
              userData={userData}
              openSnackbar={this.openSnackbar}
              dialogs={{
                aboutDialog: { dialogProps: { open: aboutDialog.open, onClose: () => this.closeDialog("aboutDialog") } },
                signUpDialog: {
                  dialogProps: {
                    open: signUpDialog.open,
                    onClose: (callback) => {
                      this.closeDialog("signUpDialog");
                      if (callback && typeof callback === "function") {
                        callback();
                      }
                    },
                  },
                },
                signInDialog: {
                  dialogProps: {
                    open: signInDialog.open,
                    onClose: (callback) => {
                      this.closeDialog("signInDialog");
                      if (callback && typeof callback === "function") {
                        callback();
                      }
                    },
                  },
                },
                settingsDialog: {
                  dialogProps: {
                    open: settingsDialog.open,
                    onClose: () => this.closeDialog("settingsDialog"),
                  },
                  props: {
                    onDeleteAccountClick: () => this.openDialog("deleteAccountDialog"),
                  },
                },
                deleteAccountDialog: {
                  dialogProps: {
                    open: deleteAccountDialog.open,
                    onClose: () => this.closeDialog("deleteAccountDialog"),
                  },
                  props: { deleteAccount: this.deleteAccount },
                },
                signOutDialog: {
                  dialogProps: { open: signOutDialog.open, onClose: () => this.closeDialog("signOutDialog") },
                  props: {
                    title: "Sign out?",
                    contentText: "While signed out you are unable to manage your profile and conduct other activities that require you to be signed in.",
                    dismissiveAction: (
                      <Button color="primary" onClick={() => this.closeDialog("signOutDialog")}>
                        Cancel
                      </Button>
                    ),
                    confirmingAction: (
                      <Button color="primary" disabled={performingAction} variant="contained" onClick={this.signOut}>
                        Sign Out
                      </Button>
                    ),
                  },
                },
              }}
            />
            <Snackbar autoHideDuration={snackbar.autoHideDuration} message={snackbar.message} open={snackbar.open} onClose={this.closeSnackbar} />
          </>
        )}
        {/* </ErrorBoundary> */}
      </MuiThemeProvider>
    );
  }
  UNSAFE_componentWillMount() {
    olyxbase.getConfig().then((config) => {
      this.state.config.cardTimeout1 = config.find((id) => {
        return id.configKey === "CARD_TIMEOUT_1";
      });
      this.state.config.cardTimeout2 = config.find((id) => {
        return id.configKey === "CARD_TIMEOUT_2";
      });
      this.state.config.cardTimeout3 = config.find((id) => {
        return id.configKey === "CARD_TIMEOUT_3";
      });
    });
  }
  componentDidMount() {
    this.onAuthStateChangedObserver = auth.onAuthStateChanged(
      (user) => {
        // The user is not signed in or doesn’t have a user ID.
        if (!user || !user.uid) {
          if (this.userDocumentSnapshotListener) {
            this.userDocumentSnapshotListener();
          }
          this.resetState();
          return;
        }
        // The user is signed in, begin retrieval of external user data.
        this.userDocumentSnapshotListener = firestore
          .collection("users")
          .doc(user.uid)
          .onSnapshot(
            (snapshot) => {
              const data = snapshot.data();
              authentication
                .getRoles()
                .then(async (value) => {
                  let localUser = await olyxbase.getMe();
                  if (!localUser) {
                    this.signOut();
                  }
                  this.setState({
                    ready: true,
                    user: user,
                    localUser: localUser,
                    userData: {}, //data,
                    roles: value || [],
                  });
                  localStorage.setItem("user", JSON.stringify(localUser));
                })
                .catch((reason) => {
                  this.resetState(() => {
                    const code = reason.code;
                    const message = reason.message;
                    switch (code) {
                      default:
                        this.openSnackbar(message);
                        return;
                    }
                  });
                });
            },
            (error) => {
              this.resetState(() => {
                const code = error.code;
                const message = error.message;
                switch (code) {
                  default:
                    this.openSnackbar(message);
                    return;
                }
              });
            }
          );
      },
      (error) => {
        this.resetState(() => {
          const code = error.code;
          const message = error.message;
          switch (code) {
            default:
              this.openSnackbar(message);
              return;
          }
        });
      }
    );
  }
  UNSAFE_componentWillUnmount() {
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }
    if (this.userDocumentSnapshotListener) {
      this.userDocumentSnapshotListener();
    }
  }
}

export default App;
