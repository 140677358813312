import React from "react";
import { compareAsc } from "date-fns";
import ProductToolTip from "../ProductToolTip/ProductToolTip";
import Toggle from "../FilterForm/Toggle";
import TotalBrokers from "../TotalBrokers/";
import TotalCommission from "../TotalCommission/";
import TotalDeals from "../TotalDeals/";
import TotalGeneric from "../TotalGeneric";

function AnalyticsBar({ formFields, dealStatistics, paymentPerformanceInEur, onClick, tooltipExpandsOnTop }) {
  const newClients = dealStatistics
    ? dealStatistics.clientStatistics.filter((clientStatistic) => compareAsc(new Date(clientStatistic.client.createdAt), new Date(dealStatistics?.after)) === 1)
    : [];

  return (
    <>
      {dealStatistics && (
        <div className="flex flex-wrap justify-between gap-2 mt-2">
          <div className="flex flex-wrap gap-4">
            <TotalDeals dealCount={dealStatistics.dealCount}></TotalDeals>
            <TotalBrokers onClick={onClick} dealStatistics={dealStatistics}></TotalBrokers>
            <TotalCommission paymentPerformanceInEur={paymentPerformanceInEur}></TotalCommission>
            <TotalGeneric
              countValue={dealStatistics.clientStatistics.length}
              wordSingular={"Client"}
              wordPlural={"Clients"}
              showTooltip={true}
              additionalDataToDisplay={newClients}
              notifyWhenAdditionalDataExists={true}
            >
              <ProductToolTip
                data={newClients}
                countValue={newClients.length}
                expandsOnTop={tooltipExpandsOnTop}
                leftColumnName="New client"
                rightColumnName="Introduced by"
              ></ProductToolTip>
            </TotalGeneric>
            <TotalGeneric
              countValue={dealStatistics.distinctProductCount}
              wordSingular={"Product"}
              wordPlural={"Products"}
              showTooltip={true}
              additionalDataToDisplay={dealStatistics.totalVolumePerUnit}
              notifyWhenAdditionalDataExists={false}
            >
              <ProductToolTip
                data={dealStatistics.totalVolumePerUnit}
                countValue={dealStatistics.distinctProductCount}
                expandsOnTop={tooltipExpandsOnTop}
                leftColumnName="Product"
                rightColumnName="Volume"
              ></ProductToolTip>
            </TotalGeneric>
          </div>
          <Toggle formFields={formFields}></Toggle>
        </div>
      )}
    </>
  );
}

export default AnalyticsBar;
