import React from "react";
import helpers from "../../services/helpers";

function TotalCommission({ paymentPerformanceInEur }) {
  const totalExpectedCommission = paymentPerformanceInEur ? paymentPerformanceInEur.reduce((sum, period) => period.expectedAmountEur.totalSum + sum, 0) : 0;

  return (
    <>
      <div className="inline-block gap-2 border border-gray-light px-2 py-2 rounded-xl text-base cursor-default bg-white">
        <span className="text-gray-dark mr-2">Total commission</span>
        <span>{helpers.formatMoneyKMB(totalExpectedCommission / 100, "EUR")}</span>
      </div>
    </>
  );
}

export default TotalCommission;
