import { gql } from "@apollo/client";

const GET_DEAL_OPTIONS = gql`
  query getDealOptions {
    clients {
      id
      companyName
      isActive
    }
    brokers {
      id
      brokerName
      isActive
    }
    countries {
      id
      name
      code
    }
    termsregions {
      id
      description
    }
    termsagreements {
      id
      description
    }
    categories {
      id
      name
    }
    products {
      id
      name
      category {
        id
      }
      isActive
    }
    units {
      id
      description
      isActive
    }
    currencies {
      id
      description
    }
    packages {
      id
      packageName
    }
    productspecpropertytypes {
      id
      description
    }
    dealstatuses {
      id
      description
      isActive
    }
  }
`;

export default GET_DEAL_OPTIONS;
