import { withRouter } from "react-router-dom";
import ExternalLinkSectionGenerator from "../../components/ExternalLinkSectionGenerator/ExternalLinkSectionGenerator";
import OlyxAcademyBookStack from "../../assets/olyx-academy-book-stack.png";
import DataVisualiser from "../../assets/data-visualiser.png";
import OlyxWiki from "../../assets/OLYX-wiki.png";
import TradingGame from "../../assets/trading-game.png";
import SpreadVisualiser from "../../assets/spread-visualiser.png";

function ExternalLinksPage() {
  const allSections = [
    {
      buttonExtraInfo: "Password: 0lyx020",
      buttonLink:
        "https://www.figma.com/proto/Z8KbjQELOuaguyZLC7wEMI/OLYX-academy?page-id=0%3A1&type=design&node-id=11-1376&viewport=1454%2C490%2C0.14&t=9KrtqzTqC9Jr1mU1-1&scaling=min-zoom&starting-point-node-id=11%3A1376&mode=design",
      buttonText: "Go to academy",
      description:
        "In your hands, you hold the key to your personal and professional growth. We embark on this educational journey together as we believe in the immense value that lies within our Academy training modules.",
      image: OlyxAcademyBookStack,
      order: 1,
    },
    {
      buttonLink: "https://data-visualizer.olyx.exchange/",
      buttonText: "Go to datavisualiser",
      description:
        "With the dataVisualiser (courtesy of Charles) we allow you to compare and plot prices of our assets over time to visualize known and unknown relationships. Use this tool to your advantage in exploring new trading opportunities.",
      image: DataVisualiser,
      order: 2,
    },
    {
      buttonLink: "https://www.notion.so/OLYX-Wiki-5b19eac259d249cb8cdf1477a7f60d5d",
      buttonText: "Go to OLYX wiki",
      description:
        "On our notion page we agregated our guidelines and device management documentation ranging from laptop/phone usage to Bitwarden and Hexnode configurations. Make sure to consult the Wiki for your IT-related questions.",
      image: OlyxWiki,
      order: 3,
    },
    {
      buttonLink: "https://trading-game-test.olyx.exchange/",
      buttonText: "Go to trading simulator",
      description:
        "Join our custom Olyx trading simulator in order to sharpen your skills and claim victory! Sign up as a trader or broker, take strategic positions, trade assets, and compete with colleagues.",
      image: TradingGame,
      order: 4,
    },
    {
      buttonLink: "/spreadvisualiser",
      buttonText: "Go to spread visualiser",
      description:
        "This page visualises the spread percentages of different products over time. Select the products you want to compare by clicking on the toggles on either side. Explore the liquidity of our most traded products.",
      image: SpreadVisualiser,
      order: 5,
    },
  ];

  return (
    <div id="externalLinksWrapper" className="flex flex-wrap">
      <ExternalLinkSectionGenerator allSections={allSections}></ExternalLinkSectionGenerator>
    </div>
  );
}

export default withRouter(ExternalLinksPage);
