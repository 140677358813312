import React from "react";
// import PropTypes from 'prop-types';
// import styles from './AddDealQuickPage.css';
import AddDealSelect from "./../../components/AddFormDeal/AddDealSelect";
import { withRouter } from "react-router-dom";

function AddDealExtensivePage(props) {
  return (
    <>
      <AddDealSelect extensive={true} />
    </>
  );
}

// AddDealQuickPage.defaultProps = {};

// AddDealQuickPage.propTypes = {
// };

export default withRouter(AddDealExtensivePage);
