import React from "react";
import { Card, CardHeader } from "@material-ui/core";

function UserCard(props) {
  const user = props.user;

  return (
    <Card>
      <CardHeader title={`${user.firstName} ${user.lastName}`} subheader={user.username} />
    </Card>
  );
}

export default UserCard;
