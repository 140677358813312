import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";

const MARGIN = { top: 30, right: 30, bottom: 50, left: 50 };

type LineGraphProductProps = {
  productName: string;
  color: string;
};

type LineGraphProps = {
  width: number;
  height: number;
  data: { [key: string]: any }[];
  products: LineGraphProductProps[];
};

export const LineGraph = ({ width, height, data, products }: LineGraphProps) => {
  // Specify the chart’s dimensions.
  const axesRef = useRef(null);
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const groups = products.map((product) => `${product.productName}-mid`);
  const colorGroups = products.map((product) => product.color);

  // Y axis
  let dataFilderedWithSelection = data;
  if (dataFilderedWithSelection) {
    dataFilderedWithSelection = dataFilderedWithSelection.map((obj) => {
      const filteredObj: any = {};
      if (Object.prototype.hasOwnProperty.call(obj, "x")) {
        filteredObj["x"] = obj["x"];
      }
      // Include keys that are present in the 'groups' array
      groups.forEach((key) => {
        if (key in obj) {
          filteredObj[key] = obj[key];
          filteredObj[key] = obj[key];
        }
      });
      return filteredObj;
    });
  }

  const topYValues =
    dataFilderedWithSelection &&
    dataFilderedWithSelection.map((obj) => {
      return (
        Math.max(
          ...Object.entries(obj)
            .filter(([key, _]) => key !== "x")
            .map(([_, value]) => value)
        ) * 1.15
      );
    });

  const yMax = Math.max(...topYValues);
  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, yMax])
      .range([boundsHeight, 0]);
  }, [data, height]);

  const customTimeParser = d3.timeParse("%Y-%m-%d");
  const times = data.map((d: any) => customTimeParser(d.date)).filter((item: Date | null) => item !== null) as Date[]; // filter out null values
  const dateDomain = times.length > 0 ? (d3.extent(times) as [Date, Date]) : [new Date(), new Date()];
  const xScale = d3
    .scaleTime()
    .domain(dateDomain.map((d) => d as Date))
    .range([0, boundsWidth]);

  const colorScale = d3
    .scaleOrdinal<string>()
    .domain(groups)
    .range(colorGroups);

  useEffect(() => {
    const svgElement = d3.select(axesRef.current);
    svgElement.selectAll("*").remove();
    const xAxisGenerator = d3.axisBottom(xScale);
    svgElement
      .append("g")
      .attr("transform", "translate(0," + boundsHeight + ")")
      .call(xAxisGenerator);

    const yAxisGenerator = d3.axisLeft(yScale);
    svgElement.append("g").call(yAxisGenerator);
  }, [xScale, yScale, boundsHeight]);

  // Build the line
  const areaBuilder = d3
    .area<any>()
    .x((d) => {
      return xScale(customTimeParser(d.data.date) as Date);
    })
    .y1((d) => yScale(d[1] - d[0]))
    .y0((d) => yScale(0));

  const stackSeries = d3
    .stack()
    .keys(groups)
    .order(d3.stackOrderNone)
    .offset(d3.stackOffsetNone);
  const series = stackSeries(data);

  const allPath = series.map((serie, i) => {
    const path = areaBuilder(serie);
    return <path key={i} d={path!} opacity={1} stroke={colorScale(serie.key)} fill="none" fillOpacity={0.2} />;
  });

  return (
    <div>
      <svg width={width} height={height}>
        <rect width="100%" height="100%" fill="#eaeaea" />
        <g width={boundsWidth} height={boundsHeight} transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}>
          {allPath}
        </g>
        <g width={boundsWidth} height={boundsHeight} ref={axesRef} transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`} />
      </svg>
    </div>
  );
};
