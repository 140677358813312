import { useState, useEffect } from "react";

function useIntersectionObserver(ref) {
  const [isOutOfScreen, setIsOutOfScreen] = useState(false);
  // sticking the filter to the top
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => {
          setIsOutOfScreen(e.intersectionRatio < 1);
        },
        {
          threshold: [1],
        }
      );

    observer.observe(cachedRef);

    // unmount
    return function() {
      observer.unobserve(cachedRef);
    };
  }, [ref]);

  return isOutOfScreen;
}

export default useIntersectionObserver;
