import Client from "../../interfaces/Clients";
import FormGenerator from "../FormGenerator/FormGenerator";
import Product from "../../interfaces/Products";
import UpdateDealFormProps from "../../interfaces/UpdateDealFormProps";
import useDealOptions from "../../hooks/useDealOptions";
import useUpdateDeal from "../../hooks/useUpdateDeal";
import { Broker } from "../../interfaces/Broker";
import { CircularProgress } from "@material-ui/core";
import { Currency } from "../../interfaces/Currency";
import { FormEvent, useEffect, useState } from "react";
import { FormField } from "../../interfaces/FormGeneratorProps";
import { format, add } from "date-fns";
import { toast } from "react-toastify";

const UpdateDealForm = ({ initialValues, closeAfterSubmit }: UpdateDealFormProps) => {
  const [updateDeal] = useUpdateDeal();
  const [dealOptions]: any = useDealOptions();
  const [description, setDescription] = useState<string | undefined>(initialValues.description);
  const [dealDate, setDealDate] = useState<string | undefined>(initialValues.dealDate);
  const [productId, setProductId] = useState<string | undefined>(initialValues.productId);
  const [volume, setVolume] = useState<string | undefined>(initialValues.volume);
  const [unitId, setUnitId] = useState<string | undefined>(initialValues.unitId);
  const [pricePerUnit, setPricePerUnit] = useState<string | undefined>(initialValues.pricePerUnit);
  const [commissionSeller, setCommissionSeller] = useState<string | undefined>(initialValues.commissionSeller);
  const [commissionBuyer, setCommissionBuyer] = useState<string | undefined>(initialValues.commissionBuyer);
  const [dateExpectedBuyer, setDateExpectedBuyer] = useState<string | undefined>(initialValues.dateExpectedBuyer);
  const [dateExpectedSeller, setDateExpectedSeller] = useState<string | undefined>(initialValues.dateExpectedSeller);
  const [brokerBuyerId, setBrokerBuyer] = useState<string | undefined>(initialValues.brokerBuyerId);
  const [brokerSellerId, setBrokerSeller] = useState<string | undefined>(initialValues.brokerSellerId);
  const [buyerId, setBuyer] = useState<string | undefined>(initialValues.buyerId);
  const [sellerId, setSeller] = useState<string | undefined>(initialValues.sellerId);
  const [currencyDealId, setDealCurrency] = useState<string | undefined>(initialValues.currencyDealId);
  const [currencyCommissionBuyerId, setCurrencyCommissionBuyer] = useState<string | undefined>(initialValues.currencyCommissionBuyerId);
  const [currencyCommissionSellerId, setCurrencyCommissionSeller] = useState<string | undefined>(initialValues.currencyCommissionSellerId);

  const [productSpec, setProductSpec] = useState<string | undefined>(initialValues.productSpec);
  const [productSpecsToShow, setProductSpecsToShow] = useState<string[] | undefined>(undefined);

  const [sellerCommissionPerUnitValue, setSellerCommissionPerUnitValue] = useState<string | undefined>(undefined);
  const [sellerCommissionPercentageValue, setSellerCommissionPercentageValue] = useState<string | undefined>(undefined);
  const [buyerCommissionPerUnitValue, setBuyerCommissionPerUnitValue] = useState<string | undefined>(undefined);
  const [buyerCommissionPercentageValue, setBuyerCommissionPercentageValue] = useState<string | undefined>(undefined);

  const today: Date = new Date();
  const formattedToday: string = format(today, "yyyy-MM-dd");
  const formattedFortnight: string = format(add(today, { weeks: 2 }), "yyyy-MM-dd");

  const additionalProductSpecs: { [name: string]: string[] } = {
    biodiesel: ["CFPP -20", "CFPP -15", "CFPP -10", "CFPP -5", "CFPP -3", "CFPP 0", "CFPP +3", "CFPP +5", "CFPP +10"],
    biomethane: [
      "Biomethane with THG-Quote",
      "22",
      "22 Uncertified waste",
      "22 Certified waste",
      "23",
      "23 Uncertified waste",
      "23 Certified waste",
      "24",
      "24 Uncertified waste",
      "24 Certified waste",
      "25",
      "25 Uncertified waste",
      "25 Certified waste",
    ],
    hee: ["24", "25"],
    oerver: ["2022", "2023", "2024", "2025"],
    rtfc: ["22 NC", "23 NC", "24 NC", "25 NC"],
    thg: ["22 C", "22 A", "22 O", "22 B", "23 C", "23 A", "23 O", "23 B", "24 C", "24 A", "24 O", "24 B", "25 C", "25 A", "25 O", "25 B"],
    uco: ["FFA 5", "FFA 3", "FFA 7"],
    uer: ["22 German", "23 German", "22 FQD", "23 FQD", "24 German", "25 German"],
  };

  const productIdToProductSpecMap: { [name: string]: string[] } = {
    "2": additionalProductSpecs.biodiesel,
    "29": additionalProductSpecs.biodiesel,
    "32": additionalProductSpecs.biomethane,
    "78": additionalProductSpecs.biomethane,
    "79": additionalProductSpecs.biomethane,
    "87": additionalProductSpecs.hee,
    "77": additionalProductSpecs.oerver,
    "34": additionalProductSpecs.rtfc,
    "5": additionalProductSpecs.thg,
    "10": additionalProductSpecs.thg,
    "72": additionalProductSpecs.thg,
    "1": additionalProductSpecs.uco,
    "36": additionalProductSpecs.uer,
    "73": additionalProductSpecs.uer,
  };

  const getAndSetProductSpecsByProductId = (productId: string) => {
    const correspondingSpecs = productIdToProductSpecMap[productId];

    if (correspondingSpecs && productSpec) {
      if (!correspondingSpecs.includes(productSpec)) {
        setProductSpec(undefined);
      }
    }

    if (productId && correspondingSpecs) {
      setProductSpecsToShow(correspondingSpecs);
    } else {
      setProductSpecsToShow(undefined);
      setProductSpec(undefined);
    }
  };

  const handleVolumeOrPricePerUnitChange = (
    volume: string | undefined,
    pricePerUnit: string | undefined,
    commissionPerUnitValue: string | undefined,
    commissionPercentageValue: string | undefined,
    setCommissionPerUnitValue: (value: string | undefined) => void,
    setCommissionPercentageValue: (value: string | undefined) => void,
    setCommission: (value: string | undefined) => void
  ) => {
    // units or percentage??
    const unitOrPercentageValue = commissionPerUnitValue ?? commissionPercentageValue ?? undefined;
    const unitOrPercentage = commissionPerUnitValue ? "units" : commissionPercentageValue ? "percentage" : "none";
    if (unitOrPercentage === "none") {
      return 1;
    }
    handleCommissionBaseChange(
      unitOrPercentageValue,
      unitOrPercentage,
      setCommissionPerUnitValue,
      setCommissionPercentageValue,
      setCommission,
      volume,
      pricePerUnit
    );
  };

  const handleCommissionBaseChange = (
    value: string | undefined,
    commissionBase: string,
    setCommissionPerUnitValue: (value: string | undefined) => void,
    setCommissionPercentageValue: (value: string | undefined) => void,
    setCommission: (value: string | undefined) => void,
    volume: string | undefined,
    pricePerUnit: string | undefined
  ) => {
    if (commissionBase === "units") {
      setCommissionPerUnitValue(value);
      setCommissionPercentageValue(undefined);
      setCommission(volume ? (+(Number(value) * Number(volume)).toFixed(2)).toString() : "");
    } else {
      setCommissionPercentageValue(value);
      setCommissionPerUnitValue(undefined);
      setCommission(volume && pricePerUnit ? (+((Number(value) * (Number(volume) * Number(pricePerUnit))) / 100).toFixed(2)).toString() : "");
    }
  };

  useEffect(() => {
    if (productSpec === "no description") {
      setProductSpec(undefined);
    }
    getAndSetProductSpecsByProductId(productId ?? "0");
  }, [productId]);

  const formFields: FormField[] = [
    {
      label: `Olyxref: ${initialValues.olyxRef}`,
      order: "order-first",
      type: "heading",
    },
    {
      initialValue: description,
      isRequired: false,
      label: "Deal Description",
      onChange: (description: string) => {
        setDescription(description ?? "");
      },
      order: "order-first",
      placeholder: "",
      showDefaultValue: false,
      type: "text",
    },
    {
      initialValue: dealDate ? format(new Date(dealDate), "yyyy-MM-dd") : formattedToday,
      isRequired: true,
      label: "Deal Date as on Deal Confirmation*",
      newDateCanBeBeforeToday: true,
      onChange: (dealDate: string) => {
        setDealDate(dealDate ?? "");
      },
      order: "order-1",
      showDefaultValue: dealDate ? false : true,
      type: "date",
    },
    {
      label: "Product specs",
      order: "order-2",
      type: "heading",
    },
    {
      initialValue: productId,
      isRequired: false,
      label: "Product",
      onChange: (productId: string) => {
        setProductId(productId && productId !== "0" ? productId : undefined);
      },
      options: dealOptions?.products
        ? dealOptions?.products.filter((product: Product) => product.isActive).map((product: Product) => ({ id: String(product.id), name: product.name }))
        : [],
      order: "order-2",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: volume,
      isRequired: false,
      label: "Volume",
      onChange: (volume: string) => {
        setVolume(volume ?? undefined);
        handleVolumeOrPricePerUnitChange(
          volume,
          pricePerUnit,
          sellerCommissionPerUnitValue,
          sellerCommissionPercentageValue,
          setSellerCommissionPerUnitValue,
          setSellerCommissionPercentageValue,
          setCommissionSeller
        );
        handleVolumeOrPricePerUnitChange(
          volume,
          pricePerUnit,
          buyerCommissionPerUnitValue,
          buyerCommissionPercentageValue,
          setBuyerCommissionPerUnitValue,
          setBuyerCommissionPercentageValue,
          setCommissionBuyer
        );
      },
      order: "order-3",
      placeholder: "",
      showDefaultValue: false,
      step: "1",
      type: "number",
    },
    {
      initialValue: unitId,
      isRequired: false,
      label: "Unit",
      onChange: (unitId: string) => {
        setUnitId(unitId && unitId !== "0" ? unitId : undefined);
      },
      options: dealOptions?.units ? dealOptions?.units.map((currency: Currency) => ({ id: String(currency.id), name: currency.description })) : [],
      order: "order-3",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: currencyDealId,
      isRequired: false,
      label: "Currency Deal",
      onChange: (currencyId: string) => {
        setDealCurrency(currencyId && currencyId !== "0" ? currencyId : undefined);
      },
      options: dealOptions?.currencies ? dealOptions?.currencies.map((currency: Currency) => ({ id: String(currency.id), name: currency.description })) : [],
      order: "order-3",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: pricePerUnit,
      isRequired: false,
      label: "Price per Unit",
      onChange: (pricePerUnit: string) => {
        setPricePerUnit(pricePerUnit ? pricePerUnit : undefined);
        handleVolumeOrPricePerUnitChange(
          volume,
          pricePerUnit,
          sellerCommissionPerUnitValue,
          sellerCommissionPercentageValue,
          setSellerCommissionPerUnitValue,
          setSellerCommissionPercentageValue,
          setCommissionSeller
        );
        handleVolumeOrPricePerUnitChange(
          volume,
          pricePerUnit,
          buyerCommissionPerUnitValue,
          buyerCommissionPercentageValue,
          setBuyerCommissionPerUnitValue,
          setBuyerCommissionPercentageValue,
          setCommissionBuyer
        );
      },
      order: "order-3",
      placeholder: "",
      showDefaultValue: false,
      step: "0.01",
      type: "number",
    },
    {
      label: "Buyer / Seller",
      order: "order-4",
      type: "heading",
    },
    {
      initialValue: sellerId,
      isRequired: false,
      label: "Seller",
      onChange: (sellerId: string) => {
        setSeller(sellerId && sellerId !== "0" ? sellerId : undefined);
      },
      options: dealOptions?.clients
        ? dealOptions?.clients.filter((client: Client) => client.isActive).map((client: Client) => ({ id: String(client.id), name: client.companyName }))
        : [],
      order: "order-4",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: brokerSellerId,
      isRequired: false,
      label: "Broker Seller",
      onChange: (brokerId: string) => {
        setBrokerSeller(brokerId && brokerId !== "0" ? brokerId : undefined);
      },
      options: dealOptions?.brokers
        ? dealOptions?.brokers.filter((broker: Broker) => broker.isActive).map((broker: Broker) => ({ id: String(broker.id), name: broker.brokerName }))
        : [],
      order: "order-4",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: buyerId,
      isRequired: false,
      label: "Buyer",
      onChange: (buyerId: string) => {
        setBuyer(buyerId && buyerId !== "0" ? buyerId : undefined);
      },
      options: dealOptions?.clients
        ? dealOptions?.clients.filter((client: Client) => client.isActive).map((client: Client) => ({ id: String(client.id), name: client.companyName }))
        : [],
      order: "order-4",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: brokerBuyerId,
      isRequired: false,
      label: "Broker Buyer",
      onChange: (brokerId: string) => {
        setBrokerBuyer(brokerId && brokerId !== "0" ? brokerId : undefined);
      },
      options: dealOptions?.brokers
        ? dealOptions?.brokers.filter((broker: Broker) => broker.isActive).map((broker: Broker) => ({ id: String(broker.id), name: broker.brokerName }))
        : [],
      order: "order-4",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      label: "Commission Seller",
      order: "order-4",
      type: "heading",
    },
    {
      initialValue: currencyCommissionSellerId,
      isRequired: false,
      label: "Currency Commission Seller",
      onChange: (currencyId: string) => {
        setCurrencyCommissionSeller(currencyId && currencyId !== "0" ? currencyId : undefined);
      },
      options: dealOptions?.currencies ? dealOptions?.currencies.map((currency: Currency) => ({ id: String(currency.id), name: currency.description })) : [],
      order: "order-4",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: sellerCommissionPerUnitValue ?? "",
      isRequired: false,
      label: "Unit Commission from Seller",
      onChange: (submittedValue: string) => {
        handleCommissionBaseChange(
          submittedValue,
          "units",
          setSellerCommissionPerUnitValue,
          setSellerCommissionPercentageValue,
          setCommissionSeller,
          volume,
          pricePerUnit
        );
      },
      order: "order-4",
      placeholder: "",
      showDefaultValue: false,
      step: "any",
      type: "number",
    },
    {
      initialValue: sellerCommissionPercentageValue ?? "",
      isRequired: false,
      label: "Percentage of DealValue commission from Seller",
      onChange: (submittedValue: string) => {
        handleCommissionBaseChange(
          submittedValue,
          "percentage",
          setSellerCommissionPerUnitValue,
          setSellerCommissionPercentageValue,
          setCommissionSeller,
          volume,
          pricePerUnit
        );
      },
      order: "order-4",
      placeholder: "",
      showDefaultValue: false,
      step: "any",
      type: "number",
    },
    {
      initialValue: commissionSeller,
      isRequired: false,
      label: "Total Commission from Seller",
      onChange: (commissionSeller: string) => {
        setSellerCommissionPerUnitValue(undefined);
        setSellerCommissionPercentageValue(undefined);
        setCommissionSeller(commissionSeller ? commissionSeller : undefined);
      },
      order: "order-4",
      placeholder: "",
      showDefaultValue: false,
      step: "0.01",
      type: "number",
    },
    {
      label: "Commission Buyer",
      order: "order-4",
      type: "heading",
    },
    {
      initialValue: currencyCommissionBuyerId,
      isRequired: false,
      label: "Currency Commission Buyer",
      onChange: (currencyId: string) => {
        setCurrencyCommissionBuyer(currencyId && currencyId !== "0" ? currencyId : undefined);
      },
      options: dealOptions?.currencies ? dealOptions?.currencies.map((currency: Currency) => ({ id: String(currency.id), name: currency.description })) : [],
      order: "order-4",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: buyerCommissionPerUnitValue ?? "",
      isRequired: false,
      label: "Unit Commission from Buyer",
      onChange: (submittedValue: string) => {
        handleCommissionBaseChange(
          submittedValue,
          "units",
          setBuyerCommissionPerUnitValue,
          setBuyerCommissionPercentageValue,
          setCommissionBuyer,
          volume,
          pricePerUnit
        );
      },
      order: "order-4",
      placeholder: "",
      showDefaultValue: false,
      step: "any",
      type: "number",
    },
    {
      initialValue: buyerCommissionPercentageValue ?? "",
      isRequired: false,
      label: "Percentage of DealValue commission from Buyer",
      onChange: (submittedValue: string) => {
        handleCommissionBaseChange(
          submittedValue,
          "percentage",
          setBuyerCommissionPerUnitValue,
          setBuyerCommissionPercentageValue,
          setCommissionBuyer,
          volume,
          pricePerUnit
        );
      },
      order: "order-4",
      placeholder: "",
      showDefaultValue: false,
      step: "any",
      type: "number",
    },
    {
      initialValue: commissionBuyer,
      isRequired: false,
      label: "Total Commission from Buyer",
      onChange: (commissionBuyer: string) => {
        setBuyerCommissionPerUnitValue(undefined);
        setBuyerCommissionPercentageValue(undefined);
        setCommissionBuyer(commissionBuyer ? commissionBuyer : undefined);
      },
      order: "order-4",
      placeholder: "",
      showDefaultValue: false,
      step: "0.01",
      type: "number",
    },
    {
      label: "Invoice date",
      order: "order-5",
      type: "heading",
    },
    {
      initialValue: dateExpectedSeller ? format(new Date(dateExpectedSeller), "yyyy-MM-dd") : formattedFortnight,
      isRequired: false,
      label: "Estimated Invoice Date Seller",
      newDateCanBeBeforeToday: false,
      onChange: (dateExpectedSeller: string) => {
        setDateExpectedSeller(dateExpectedSeller ? dateExpectedSeller : undefined);
      },
      order: "order-5",
      showDefaultValue: dateExpectedSeller ? false : true,
      type: "date",
    },
    {
      initialValue: dateExpectedBuyer ? format(new Date(dateExpectedBuyer), "yyyy-MM-dd") : formattedFortnight,
      isRequired: false,
      label: "Estimated Invoice Date Buyer",
      newDateCanBeBeforeToday: false,
      onChange: (dateExpectedBuyer: string) => {
        setDateExpectedBuyer(dateExpectedBuyer ? dateExpectedBuyer : undefined);
      },
      order: "order-5",
      showDefaultValue: dateExpectedBuyer ? false : true,
      type: "date",
    },
    {
      label: "Submit changes",
      order: "order-5",
      type: "submit",
    },
  ];

  if (productSpecsToShow) {
    const additionalFormField: FormField = {
      initialValue: productSpec,
      isRequired: false,
      label: "Product and Spec Description",
      onChange: (productSpec: string) => {
        setProductSpec(productSpec && productSpec !== "0" ? productSpec : undefined);
      },
      options: productSpecsToShow.map((productSpec: any) => ({ id: productSpec, name: productSpec })),
      order: "order-2",
      placeholder: "Select an option",
      type: "dropdown",
    };

    formFields.push(additionalFormField);
  }

  const mutationKeys = {
    input: {
      dealId: initialValues.id,
      dealDate: dealDate ? dealDate : formattedToday,
      updatedById: "1",
      productId: productId ? productId : undefined,
      productSpecInput: {
        description: productSpec ? productSpec : "no description",
        productId: productId ? productId : undefined,
        productSpecPropertyInput: [],
      },
      currencyDealId: currencyDealId ? currencyDealId : undefined,
      pricePerUnit: pricePerUnit ? pricePerUnit : undefined,
      unitId: unitId ? unitId : undefined,
      volume: volume ? volume : undefined,
      currencyCommissionBuyerId: currencyCommissionBuyerId ? currencyCommissionBuyerId : undefined,
      currencyCommissionSellerId: currencyCommissionSellerId ? currencyCommissionSellerId : undefined,
      commissionBuyer: commissionBuyer ? commissionBuyer : undefined,
      commissionSeller: commissionSeller ? commissionSeller : undefined,
      buyerId: buyerId && buyerId !== "0" ? buyerId : undefined,
      sellerId: sellerId && sellerId !== "0" ? sellerId : undefined,
      dealStatusId: initialValues.dealStatusId ? initialValues.dealStatusId : undefined,
      brokerSellerId: brokerSellerId ? brokerSellerId : undefined,
      brokerBuyerId: brokerBuyerId ? brokerBuyerId : undefined,
      description: description ? description : undefined,
      dateExpectedSeller: dateExpectedSeller ? dateExpectedSeller : formattedFortnight,
      dateExpectedBuyer: dateExpectedBuyer ? dateExpectedBuyer : formattedFortnight,
      newInput: false,
    },
  };

  const onSubmitFromParent = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateDeal({ variables: mutationKeys });

    toast.dismiss();

    toast.success(`Thanks for submitting info ${initialValues.olyxRef ? "for deal olyxRef " + initialValues.olyxRef : undefined}!`, {
      autoClose: 8000,
    });

    if (closeAfterSubmit) {
      closeAfterSubmit();
    }
  };

  if (dealOptions) {
    return (
      <FormGenerator
        closeFormFunction={closeAfterSubmit}
        customFormStyles={{
          parent:
            "h-full  w-full pb-[80px] overflow-auto border-box flex flex-wrap gap-y-8 bg-white p-5 rounded-xl justify-start md:justify-between lg:bp-[40px] lg:justify-between lg:p-[40px] lg:pb-[40px]",
          children: "w-[100%] md:w-[48.5%]",
        }}
        formFields={formFields}
        layoutOptions={{ hasLabelsOnTop: true, hasCloseButton: true }}
        onSubmitFromParent={onSubmitFromParent}
      ></FormGenerator>
    );
  } else {
    return (
      <>
        <div className=" w-fit h-[120px] bg-white rounded-lg flex flex-wrap justify-center items-center gap-4 p-5">
          <span className="text-center">Loading deal data...</span>
          <CircularProgress />
        </div>
      </>
    );
  }
};

export default UpdateDealForm;
