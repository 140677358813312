import { useMutation } from "@apollo/client";
import MUTATION_PICKUP_DEALNOTE from "./mutation_pickupdealnote";

function usePickUpDealNote(props) {
  const [pickUpDealNote, { data, loading, error }] = useMutation(MUTATION_PICKUP_DEALNOTE, {
    variables: {
      status: "INPROGRESS",
    },
    refetchQueries: ["dealnotesoverview", "OpenDealNoteCount"],
  });

  return { pickUpDealNote, data, loading, error };
}

export default usePickUpDealNote;
