import React, { useState } from "react";
import { motion } from "framer-motion";

function ProductToolTip({
  data,
  countValue,
  expandsOnTop,
  leftColumnName,
  rightColumnName,
}: {
  data: any;
  countValue: number;
  expandsOnTop: boolean;
  leftColumnName: string;
  rightColumnName: string;
}) {
  const [showMore, setShowMore] = useState<boolean>(false);
  const numberOfProducts = 10;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      key={countValue}
      className={`z-50 gap-2 border px-2 py-2 rounded-xl text-xs absolute ${
        expandsOnTop ? "bottom-14" : "top-14"
      } left-1/2 transform -translate-x-1/2 bg-black/80 text-white ${data[0].product ? "w-72" : "w-[420px]"} max-h-72 overflow-y-auto`}
    >
      <div className={`${data[0].product ? "grid-cols-2" : "grid-cols-3"} grid gap-2`}>
        <div className={`${data[0].product ? "" : "col-span-2"} font-bold`}>{leftColumnName}</div>
        <div className="font-bold">{rightColumnName}</div>
        {data.slice(0, numberOfProducts).map((data: any) => {
          return (
            <>
              <div className={`${data.product ? "" : " whitespace-nowrap overflow-hidden text-ellipsis col-span-2"}`}>
                {data.product ? data.product.name : data.client.companyName}
              </div>
              <div>
                {data.volumePerUnit ? (
                  data.volumePerUnit.map((data: any) => {
                    return (
                      <div>
                        <span>{Number(data.totalVolume).toLocaleString("en-US")} </span>
                        <span>{data.unit.descriptionShort ?? data.unit.description}</span>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <span>{data.client.broker.brokerName}</span>
                  </div>
                )}
              </div>
            </>
          );
        })}
        {showMore &&
          data.slice(numberOfProducts).map((data: any) => {
            return (
              <>
                <div className={`${data.product ? "" : " whitespace-nowrap text-ellipsis col-span-2"}`}>
                  {data.product ? data.product.name : data.client.companyName}
                </div>
                <div>
                  {data.volumePerUnit ? (
                    data.volumePerUnit.map((data: any) => {
                      return (
                        <div>
                          <span>{Number(data.totalVolume).toLocaleString("en-US")} </span>
                          <span>{data.unit.descriptionShort ?? data.unit.description}</span>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <span>{data.client.broker.brokerName}</span>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        {data.length > 10 && (
          <button
            onClick={() => setShowMore(!showMore)}
            className={`${
              data[0].product ? "col-start-2 col-end-3" : "col-start-3 col-end-4"
            } place-self-end mt-2 w-auto underline text-white hover:text-primary-blue transition-all`}
          >
            {showMore ? "show less" : "show more"}
          </button>
        )}
      </div>
    </motion.div>
  );
}

export default ProductToolTip;
