import React from "react";
import _ from "lodash";
import { format } from "d3-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import { CandlestickSeries } from "react-stockcharts/lib/series";
import { fitWidth } from "react-stockcharts/lib/helper";
import { last } from "react-stockcharts/lib/utils";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";

import { PriceCoordinate } from "react-stockcharts/lib/coordinates";

class CandleStickChart extends React.Component {
  render() {
    var initialData = this.props.initialData;

    var lowlow = _.minBy(initialData, function(i) {
      return i.low;
    });
    if (lowlow) lowlow = lowlow.low;
    var highhigh = _.maxBy(initialData, function(i) {
      return i.high;
    });
    if (highhigh) highhigh = highhigh.high;

    var maxopen = _.maxBy(initialData, function(i) {
      return i.open;
    });
    if (maxopen && maxopen.open > highhigh) highhigh = maxopen.open;
    var maxclose = _.maxBy(initialData, function(i) {
      return i.close;
    });
    if (maxclose && maxclose.close > highhigh) highhigh = maxclose.close;

    var minopen = _.minBy(initialData, function(i) {
      return i.open;
    });
    if (minopen && minopen.open < lowlow) lowlow = minopen.open;
    var minclose = _.minBy(initialData, function(i) {
      return i.close;
    });
    if (minclose && minclose.close < lowlow) lowlow = minclose.close;

    // console.log(this.props.productId + ' ' + JSON.stringify(minclose))
    // console.log(this.props.productId + ' ' + lowlow)

    var width = this.props.width;

    //console.log(initialData);
    var type = "hybrid";

    var ratio = 1;

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => new Date(d.date));
    const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(initialData);
    const xExtents = [xAccessor(last(data)), xAccessor(data[data.length - 11])];

    return (
      <ChartCanvas
        height={110}
        ratio={ratio}
        width={width}
        margin={{ left: 35, right: 60, top: 10, bottom: 30 }}
        type={type}
        seriesName="MSFT"
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
        clamp="right"
        mouseMoveEvent={false}
        panEvent={false}
        zoomEvent={false}
      >
        <Chart id={1} yExtents={(d) => [d.high, d.low]}>
          <CandlestickSeries
            stroke={(d) => (d.close > d.open ? "#00b526" : "#dc2334")}
            wickStroke={(d) => (d.close > d.open ? "#00b526" : "#dc2334")}
            fill={(d) => (d.close > d.open ? "#00b526" : "#dc2334")}
            opacity={1}
          />

          <PriceCoordinate
            at="right"
            orient="right"
            price={highhigh}
            lineOpacity={1}
            strokeWidth={1}
            lineStroke="#ffffff"
            textFill="#fff"
            arrowWidth={5}
            strokeDasharray="ShortDash"
            displayFormat={
              this.props.price == highhigh ? format("") : this.props.productId == "658c8244-8dce-1e41-01a5-6eb62fd1a797" ? format(".3f") : format(".3r")
            }
            opacity={0}
          />

          <PriceCoordinate
            at="left"
            orient="left"
            price={this.props.price}
            fontsize={0}
            strokeWidth={1}
            lineStroke="#22b0ff"
            textFill="#171720"
            arrowWidth={5}
            strokeDasharray="ShortDash"
            displayFormat={format("")}
            opacity={0}
            lineOpacity={1}
          />

          <PriceCoordinate
            at="right"
            orient="right"
            price={lowlow}
            lineOpacity={1}
            strokeWidth={1}
            lineStroke="#ffffff"
            arrowWidth={5}
            strokeDasharray="ShortDash"
            displayFormat={
              this.props.price == lowlow ? format("") : this.props.productId == "658c8244-8dce-1e41-01a5-6eb62fd1a797" ? format(".3f") : format(".3r")
            }
            opacity={0}
            textFill="#fff"
          />
        </Chart>
      </ChartCanvas>
    );
  }
}

CandleStickChart = fitWidth(CandleStickChart);

export default CandleStickChart;
