import React, { useState, useRef, useEffect } from "react";
import { RegularInputProps } from "../../../interfaces/InputProps";

const RegularInput = (props: RegularInputProps) => {
  const [inputHasBeenActive, setInputHasBeenActive] = useState<boolean>(false);

  let ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current && props.order === "order-first") {
      ref.current.focus();
    }
  }, [props.order]);

  return (
    <div className={`relative ${props.customFormStyles ? props.customFormStyles : "h-12 max-w-fit"} ${props.order ? props.order : ""}`} id="rootDiv">
      <div className={`p-[4px] h-[2.7em] flex flex-nowrap rounded-xl border border-solid border-gray-200 text-base items-center`}>
        <div className="flex flex-row flex-nowrap items-center relative w-full h-full">
          <label
            className={`${
              props.hasLabelsOnTop ? "absolute " : "inherit "
            }bg-white left-1 bottom-[30px] whitespace-nowrap md:m-auto px-1 text-[#9aa6b2] max-md:text-sm md:text-base`}
            htmlFor={props.label}
          >
            {props.label}:
          </label>
          <div className="w-full h-full text-dark-gray-filters grow relative flex items-center flex-row flex-nowrap bg-transparent text-opacity-50">
            <input
              className={`w-full text-dark-gray-filters focus:outline-0 border-[1px] border-transparent 
                focus:border-[1px] ${props.showDefaultValue ? (inputHasBeenActive ? "text-opacity-100" : "text-opacity-50") : "text-opacity-100"}
                invalid:border-red-200 invalid:bg-red-100  h-[100%] pl-2 pr-2 rounded-[6px]`}
              data-testid={props.label}
              ref={ref}
              defaultValue={props.initialValue ? props.initialValue : ""}
              id={props.label}
              required={props.isRequired ?? true}
              type={props.type}
              onChange={props.onChange ? (e) => props.onChange(e.target.value) : undefined}
              onFocus={() => {
                setInputHasBeenActive(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularInput;
