import React from "react";

export default function StyledMentionParagraph({ textContent }) {
  const tagMatch = /(@\[[^\]]+\]\([^)]+\))/g;
  const matches = textContent.split(tagMatch);

  return (
    <>
      {matches.map((sentencePart, index) => {
        if (tagMatch.test(sentencePart)) {
          return (
            <span key={index} className="bg-white py-0.5 text-justify text-primary-blue z-50 relative">
              {sentencePart.replace(/@\[(.+?)\]\((.+?)\)/g, "@$1")}
            </span>
          );
        } else {
          return <span key={index}>{sentencePart}</span>;
        }
      })}
    </>
  );
}
