import React from "react";
import FilterForm from "../FilterForm/FilterForm";
import { motion } from "framer-motion";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

function MobileFilters(props) {
  const variants = {
    open: {
      x: "0vw",
      transition: {
        ease: "easeIn",
        delay: 0.5,
      },
    },
    closed: {
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0.5,
      },
    },
  };

  return (
    <motion.div
      animate={props.isOpen ? "open" : "closed"}
      initial={"closed"}
      variants={variants}
      transition={{ duration: 0.05 }}
      className="flex fixed bottom-0 -right-0 z-50"
    >
      <div className="px-2 py-4 cursor-pointer bg-primary-blue rounded-l-lg text-white fixed bottom-16 right-[100vw] flex gap-1" onClick={props.onClick}>
        <FilterAltIcon
          style={{
            width: "0.8rem",
            height: "auto",
          }}
        ></FilterAltIcon>
        <div className="">Filter</div>
      </div>
      <div className="w-full h-auto pb-10 pt-2 z-50 shadow-[0_-35px_60px_-15px_rgba(0,0,0,0.3)] bg-white rounded-t-lg transition ease-in-out delay-150 duration-500">
        <button onClick={props.onClick} className="bg-primary-blue text-white rounded-l-lg p-2 absolute z-[4000] bottom-14 right-0">
          <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
        </button>
        <FilterForm formFields={props.formFields} adminClaim={props.adminClaim}></FilterForm>
      </div>
    </motion.div>
  );
}

export default MobileFilters;
