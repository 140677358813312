import { withRouter } from "react-router-dom";
import OverviewComments from "../../components/OverviewComments/OverviewComments";

const HomePage = withRouter((props) => {
  return (
    <div className="root">
      <OverviewComments></OverviewComments>
    </div>
  );
});

export default HomePage;
