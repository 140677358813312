import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import TextInput from "../FormFields/TextInput";
import TextInputCalculated from "../FormFields/TextInputCalculated";
import Immutable from "../FormFields/Immutable";
import SelectInputConditionalOptions from "../FormFields/SelectInputConditionalOptions";
import AutoCompleteInput from "../FormFields/AutoCompleteInput";
import DateInput from "../FormFields/DateInput";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import InFormButton from "../FormFields/InFormButton";
import { useHistory } from "react-router-dom";

import { gql, useQuery, useMutation } from "@apollo/client";

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});

const AddFormGeneric = (props) => {
  const initialValues = useMemo(() => props.initialValues, []);
  const [validationSchema, setValidationSchema] = useState({});
  // const [formFields, setFormFields] = useState([...props.formFields]);
  const [formOptions, setFormOptions] = useState(false);

  // GraphQL
  const EMPTY_QUERY = gql`
    query {
      brokers {
        id
      }
    }
  `;
  const { loading, error, data } = useQuery(props.graphqlQuery ? props.graphqlQuery : EMPTY_QUERY);
  // console.log(data)

  //
  const history = useHistory();

  // choose between using graphqlQuery here, or formOptions prop from parent component
  useEffect(() => {
    setFormOptions(() => {
      if (props.formOptions) {
        return props.formOptions;
      } else if (data) {
        return data;
      } else {
        return false;
      }
    });
  });

  const [addFunction, mutationResponse] = useMutation(props.graphqlMutation);

  // const fetchedData = useFetch(["countries","clients","brokers"]);
  const [optionObjects1, setOptionObjects1] = useState([
    ...new Set(props.formFields.filter((item) => item.optionObjects !== undefined).map((item) => item.optionObjects)),
  ]);
  //    if (optionObjects1) {
  //        console.log('optionObjects1')
  //        console.log(optionObjects1)
  //    }

  // const [fetchedData,setFetchedData] = useFetch(
  //     optionObjects1,props.bearerToken
  // );
  // if (fetchedData) {
  //     console.log("fetchedData")
  //     console.log(fetchedData)
  // }

  //set initialValues, validationSchema when formFields update
  useEffect(() => {
    console.log("useEffect AddFormGeneric");

    // setSelectOptions(() => selectOptions1)
    // setInitialValues( () => {
    //     console.log("setInitialValues")
    //     return(getInitialValues(formFields) );
    // })

    // setInitialValues( () => {
    //     console.log("setInitialValues")
    //     return( getInitialValues( formFields ) )
    // })

    setValidationSchema(() => {
      console.log("setValidationSchema");
      return getFirstValidationSchema(props.formFields);
    });
  }, [props.formFields]);

  // render new formFields when props.formFields changes
  // useEffect( () => {
  //     setFormFields( [...props.formFields] )
  // }, [props.formFields] )

  const getFirstValidationSchema = (formFields) => {
    const validationSchema = formFields.reduce((acc, formField) => {
      const validation = {};
      // const required = (formField.required ? re)
      if (formField.yupType === "string") {
        if (formField.required) {
          validation[formField["name"]] = Yup.string()
            .max(500, "max 500 char")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.string().max(500, "max 500 char");
        }
      }
      if (formField.yupType === "integer") {
        if (formField.required) {
          validation[formField["name"]] = Yup.number()
            .integer("Choose int")
            .min(1, "Please choose an option")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.number()
            .integer("Choose int")
            .min(1, "Please choose an option");
        }
      }
      if (formField.yupType === "float") {
        if (formField.required) {
          validation[formField["name"]] = Yup.number()
            .min(0, "Choose positive number")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.number().min(0, "Choose positive number");
        }
      }
      if (formField.yupType === "date") {
        if (formField.required) {
          validation[formField["name"]] = Yup.date()
            .min("2010-01-01", "Must be in 2010 or later")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.date().min("2010-01-01", "Must be in 2010 or later");
        }
      }

      return { ...acc, ...validation };
    }, {});
    return validationSchema;
  };

  const ErrorToken = () => {
    const { errors } = useFormikContext();
    //console.log("useformikcontext")
    //console.log(errors)
    const errorsKeys = Object.keys(errors);
    Object.keys(errors).length === 0 ? console.log("no errors in form") : console.log("found errors in form");
    if (errorsKeys.length === 0) {
      return null;
    } else {
      const errorMessage = errorsKeys.reduce(
        (acc, key) => (
          <>
            {acc}
            <div>
              <strong>{key.toString()}</strong>
              {" : " + errors[key].toString()}
            </div>
          </>
        ),
        <div>Form is not filled out complete and/or correctly, please check the following fields</div>
      );
      console.log(errorMessage);
      return <div>{errorMessage}</div>;
    }
    //return null
  };

  const onSubmit = (values, actions) => {
    console.log("onsubmit");
    // console.log(values);
    // const newInput = true;
    if (props.graphql && values) {
      console.log("graphql mutation");
      addFunction({ variables: props.mutationKeys(values) });
      alert("Input Submitted");
      // props.history.push('/pricedashboardlayout')
      if (props.afterSubmit) {
        props.afterSubmit(values, history);
        // actions.setSubmitting(false)
      } else {
        actions.resetForm(props.initialValues);
        window.location.reload(false);
      }
      // console.log(mutationResponse.data)
    } else if (values) {
      props.addFunction(values, props.newInput, props.bearerToken); //,props.newInput);
      props.newInput ? alert("New input submitted") : alert("Change of entry submitted");
    }
    // alert("Input Submitted");
  };

  return (
    <>
      <div style={styles.root}>
        {formOptions && <div style={{ marginBottom: 20 }}>Please fill out the details below. Compulsory fields are marked *.</div>}
        <Formik initialValues={props.initialValues} validationSchema={Yup.object(validationSchema)} enableReinitialize={true} onSubmit={onSubmit}>
          <Form>
            <Grid container spacing={3} style={{ backgroundColor: "#1f3a56" }}>
              {formOptions &&
                props.formFields
                  .sort((formFieldA, formFieldB) => formFieldA.rank - formFieldB.rank)
                  .map((formfield) => {
                    if (formfield.fieldType === "text") {
                      return <TextInput label={formfield.label} name={formfield.name} type={formfield.fieldType} required={formfield.required} />;
                    } else if (formfield.fieldType === "textCalculated") {
                      return <TextInputCalculated label={formfield.label} name={formfield.name} type={formfield.fieldType} required={formfield.required} />;
                    } else if (formfield.fieldType === "date") {
                      return (
                        <DateInput
                          label={formfield.label}
                          name={formfield.name}
                          type={formfield.fieldType}
                          defaultValue={formfield.defaultValue}
                          required={formfield.required}
                        />
                      );
                    } else if (formfield.fieldType === "select") {
                      return (
                        <AutoCompleteInput
                          id={formfield.name}
                          label={formfield.label}
                          name={formfield.name}
                          optionObjects={[...formOptions[formfield.optionObjects]]}
                          optionFieldsToDisplay={[...formfield.optionFieldsToDisplay]}
                          required={formfield.required}
                          conditionOn={formfield.conditionOn ? formfield.conditionOn : undefined}
                        />
                      );
                    } else if (formfield.fieldType === "selectConditionalOptions") {
                      return (
                        <SelectInputConditionalOptions
                          label={formfield.label}
                          name={formfield.name}
                          optionObjects={[...formOptions[formfield.optionObjects]]}
                          optionFieldsToDisplay={formfield.optionFieldsToDisplay}
                          conditionOn={formfield.conditionOn}
                        />
                      );
                    } else if (formfield.fieldType === "immutable") {
                      return <Immutable label={formfield.label} name={formfield.name} type={formfield.fieldType} />;
                    } else if (formfield.fieldType === "titleSection") {
                      return (
                        <>
                          <Grid item sm={12} xs={12} style={{ backgroundColor: "#115980" }}>
                            <h4>{formfield.title}</h4>
                          </Grid>
                        </>
                      );
                    } else if (formfield.fieldType === "newLine") {
                      return (
                        <>
                          <Grid item sm={12} xs={12}></Grid>
                        </>
                      );
                    } else if (formfield.fieldType === "button") {
                      return <InFormButton onClick={() => formfield.onClick(props.formFields.length)}>{formfield.label}</InFormButton>;
                    } else {
                      return <div>Error: unknow fieldType</div>;
                    }
                  })}
              {!formOptions && <h3>Loading formOptions from Database. If this take too long (more than 10 sec.), connection to DB may experience trouble.</h3>}
              <Grid item sm={12} xs={12}>
                {formOptions && (
                  <Button type="submit" onClick={() => onSubmit()} variant="outlined" color="primary">
                    {props.submitButtonText ? props.submitButtonText : "Submit"}
                  </Button>
                )}
              </Grid>
            </Grid>
            {/* <InvisibleToken/> */}
            <ErrorToken />
          </Form>
        </Formik>
        {/* { formOptions && <button onClick={ () => props.extraButtonAddFormFieldOnClick()}>{props.extraButtonAddFormFieldText}</button> } */}
      </div>
    </>
  );
};
const styles = {
  root: {
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#115980",
  },
};

export default withRouter(AddFormGeneric);
