import React from "react";
import { useField, useFormikContext } from "formik";

function SelectInputConditionalOptions({ label, optionObjects, optionFieldsToDisplay, conditionOn, ...props }) {
  const [field, meta] = useField(props);
  const { values, submitForm } = useFormikContext();
  // console.log("useFormikContext")
  // console.log(values)
  // console.log(values[conditionOn])
  // console.log(optionObjects)
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select className="select-input" {...field} {...props}>
        {optionObjects
          .filter((item) => {
            // console.log("item");
            // console.log(item[conditionOn]);
            return item[conditionOn] === Number(values[conditionOn]);
          })
          .sort((first, second) => first.id - second.id)
          .map((item) => {
            return <option value={item.id}>{optionFieldsToDisplay.reduce((acc, key) => acc + item[key].toString() + " ", "")}</option>;
          })}
      </select>

      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
}

export default SelectInputConditionalOptions;
