import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function ClapIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 285.247 285.247">
      <path
        d="M73.416,78.551c6.3,4.554,10.427,11.936,10.491,20.27l0.233,8.12l68.919-70.186c4.849-4.849,5.595-12.651,1.34-18.027
		c-2.685-3.391-6.6-5.086-10.516-5.087c-3.43,0-6.859,1.302-9.461,3.903L73.416,78.551z"
      />
      <path
        d="M16.828,191.558l4.458-5.182L33.431,98.07c0.433-11.632,8.774-21.271,19.79-23.7l-0.354-12.393
		c0-7.286-5.962-13.247-13.248-13.247c-7.286,0-13.247,5.961-13.247,13.247l-12.72,92.483c-0.001,0.01-0.006,0.02-0.012,0.026
		L1.359,168.764c-1.918,2.23-1.791,5.561,0.291,7.639L16.828,191.558z"
      />
      <path
        d="M168.804,39.367c5.506,1.297,10.443,4.402,14.047,8.955c2.402,3.035,3.984,6.479,4.783,10.063l7.153-7.152
		c4.849-4.85,5.595-12.651,1.339-18.028c-2.684-3.391-6.6-5.086-10.515-5.086c-3.43,0-6.859,1.301-9.462,3.902L168.804,39.367z"
      />
      <path
        d="M253.342,44.554c1.198,0,2.408-0.358,3.461-1.104l14.984-10.603c2.705-1.913,3.346-5.658,1.432-8.363
		c-1.913-2.705-5.658-3.347-8.363-1.432l-14.984,10.603c-2.705,1.913-3.346,5.658-1.432,8.363
		C249.609,43.671,251.461,44.554,253.342,44.554z"
      />
      <path
        d="M232.089,29.4c0.695,0.262,1.409,0.386,2.11,0.386c2.428,0,4.713-1.484,5.617-3.891l6.46-17.182
		c1.166-3.101-0.403-6.561-3.505-7.727c-3.102-1.167-6.561,0.404-7.727,3.505l-6.46,17.182
		C227.418,24.774,228.988,28.234,232.089,29.4z"
      />
      <path
        d="M279.248,55.623c-0.041,0-0.082,0-0.124,0l-18.351,0.154c-3.313,0.067-5.944,2.606-5.877,5.918
		c0.066,3.271,2.739,5.928,5.997,5.928c0.041,0,0.082,0,0.124,0l18.351-0.313c3.313-0.068,5.944-2.732,5.877-6.045
		C285.179,57.994,282.506,55.623,279.248,55.623z"
      />
      <path
        d="M237.96,176.552c5.572-5.572,5.179-14.941-1.183-19.977c-2.382-1.886-5.241-2.789-8.106-2.789
		c-3.605,0-7.221,1.428-9.922,4.129l-29.974,29.974c-1.206,1.206-2.788,1.81-4.37,1.81c-1.582,0-3.163-0.604-4.37-1.81l-0.078-0.079
		c-2.612-2.611-2.612-6.847,0-9.458l48.349-48.35c4.85-4.849,5.596-12.651,1.34-18.028c-2.684-3.391-6.6-5.086-10.515-5.086
		c-3.43,0-6.859,1.301-9.462,3.903l-48.811,48.811c-1.258,1.258-2.906,1.887-4.555,1.887c-1.649,0-3.298-0.629-4.556-1.887
		c-2.553-2.554-2.553-6.693,0-9.246l62.081-62.081c4.849-4.849,5.596-12.651,1.34-18.028c-2.684-3.391-6.6-5.086-10.515-5.086
		c-3.431,0-6.86,1.301-9.462,3.903l-62.374,62.373c-1.274,1.275-2.946,1.913-4.617,1.913c-1.671,0-3.343-0.638-4.617-1.913
		c-2.519-2.519-2.519-6.603,0-9.121l48.518-48.518c4.849-4.849,5.596-12.651,1.34-18.028c-2.684-3.391-6.6-5.086-10.515-5.086
		c-3.431,0-6.859,1.301-9.462,3.903l-70.431,70.431c-1.251,1.251-2.795,1.813-4.309,1.813c-3.103,0-6.088-2.36-6.191-5.986
		L71.908,99.02c0-7.286-5.961-13.248-13.247-13.248c-7.286,0-13.248,5.962-13.248,13.248l-12.719,92.482
		c-0.002,0.011-0.006,0.02-0.013,0.027l-12.28,14.276c-1.919,2.23-1.792,5.563,0.291,7.64l69.67,69.56
		c1.089,1.086,2.525,1.641,3.969,1.641c1.2,0,2.405-0.383,3.419-1.162l15.655-12.022c0.671-0.515,1.446-0.862,2.271-1.043
		c27.159-5.92,58.076-29.66,87.652-59.237L237.96,176.552z"
      />
    </SvgIcon>
  );
}
