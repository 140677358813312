import React from "react";
import AddFormDeal from "../../components/AddFormDeal/AddFormDeal";
import { withRouter } from "react-router-dom";
import "./AddDealPage.css";

function AddDealPage() {
  return <AddFormDeal newInput={true} />;
}

export default withRouter(AddDealPage);
