import { gql } from "@apollo/client";

const GET_EXP_REV_CHANGE_OPTIONS = gql`
  query getExpectedRevenueChangeOptions {
    expectedrevenuereason {
      id
      name
    }
  }
`;

export default GET_EXP_REV_CHANGE_OPTIONS;
