import React from "react";
import { motion } from "framer-motion";

const ProfileMenu = ({ onAboutClick, onSignOutClick, setOpenProfileMenu }) => {
  const menuItems = [
    {
      name: "About",
      onClick: onAboutClick,
    },
    {
      name: "Sign out",
      divide: true,
      onClick: onSignOutClick,
    },
  ];

  const onOutsideBarClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setOpenProfileMenu(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        ease: "linear",
      }}
      className="absolute h-full w-screen top-0 left-0 z-[100]"
      onClick={(e) => {
        onOutsideBarClick(e);
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: "linear",
        }}
        className="absolute bg-[#171720] z-[999] text-white rounded-md right-6 lg:right-8 top-[4.2rem]"
      >
        {menuItems.map((menuItem) => (
          <button
            className="block p-4 hover:bg-white/10 w-full"
            key={menuItem.name}
            onClick={() => {
              menuItem.onClick();
              setOpenProfileMenu(false);
            }}
          >
            {menuItem.name}
          </button>
        ))}
      </motion.div>
    </motion.div>
  );
};
export default ProfileMenu;
