import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import olyxbase from "../../services/olyxbase";
import { useParams } from "react-router-dom";
import ProductHistoryChart from "./ProductHistoryChart";
import { useQuery } from "react-query";
import { Avatar } from "@material-ui/core";
import Moment from "react-moment";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

function ProductHistoryPage(props) {
  let historyRouter = useHistory();

  const [days, setDays] = useState(30);
  const [avatars, setAvatars] = useState([]);
  const [comments, setComments] = useState([]);

  let { productId } = useParams();

  let history = useQuery(["productHistory", productId, days], async () => {
    return olyxbase.getProductHistoryLong(productId, days);
  });

  useEffect(() => {
    if (history.data) {
      const uniqueAvatars = Array.from(new Set(history.data.map((item) => item.imageUrl)));
      setAvatars(uniqueAvatars);

      const commentist = history.data.filter((item) => item.extraInfo != "" && item.extraInfo != null && item.extraInfo != undefined).reverse();
      setComments(commentist);
    }
  }, [history.data]);

  useEffect(() => {}, [days]);

  return (
    <Grid container style={{ backgroundColor: "white" }}>
      <Grid item xs={12}>
        <div style={{ margin: 15 }}>
          <Button variant="contained" color={"blue"} style={{ marginRight: 10 }} onClick={() => historyRouter.push("/dashboard")}>
            Back To Dashboard
          </Button>

          <Button variant="contained" color={days == 1 ? "primary" : "info"} style={{ marginRight: 10 }} onClick={() => setDays(1)}>
            1D
          </Button>
          <Button variant="contained" color={days == 7 ? "primary" : "info"} style={{ marginRight: 10 }} onClick={() => setDays(7)}>
            1W
          </Button>
          <Button variant="contained" color={days == 30 ? "primary" : "info"} style={{ marginRight: 10 }} onClick={() => setDays(30)}>
            1M
          </Button>
          <Button variant="contained" color={days == 365 ? "primary" : "info"} style={{ marginRight: 10 }} onClick={() => setDays(365)}>
            1Y
          </Button>
        </div>
      </Grid>
      <Grid item md={9}>
        {history.isSuccess && history.data && history.data.length > 1 && <ProductHistoryChart data={history.data} />}
      </Grid>
      <Grid item md={3}>
        <div>
          <h2 style={{ color: "black", fontFamily: "Gilroy SemiBold" }}>Broker</h2>
          {avatars.map((avatar, index) => (
            <Avatar key={index} src={avatar} style={{ margin: 10, float: "left", width: 70, height: 70 }} />
          ))}
        </div>
        <div style={{ clear: "both" }}></div>
        <div>
          <h2 style={{ color: "black", fontFamily: "Gilroy SemiBold" }}>Comments</h2>
          <div style={{ overflowY: "scroll", height: 500, marginBottom: 20, overflowX: "hidden" }}>
            {comments.map((comment, index) => (
              <Card key={index} style={{ backgroundColor: "#eee", margin: 10, marginRight: 10, marginTop: 10, marginLeft: 0 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Avatar src={comment.imageUrl} style={{ margin: 10, float: "left", width: 70, height: 70 }} />
                  <div style={{ marginLeft: 10 }}>
                    <p style={{ color: "black", fontFamily: "Gilroy SemiBold", fontSize: 12 }}>{comment.extraInfo}</p>
                    <p style={{ color: "black", fontFamily: "Gilroy SemiBold", fontSize: 10 }}>
                      <Moment fromNow>{comment.timestamp}</Moment>
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ProductHistoryPage;
