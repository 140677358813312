import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import FormGenerator from "../FormGenerator/FormGenerator";
import useDealNotesOverview from "../../typedHooks/queries/queryDealNotesOverview";
import useUsers from "../../hooks/useUsers";
import CommentsColumn from "./CommentsColumn";
import CheckboxFilter from "../../interfaces/CheckboxFilter";
import { DealNote } from "../../codegen/gql/graphql";
import parsedStringToBoolean from "../../services/helperFunctions/parsedStringToBoolean";
import parsedStringToNumber from "../../services/helperFunctions/parsedStringToNumber";
import { QueryDealNotesOverviewInput } from "../../codegen/gql/graphql";
import { FormField } from "../../interfaces/FormGeneratorProps";

const OverviewComments = () => {
  let localUser = JSON.parse(localStorage.getItem("user")!).id ?? 0;

  // cant type user since users is still plain js
  const { users } = useUsers() as any;
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search) as {
    mentioned: string;
    others: string;
    sent: string;
    userView: string;
  };

  const [userView, setUserView] = useState<number>(parsedStringToNumber(queryParams.userView, localUser));
  const [mentioned, setMentioned] = useState<boolean>(parsedStringToBoolean(queryParams.mentioned, true));
  const [sent, setSent] = useState<boolean>(parsedStringToBoolean(queryParams.sent, false));
  const [others, setOthers] = useState<boolean>(parsedStringToBoolean(queryParams.others, false));

  const checkBoxOptions: CheckboxFilter[] = [
    { label: "mentioned", value: "mentioned", checked: mentioned },
    { label: "sent", value: "sent", checked: sent },
    { label: "others", value: "others", checked: others },
  ];

  const newQueries: { userView: number; mentioned: boolean; sent: boolean; others: boolean } = {
    userView: userView,
    mentioned: mentioned,
    sent: sent,
    others: others,
  };

  // Somehow needed to put this line of code into a useEffect otherwise it will infinite loop
  useEffect(() => {
    history.replace({ search: queryString.stringify(newQueries) });
    refetch();
  }, [userView, mentioned, sent, others]);

  const queryDealnotesOverviewInput: QueryDealNotesOverviewInput = {
    userIds: [userView],
    isMentioned: mentioned,
    hasSent: sent,
    includeNoMention: others,
  };

  const { dataFromQuery: dealNotesOverviewData, refetch } = useDealNotesOverview(queryDealnotesOverviewInput);

  const formFields: { [key: string]: FormField } = {
    userFields: {
      type: "dropdown",
      initialValue: String(userView),
      label: "User view",
      order: "order-1",
      onChange: (userId: number) => {
        setUserView(userId ? Number(userId) : 0);
      },
      // cant type user since users is still plain js
      options: users ? users.users.map((user: any) => ({ id: String(user.id), name: user.fullName })) : [],
    },
    TodoFilter: {
      type: "checkboxes",
      checkboxInitialValue: checkBoxOptions,
      label: "To do",
      order: "order-2",
      onChange: (value: string) => {
        if (value === "mentioned") {
          setMentioned(!mentioned);
        } else if (value === "sent") {
          setSent(!sent);
        } else if (value === "others") {
          setOthers(!others);
        }
      },
      // to match options interface
      checkboxOptions: checkBoxOptions,
    },
  };

  return (
    <>
      {users && "users" in users && users.users.length > 0 && (
        <FormGenerator
          onSubmitFromParent={(e) => {
            e.preventDefault();
          }}
          layoutOptions={{
            hasLabelsOnTop: true,
            hasCloseButton: false,
          }}
          customFormStyles={{
            parent: "h-full  w-full rounded-xl p-[20px] pt-[32px] border-box flex flex-wrap gap-x-[1%] bg-whitejustify-start",
            children: "w-full py-[10px] pt-[24px] md:p-[0px] md:w-[33%] md:pt-[0px] lg:w-[25%]",
          }}
          formFields={[...Object.values(formFields)]}
        ></FormGenerator>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8 w-full ">
        <>
          <CommentsColumn
            title="To do"
            emptyState="No comments in to do"
            dealNotes={dealNotesOverviewData?.dealnotesoverview?.dealNotesTodo ? (dealNotesOverviewData?.dealnotesoverview?.dealNotesTodo as DealNote[]) : []}
          ></CommentsColumn>
          <CommentsColumn
            title="In progress"
            emptyState="No comments in progress"
            dealNotes={
              dealNotesOverviewData?.dealnotesoverview?.dealNotesInProgress ? (dealNotesOverviewData?.dealnotesoverview?.dealNotesInProgress as DealNote[]) : []
            }
          ></CommentsColumn>
          <CommentsColumn
            title="Done"
            emptyState="No comments done"
            dealNotes={dealNotesOverviewData?.dealnotesoverview?.dealNotesDone ? (dealNotesOverviewData?.dealnotesoverview?.dealNotesDone as DealNote[]) : []}
          ></CommentsColumn>
        </>
      </div>
    </>
  );
};
export default OverviewComments;
