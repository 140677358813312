import { gql } from "@apollo/client";

const GET_DEALSTATISTICS = gql`
  query DealStatistics($input: QueryDealsInput) {
    dealstatistics(input: $input) {
      after
      before
      brokers {
        id
        brokerName
        userInfo {
          imageUrl
        }
      }
      commissionTotalEur
      clientStatistics {
        client {
          id
          companyName
        }
        commissionInEur
      }
      dealCount
      distinctBrokerCount
      distinctClientCount
      distinctProductCount
      totalVolumePerUnit {
        product {
          name
          id
        }
        volumePerUnit {
          unit {
            descriptionShort
            description
          }
          totalVolume
        }
      }
    }
  }
`;

export default GET_DEALSTATISTICS;
