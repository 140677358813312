import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";

interface QueryResponse<T> {
  dataFromQuery: T | undefined;
}

function useTypedQuery<T, U>(query: string, input: U | undefined = undefined): QueryResponse<T> {
  const [dataFromQuery, setDataFromQuery] = useState<T>();

  const { loading, error, data } = useQuery<T>(gql(query), {
    variables: {
      input: input,
    },
  });

  useEffect(() => {
    if (loading) return;
    if (error) {
      //TODO: Log4Js (error)
      console.log(error);
      return;
    }
    setDataFromQuery(data);
  }, [loading, error, data]);

  return { dataFromQuery };
}

export default useTypedQuery;
