import React from "react";
import helpers from "../../services/helpers";
import Moment from "react-moment";
import "moment-timezone";
import isSameDay from "date-fns/isSameDay";
import PaymentStatusIcon from "../PaymentStatusIcon/PaymentStatusIcon";

function ExpRevInvoiceTable({ expRev, currentUserIsAdmin }) {
  return (
    <>
      {expRev.invoices.length > 0 && (
        <div className={`inline-grid text-xs grid-cols-9 gap-2 mt-4 w-full `}>
          <p className="col-span-1 text-[#424242] text-xs justify-self-center"></p>
          <p className="col-span-2 text-[#424242] text-xs justify-self-center">Amount</p>
          <p className="col-span-2 text-[#424242] text-xs justify-self-center">Order date</p>
          <p className="col-span-2 text-[#424242] text-xs justify-self-center">Invoice nr </p>
          <p className="col-span-2 text-[#424242] text-xs justify-self-center">Order nr </p>
          {"invoices" in expRev &&
            [...expRev.invoices]
              .sort((a, b) => new Date(a.dateOrder) - new Date(b.dateOrder))
              .sort((a, b) =>
                isSameDay(new Date(a.dateOrder), new Date(b.dateOrder)) && a.invoiceNumber && b.invoiceNumber ? a.invoiceNumber > b.invoiceNumber : false
              )
              .map((invoice, index) => (
                <div
                  key={invoice && invoice.invoiceNumber ? invoice.invoiceNumber : index}
                  className={`${
                    invoice.isDeleted ? "bg-transparent " : "bg-white  "
                  }relative border-gray-200 text-[#7E8085] grid border justify-items-center w-full grid-cols-9 gap-2 col-span-9 rounded-xl py-1`}
                >
                  <div className={`absolute ${invoice.isDeleted ? "w-[97%] h-[2px] bg-[#7E8085] top-1/2 transform  -translate-y-1/2" : "hidden"}`}></div>
                  <div className="col-span-1">
                    <PaymentStatusIcon size={"small"} invoiceStatusSummary={invoice.invoiceStatusSummary}></PaymentStatusIcon>
                  </div>
                  <p className="col-span-2">
                    {helpers.formatMoney(
                      invoice && invoice.allocatedToParentExpectedRevenue ? invoice.allocatedToParentExpectedRevenue / 100 : "0",
                      invoice && invoice.currency && invoice.currency.description ? invoice.currency.description : ""
                    )}
                  </p>
                  <p className="col-span-2">{invoice && invoice.dateOrder ? <Moment format="D MMM YYYY">{new Date(invoice.dateOrder)}</Moment> : "-"}</p>
                  <p className="col-span-2">{invoice && invoice.invoiceNumber ? invoice.invoiceNumber : "-"}</p>
                  <p className="col-span-2">{invoice && invoice.orderNumber ? invoice.orderNumber : "-"}</p>
                </div>
              ))}
        </div>
      )}
    </>
  );
}

export default ExpRevInvoiceTable;
