import DeleteIcon from "@material-ui/icons/Delete";
import { motion } from "framer-motion";
import { useState } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import useMutationMarkInvoicesAsDeleted from "../../hooks/useMutationMarkInvoicesAsDeleted";
import helpers from "../../services/helpers";
import SortedPaginatedExpRevWithInv from "../SortedPaginatedExpRevWithInv";
import MatchExpRevInvForm from "../MatchExpRevInvForm/MatchExpRevInvForm";

const SingleInvoice = (props) => {
  const [isMatchFormOpen, setIsMatchFormOpen] = useState(false);
  const { markInvoicesAsDeleted } = useMutationMarkInvoicesAsDeleted(props && props.data && props.data.id ? { invoiceId: props.data.id } : undefined);

  const markThisInvoiceAsDeleted = () => {
    if (props.data.isDeleted) {
      toast.warn("Invoice is already deleted.");
    } else {
      markInvoicesAsDeleted();
      toast.success(`Invoice ${props.data && props.data.orderNumber ? `with Ordernumber ${props.data.orderNumber}` : ""} deleted.`);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.2,
            duration: 0.1,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.1,
          },
        }}
        className=" p-4"
      >
        <div className="p-4">
          <div className="rounded-2xl bg-white p-8 w-full xl:w-6/12 mb-4">
            {props && props.data && (
              <>
                <h2 className="text-xl my-2 deal-card">Invoice {props.data.id}</h2>
                <table className="my-5 border-collapse">
                  <tbody>
                    <tr>
                      <td>
                        <p className="label-text">OrderNumber</p>
                      </td>
                      <td className="w-40">
                        {" "}
                        <p className="text">{props.data.orderNumber ?? ""} </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">InvoiceNumber</p>
                      </td>
                      <td className="w-40">
                        {" "}
                        <p className="text">{props.data.invoiceNumber ?? ""} </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Client</p>
                      </td>
                      <td className="w-20">
                        <p className="text">{props.data.client && props.data.client.companyName ? props.data.client.companyName : ""}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Invoice Description</p>
                      </td>
                      <td className="w-20">
                        <p className="text">{props.data.description ?? ""}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">&nbsp;</p>
                      </td>
                      <td className="w-20">
                        <p className="text"></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Invoice Date </p>
                      </td>
                      <td>
                        {props.data.dateInvoice && (
                          <Moment className="text" format="D MMM YYYY">
                            {new Date(props.data.dateInvoice)}
                          </Moment>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className="label-text">Amount</p>
                      </td>
                      <td className="w-20">
                        <p className="text">{props.data.totalAmountFormatted}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Amount in Euro</p>
                      </td>
                      <td className="w-20">
                        <p className="text">{props.data.totalAmountEuroFormatted ?? "-"}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Allocation Status</p>
                      </td>
                      <td className="w-20">
                        <p className="text">{props.data.allocationStatus ?? ""}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">AllocatedToAllExpRev</p>
                      </td>
                      <td className="w-20">
                        <p className="text">
                          {props.data.allocatedToAllExpectedRevenue
                            ? helpers.formatMoney(
                                props.data.allocatedToAllExpectedRevenue ? props.data.allocatedToAllExpectedRevenue / 100 : "-",
                                props.data.currency.description
                              )
                            : ""}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">&nbsp;</p>
                      </td>
                      <td className="w-20">
                        <p className="text"></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Type</p>
                      </td>
                      <td className="w-20">
                        <p className="text">{props.data.type ?? ""}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Invoice Status</p>
                      </td>
                      <td>
                        <p className="text">{props.data.invoiceStatus ? props.data.invoiceStatus.name : ""}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label-text">Invoice is Deleted?</p>
                      </td>
                      <td>
                        <p className="text">{"isDeleted" in props.data && props.data.isDeleted ? "True, inv is deleted" : "False, inv is not deleted"}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button class="bg-primary-blue rounded-lg p-2 text-white hover:bg-dark-blue" onClick={markThisInvoiceAsDeleted}>
                  <DeleteIcon />
                  Mark Invoice As Deleted
                </button>
              </>
            )}
          </div>
          <h2 className="text-2xl deal-card">Expected Revenues</h2>
          <p className="mb-4">Invoice is matched to the following expRevs</p>
          <div className="w-1/3">
            <SortedPaginatedExpRevWithInv expRevAgainstInv={props.data} currentUserIsAdmin={true} enableChangesOnExpRev={false}></SortedPaginatedExpRevWithInv>
          </div>
          <div>
            {isMatchFormOpen && !isNaN(parseInt(props?.data?.id)) ? (
              <MatchExpRevInvForm invoiceId={parseInt(props?.data?.id)} closeFormFunction={() => setIsMatchFormOpen(false)} />
            ) : (
              <>
                <button className="text-xs" onClick={() => setIsMatchFormOpen(true)}>
                  + match expRev to invoice
                </button>
              </>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SingleInvoice;
