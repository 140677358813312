import { gql } from "@apollo/client";

const MUTATION_DEALNOTES = gql`
  mutation DealNotes($input: [DealNoteInput]) {
    dealnotes(input: $input) {
      id
      note
      mentions {
        userInfo {
          id
        }
      }
    }
  }
`;

export default MUTATION_DEALNOTES;
