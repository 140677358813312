import React from "react";
// import styles from './InvoiceActions.css';
import Button from "@material-ui/core/Button";

function InvoiceActions(props) {
  const invoicesAll = props.invoices.map((invoice) => {
    if (!invoice.invoiceSent && !invoice.invoicePaid) {
      return (
        <Button size="small" color="primary">
          {`Confirm invoice ${invoice.client.companyName}`}
        </Button>
      );
    }

    if (!invoice.invoiceSent && !invoice.invoicePaid && false) {
      return (
        <Button size="small" color="primary">
          {`Send invoice ${invoice.client.companyName}`}
        </Button>
      );
    }
    return <div />;
  });
  return (
    <>
      <div>
        {invoicesAll.map((button) => (
          <div>{button}</div>
        ))}
      </div>
    </>
  );
}

export default InvoiceActions;
