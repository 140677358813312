import React, { useState, useEffect } from "react";
import useMissingDealNumbers from "../../hooks/useMissingDealNumbers";
import Switch from "@material-ui/core/Switch";

function MissingDealNumbers() {
  const [missingDealNumbers, refetch, networkStatus] = useMissingDealNumbers();
  const [firstTen, setFirstTen] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (missingDealNumbers) {
      setFirstTen(missingDealNumbers.slice(-10));
    }
  }, [missingDealNumbers]);

  return (
    <>
      <div class="p-4">
        <div>Missing Deal Numbers</div>
        <div>
          <p>{"Show first 10 / Show all"}</p>
          <Switch checked={showAll} onChange={() => setShowAll(!showAll)} name="showAll" />
        </div>
        {!showAll && firstTen && <div>{[...firstTen.sort((a, b) => b - a).reduce((acc, mdn) => acc + mdn.toString() + ", ", "")]}</div>}
        {showAll && missingDealNumbers && <div>{[...missingDealNumbers.sort((a, b) => b - a).reduce((acc, mdn) => acc + mdn.toString() + ", ", "")]}</div>}
      </div>
    </>
  );
}

export default MissingDealNumbers;
