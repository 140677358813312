import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_PAYMENTPERFORMANCEINEUR from "./query_paymentperformanceineur";

function usePaymentPerformanceInEur(args) {
  const [paymentPerformanceInEur, setPaymentPerformanceInEur] = useState(false);

  const dealSearch =
    args && args.dealSearch
      ? {
          brokerIds: args.dealSearch.brokerIds ? args.dealSearch.brokerIds : undefined,
          clientIds: args.dealSearch.clientIds ? args.dealSearch.clientIds : undefined,
          productIds: args.dealSearch.productIds ? args.dealSearch.productIds : undefined,
          olyxRefs: args.dealSearch.olyxRefs ? args.dealSearch.olyxRefs : undefined,
          after: args.dealSearch.after ? args.dealSearch.after : undefined,
          before: args.dealSearch.before ? args.dealSearch.before : undefined,
          dealInvoiceStatus: args.dealSearch.dealInvoiceStatus ? args.dealSearch.dealInvoiceStatus : undefined,
          hasOpenDealNote: args.dealSearch.hasOpenDealNote ? args.dealSearch.hasOpenDealNote : false,
        }
      : undefined;

  const input = {
    clientIds: args && args.clientIds ? args.clientIds : undefined,
    brokerIds: args && args.brokerIds ? args.brokerIds : undefined,
    expectedRevenueIds: args && args.expectedRevenueIds ? args.expectedRevenueIds : undefined,
    dealSearch: dealSearch,
  };

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_PAYMENTPERFORMANCEINEUR, {
    // notifyOnNetworkStatusChange: false,
    // nextFetchPolicy: "standby",
    // fetchPolicy: "network-only",
    variables: {
      input: input,
    },
  });

  useEffect(() => {
    setPaymentPerformanceInEur(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        return false;
      } else if (data && data.paymentperformanceineur) {
        return data.paymentperformanceineur;
      } else {
        return false;
      }
    });
  }, [loading, error, data, refetch]);

  return { paymentPerformanceInEur, networkStatus };
}

export default usePaymentPerformanceInEur;
