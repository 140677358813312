import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Avatar from "../Avatar/Avatar";

const AvatarTooltip = (props) => {
  const [isHover, setHover] = useState(false);

  return (
    <div
      className="relative w-full h-full"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Avatar
        alt={props.broker ? props.broker : ""}
        src={props.img ? props.img : ""}
        className="block w-full h-full overflow-hidden rounded-full bg-gray-400"
      />

      <AnimatePresence>
        {isHover && props.hover && (
          <motion.div
            className="whitespace-nowrap left-0 absolute z-[100] p-2 text-center text-white transform bg-gray-800 rounded-lg -bottom-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {props.broker}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AvatarTooltip;
