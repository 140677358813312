import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import useMarkDealConfirmationAsConfirmed from "../../typedHooks/mutations/useMarkDealConfirmationAsConfirmed";

function DealConfirmationForm({ confirmationId }: { confirmationId: string }) {
  const [userHasManuallyConfirmed, setUserHasManuallyConfirmed] = useState(false);
  const markDealConfirmationAsConfirmed = useMarkDealConfirmationAsConfirmed();

  return (
    <div className="w-full flex flex-wrap p-2 gap-2 bg-white">
      <AnimatePresence>
        <motion.div className="w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
          <form className="relative w-full" onSubmit={(e) => e.preventDefault}>
            <label htmlFor={`confirmationCheckbox-${confirmationId}`} className="cursor-pointer flex text-[#a6a6a6] text-xs items-start gap-2 font-smallText">
              <input
                id={`confirmationCheckbox-${confirmationId}`}
                type="checkbox"
                className="cursor-pointer form-checkbox mt-[1px] opacity-0"
                onChange={() => setUserHasManuallyConfirmed(!userHasManuallyConfirmed)}
              />
              I hereby confirm that the client has confirmed the deal in writing via email in response to the Deal Confirmation email I sent.
            </label>
            <div className="absolute pointer-events-none border-[1px] border-[#d5d5d5] top-[1px] w-[12px] h-[12px] rounded-[2px] flex items-center justify-center">
              {userHasManuallyConfirmed && <div className="bg-[#22b0ff] w-[8px] h-[8px] rounded-[2px]" />}
            </div>
            <input
              className="w-fit rounded-xl mt-2 p-2 py-1 text-sm block ml-auto text-white bg-primary-blue hover:bg-[#24abf6] disabled:bg-[#9ca3af] cursor-pointer"
              name="submit"
              type="submit"
              value="Submit"
              onClick={(e) => {
                e.preventDefault();
                markDealConfirmationAsConfirmed({ variables: { input: { dealConfirmationId: parseInt(confirmationId, 10) } } });
              }}
              disabled={!userHasManuallyConfirmed}
            />
          </form>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default DealConfirmationForm;
