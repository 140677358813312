import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogContent, Typography, Tooltip, IconButton, List, ListItem, ListItemText, Button } from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

const AboutDialog = ({ dialogProps, user }) => {
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    if (user) {
      user.getIdToken(false).then(function(idToken) {
        setUserToken(idToken);
      });
    }
  }, []);

  return (
    <Dialog fullWidth maxWidth="xs" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6">About</Typography>

        <Tooltip title="Close">
          <IconButton onClick={dialogProps.onClose} style={{ position: "absolute", top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Version" secondary={process.env.REACT_APP_VERSION} />
          </ListItem>

          {user && (
            <ListItem>
              <ListItemText primary="UID" secondary={user.uid} />
            </ListItem>
          )}

          {user && (
            <ListItem>
              <Button onClick={() => navigator.clipboard.writeText(userToken)}>Copy Auth Token</Button>
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

AboutDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AboutDialog;
