import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_DEAL_OPTIONS from "./query_dealoptions";

function useDealOptions() {
  const [dealOptions, setDealOptions] = useState(null);

  const { loading, error, data, refetch } = useQuery(GET_DEAL_OPTIONS, { notifyOnNetworkStatusChange: false, nextFetchPolicy: "standby" });

  useEffect(() => {
    if (loading && (!data || data.length === 0)) {
      return;
    } else if (error) {
      console.error("Error getting dealOptions");
      return;
    } else {
      setDealOptions(data);
    }
  }, [loading, error, data, refetch]);

  return [dealOptions];
}

export default useDealOptions;
