import { forwardRef, useImperativeHandle, useRef } from "react";
import DropDownListPropsInterface from "../../../interfaces/DropDownListPropsInterface";
import React from "react";

const DropDownList = forwardRef((props: DropDownListPropsInterface, ref) => {
  const liRef = useRef<HTMLLIElement>(null);
  useImperativeHandle(ref, () => ({
    scroll() {},
  }));

  return (
    <div
      className={`absolute z-3000 w-full max-h-80 bg-white pb-1 rounded-xl shadow-lg overflow-auto max-md:text-xs md:text-base border-gray-200 ${props.dropDownStyle} ${props.additionalStyle} overflow-x-hidden`}
    >
      <ul className="rounded-xl" role="listbox" tabIndex={-1} aria-multiselectable={false}>
        {props.options
          //if element is selected there is no filter
          .filter((el) => {
            if (props.elemId !== "0") return true;
            return el.name?.toLocaleLowerCase().includes(props.textField);
          })
          .map((e, idx) => {
            return (
              <li
                key={`${e.name}-${idx}`}
                onClick={() => props.selectElement(e)}
                ref={idx === props.selectedIndex ? liRef : null}
                className={`p-3 py-2 border-solid border-b border-gray-darker hover:bg-gray-100 cursor-pointer ${
                  props.selectedIndex === idx ? "bg-light-blue-filters" : "bg-transparent"
                }`}
                value={e.name}
              >
                {e.name}
              </li>
            );
          })}
      </ul>
    </div>
  );
});

export default DropDownList;
