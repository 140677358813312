import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { sortedDataContext } from "../../contexts/TableContexts";
import helpers from "../../services/helpers";
import FilterExpRevStatus from "../../components/FilterExpRevStatus/FilterExpRevStatus";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AnimatePresence } from "framer-motion";

const TableSort = ({ data, currentUserIsAdmin, columns, ...props }) => {
  // set up which column is being sorted and what direction
  const defaultSortConfig = { key: "dateExpected", direction: "ascending" };
  const [sortConfig, setSortConfig] = useState(defaultSortConfig);
  const [sortedData, setSortedData] = useState(data);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);

  // sorting based on ID when adding new data
  useEffect(() => {
    const dataToSort =
      categoryFilters === "all" ? data : data.filter((item) => item && item.invoiceStatusSummary && item.invoiceStatusSummary === categoryFilters);
    setSortConfig(defaultSortConfig);
    setSortedData(helpers.sortOnDate(defaultSortConfig, dataToSort));
  }, [data, categoryFilters]);

  return (
    <div className="relative">
      <AnimatePresence>
        {isFilterOpen && (
          <FilterExpRevStatus
            data={data}
            setCurrentPage={setCurrentPage}
            categoryFilters={categoryFilters}
            setCategoryFilters={setCategoryFilters}
          ></FilterExpRevStatus>
        )}
      </AnimatePresence>
      <div className={`inline-grid ${currentUserIsAdmin ? "grid-cols-7" : "grid-cols-6"}  gap-1 mt-4 w-full `}>
        {columns.map((column) => (
          <div
            key={column.name}
            className={` ${column.columnSpan === 1 ? "col-span-1" : "col-span-2"} text-[#424242] text-xs flex`}
            onClick={() => {
              if (column.name !== "Status") {
                setSortConfig({ key: column.name, direction: sortConfig.direction === "ascending" ? "descending" : "ascending" });
                setSortedData(
                  column.sortingFunction({ key: column.name, direction: sortConfig.direction === "ascending" ? "descending" : "ascending" }, sortedData)
                );
              }
              if (column.name === "Status") {
                setFilterOpen(!isFilterOpen);
              }
            }}
          >
            <button className="text-left relative w-full flex flex-nowrap items-center">
              <>
                {column.name === "Status" && (
                  <div className="w-4 -top-0.5">
                    <FilterAltIcon
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    ></FilterAltIcon>
                  </div>
                )}
                {column.label}
                <div className="relative">
                  {sortConfig && sortConfig.key === column.name && sortConfig.direction === "ascending" && <KeyboardArrowUpIcon></KeyboardArrowUpIcon>}
                  {sortConfig && sortConfig.key === column.name && sortConfig.direction === "descending" && <KeyboardArrowDownIcon></KeyboardArrowDownIcon>}
                </div>
              </>
            </button>
          </div>
        ))}
      </div>

      <sortedDataContext.Provider value={{ sortedData: sortedData, currentPage: currentPage, setCurrentPage: setCurrentPage }}>
        {props.children}
      </sortedDataContext.Provider>
    </div>
  );
};

export default TableSort;
