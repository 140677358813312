import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CssTextField from "../FormFields/CssTextField";

function TextInput(props) {
  return (
    <>
      <CssTextField
        disableToolbar
        variant="outlined"
        fullWidth
        id={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        // style={{ maxWidth: 300 }}
        InputLabelProps={{ style: { color: "#22b0ff" } }}
        inputProps={{ style: { color: "#22b0ff" } }}
      />
    </>
  );
}

export default TextInput;
