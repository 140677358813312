import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import BrokerHenry from "../../assets/broker-henry.png";

const styles = (theme) => ({
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
});

const isHenryDisplayed = Math.random() <= 0.02 ? true : false;

class LaunchScreen extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    return (
      <div id="loader_wrapper" className="absolute top-0 left-0 w-screen h-screen z-[9999]">
        <div className={classes.center}>
          {isHenryDisplayed ? <img className="animate-spin w-20 h-20" src={BrokerHenry} alt="BrokerHenry" /> : <CircularProgress />}
        </div>
      </div>
    );
  }
}

LaunchScreen.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LaunchScreen);
