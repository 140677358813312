import React from "react";
import helpers from "../../services/helpers";
import { motion } from "framer-motion";

interface IcicleLegendTooltip {
  data: any;
}

const IcicleLegendTooltip = ({ data }: IcicleLegendTooltip) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className=" grid gap-2 px-4 py-4 rounded-xl text-xs absolute top-[3.5em] left-0  bg-black/80 text-white w-56 z-50"
    >
      {data.children.map((child: any) => {
        if (child.data.value > 0)
          return (
            <div key={child.data.name} className="flex items-center gap-2">
              <span>{child.data.name}</span>
              <span>{helpers.formatMoneyKMB(child.data.value / 100, "EUR")} </span>
            </div>
          );
      })}
    </motion.div>
  );
};

export default IcicleLegendTooltip;
