/* eslint-disable react/no-danger, react-hooks/exhaustive-deps */
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Moment from "react-moment";
import PriceIcon from "@material-ui/icons/LocalOffer";

import olyxbase from "../../services/olyxbase";

const useStyles = makeStyles((theme) => ({
  paper: {
    transformOrigin: "top right",
  },
  list: {
    width: theme.spacing(40),
    maxHeight: theme.spacing(40),
    overflow: "auto",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export default function PriceChangeNotificationDropdown() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [historiesList, setHistoriesList] = React.useState([]);
  const [newCount, setNewCount] = React.useState(0);
  const [lastId, setLastId] = React.useState(-1);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  let lastIdVar = -1;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTooltipOpen(false);

    let maxId = 0;
    for (let hl = 0; hl < historiesList.length; hl++) {
      {
        let character = historiesList[hl];
        if (character.id > maxId) {
          maxId = character.id;
        }
      }
    }

    setNewCount(0);
    lastIdVar = maxId;
    olyxbase.updateLastSeenPriceChangeId(lastIdVar);
  };

  React.useEffect(() => {
    let active = true;
    const timeout = setInterval(() => {
      getNotifications();
    }, 10000);

    getNotifications();

    return () => {
      clearInterval(timeout);
      active = false;
    };
  }, []);

  const getNotifications = () => {
    let histories = [];

    olyxbase.getNotificationCounts().then((counts) => {
      if (counts) {
        lastIdVar = counts.lastSeenPriceChangeId;

        return olyxbase.getHistory().then((hist) => {
          if (hist !== undefined) {
            for (var hi = 0; hi < hist.length; hi++) {
              let currHist = hist[hi];

              let direction = "same";
              let icon = "star";
              if (currHist.new_price > currHist.old_price) {
                direction = "up";
                icon = "up";
              }
              if (currHist.new_price < currHist.old_price) {
                direction = "down";
                icon = "down";
              }

              let txtS = '<span style="color: #f2c80f; font-size: 18px">' + +currHist.new_price.toFixed(2) + "</span> ";

              if (currHist.new_price - currHist.old_price > 0) {
                txtS += '<span style="color: black; background-color: #c5f9c3; padding-left: 5px; padding-right: 5px;">+';
                txtS +=
                  +(currHist.new_price - currHist.old_price).toFixed(2) +
                  " ▲ " +
                  +(((currHist.new_price - currHist.old_price) / currHist.old_price) * 100).toFixed(2) +
                  "%</span>";
              }

              if (currHist.new_price - currHist.old_price < 0) {
                txtS += '<span style="color: black; background-color: #e7adad; padding-left: 5px; padding-right: 5px">';
                txtS +=
                  +(currHist.new_price - currHist.old_price).toFixed(2) +
                  " ▼ " +
                  +(((currHist.new_price - currHist.old_price) / currHist.old_price) * 100).toFixed(2) +
                  "%</span>";
              }

              if (currHist.new_price - currHist.old_price == 0) {
                txtS += '<span style="color: white; padding-left: 5px; padding-right: 5px">';
                txtS +=
                  +(currHist.new_price - currHist.old_price).toFixed(2) +
                  " - " +
                  +(((currHist.new_price - currHist.old_price) / currHist.old_price) * 100).toFixed(2) +
                  "%</span>";
              }

              // txtS += '<br /> <span style="color: #00ccff">' + currHist.fullName + '</span>';

              if (currHist.extraInfo) {
                txtS += "<br />" + currHist.extraInfo;
              }

              let thisHist = {
                id: currHist.id,
                title: currHist.product + ' <span style="font-size: 12px;">' + currHist.info + "</span>",
                text: txtS,
                timeago: currHist.timestamp,
                icon: icon,
                direction: direction,
                avatar: currHist.imageUrl,
              };

              histories.push(thisHist);
            }

            setHistoriesList(histories);

            let maxId = 0;

            // check (Without ordered) if we have a new ID in our list that we didnt know about
            histories.forEach((character) => {
              if (character.id > maxId) {
                maxId = character.id;
              }
            });

            if (maxId > lastIdVar && lastIdVar != -1) {
              // Calculate and set the new notificaiton count
              let newCount = histories.filter((a) => a.id > lastIdVar).length;
              setNewCount(newCount);
              //setLastId(maxId);
            } else {
              setNewCount(0);
            }
          }
        });
      } // if counts
    });
  };

  return (
    <React.Fragment>
      <Tooltip
        open={tooltipOpen}
        onOpen={() => {
          setTooltipOpen(!open);
        }}
        onClose={() => {
          setTooltipOpen(false);
        }}
        title={"Toggle Notifications"}
        enterDelay={300}
      >
        <IconButton
          color="inherit"
          ref={anchorRef}
          aria-controls={open ? "notifications-popup" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          data-ga-event-category="AppBar"
          data-ga-event-action="toggleNotifications"
        >
          <Badge hidden={newCount == 0} color="secondary" badgeContent={newCount}>
            <PriceIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper id="notifications-popup" anchorEl={anchorRef.current} open={open} placement="bottom-end" transition role={undefined} style={{ zIndex: 9999 }}>
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {historiesList ? (
                    historiesList.map((message, index) => (
                      <React.Fragment key={message.id}>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar src={`${message.avatar}`}>
                              {/* {message.icon == 'up' && <ArrowUpwardIcon color="primary" />}
                              {message.icon == 'same' && <NotificationsIcon />}
                              {message.icon == 'star' && <StarIcon color="action" />}
                              {message.icon == 'down' && <ArrowDownwardIcon color="secondary" />} */}
                            </Avatar>
                          </ListItemAvatar>
                          <div className={classes.listItem}>
                            <Typography dangerouslySetInnerHTML={{ __html: message.title }}></Typography>
                            <Typography variant="body2">
                              <span id="notification-message" dangerouslySetInnerHTML={{ __html: message.text }} />
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              <Moment fromNow>{message.timeago}</Moment>
                            </Typography>
                          </div>
                        </ListItem>
                        <Divider className={classes.divider} />
                      </React.Fragment>
                    ))
                  ) : (
                    <div className={classes.loading}>
                      <CircularProgress size={32} />
                    </div>
                  )}
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
}
