import React from "react";
// import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// import {auth} from "../../firebase.js";
// import { gql, useQuery  } from "@apollo/client";
// import DealsTableExpanding from "../../components/Table/DealsTableExpanding";
import Deals from "../../components/Deals/Deals";

function DealPage() {
  return (
    <div styles={styles.contentWrapper}>
      {/* <DealsTableExpanding/> */}
      <Deals />
    </div>
  );
}

const styles = {
  contentWrapper: {
    padding: 20,
  },
};

export default withRouter(DealPage);
