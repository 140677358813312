import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import LeftIcon from "@material-ui/icons/ArrowLeftRounded";
import RightIcon from "@material-ui/icons/ArrowRightRounded";
import HideIcon from "@material-ui/icons/Cancel";
import PriceChangePopup from "../PriceChangePopup/PriceChangePopup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LongPriceHistoryBox from "../LongPriceHistoryBox/LongPriceHistoryBox";
import CandleStickChart from "../../components/CandleStickChart";
import moment from "moment";
import "./dashboardcard.css";

function DashboardCard({ price, disableHoverListen, setDisableHoverListen, nudgePrice, config, editMode, productCandles, moveCard, openProductInfoModal }) {
  return (
    <>
      <Card key={price.productId} className={"dt-price-card d-flex flex-column "} raised={true}>
        <CardContent style={{ height: "100%" }}>
          {editMode && (
            <>
              <IconButton onClick={() => moveCard(price.productId, -1.5, price.order)} color={"secondary"}>
                <LeftIcon />
              </IconButton>
              <IconButton onClick={() => moveCard(price.productId, +999, price.order)} color={"secondary"}>
                <HideIcon />
              </IconButton>
              <IconButton onClick={() => moveCard(price.productId, +1.5, price.order)} color={"secondary"}>
                <RightIcon />
              </IconButton>
            </>
          )}
          <p className="dt-product-name" align="center">
            {price.productName}
          </p>
          <Typography className="dt-product-info" align="center">
            &nbsp; {price.productInfo}&nbsp;
          </Typography>
          {/* animate={{ { y: [30, -8, -50] }, opacity: [1, 0, 0, 1] }} */}
          <PriceChangePopup
            product={price}
            disableHoverListen={disableHoverListen}
            setDisableHoverListen={setDisableHoverListen}
            nudgePrice={nudgePrice}
            lastTimeStamp={price.priceUpdateTimestamp}
          />
          <div style={styles.calcLine}>&nbsp;{price.calcline}</div>

          <div style={{ backgroundColor: "#171720", height: "60%", marginLeft: -20, width: "120%", paddingTop: 20, marginBottom: 10 }}>
            {/* style={{height: 20}} dangerouslySetInnerHTML={{ __html: '<p>' + price.calcline + '&nbsp</p>'}}  */}

            {typeof productCandles != undefined && productCandles && productCandles.length > 0 && (
              <CandleStickChart initialData={productCandles} price={price.priceInt / 10 ** price.decimals} productId={price.productId} />
            )}

            <div className="align-self-end" style={styles.priceHistoryBox}>
              <LongPriceHistoryBox
                productId={price.productId}
                boxClassName={"initials"}
                initials={price.agentInitials}
                openProductInfoModal={openProductInfoModal}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

const styles = {
  priceHistoryBox: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    bottom: 15,
    border: "1px solid white",
    marginLeft: 25,
    borderRadius: 100,
    padding: 2,
    color: "white",
    zIndex: "7",
  },
  calcLine: {
    fontSize: 15,
    color: "#000",
    marginTop: -6,
    marginBottom: 5,
  },
  productInfo: {
    fontSize: 15,
    marginTop: -25,
  },
  product: {
    fontSize: 50,
    fontWeight: "bold",
  },
  chartContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 220,
  },
  selectControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 350,
    marginTop: 3,
    zIndex: 99,
  },
  entryBar: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

export default DashboardCard;
