import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

function InFormButton(props) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={3}>
      <Button type="button" variant="text" fullWidth style={{ maxWidth: 300 }} onClick={() => props.onClick()}>
        {props.children}
      </Button>
    </Grid>
  );
}

export default InFormButton;
