import "moment-timezone";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import usePaymentPerformanceInEur from "../../hooks/usePaymentPerformanceInEur";
import CommissionOverview from "../CommissionOverview";
import SortedPaginatedExpRevWithInv from "../SortedPaginatedExpRevWithInv";
import { Barplot } from "../PaymentPerformanceGraph/Barplot";

function ExpRevList({ deal, expRevAgainstInv, currentUserIsAdmin }) {
  const { paymentPerformanceInEur } = usePaymentPerformanceInEur({
    expectedRevenueIds: expRevAgainstInv ? expRevAgainstInv.expectedRevenue.map((expRev) => parseInt(expRev.id)) : undefined,
  });

  const tradeSide = expRevAgainstInv && expRevAgainstInv.tradeSide ? expRevAgainstInv.tradeSide : undefined;

  return (
    <>
      <div className="w-full p-4 pb-0 mb-4 bg-white lg:flex-1 rounded-2xl exprevlist">
        <div className="p-4 border border-gray-light rounded-xl">
          <div className="flex flex-wrap gap-4">
            <h3 className="my-2 text-xl">{tradeSide === "SELL" ? "Seller" : tradeSide === "BUY" ? "Buyer" : "-"}</h3>
            <div className="relative w-8 h-8">
              <AvatarTooltip
                img={
                  tradeSide === "SELL" && deal && deal.brokerSeller && deal.brokerSeller.userInfo && deal.brokerSeller.userInfo.imageUrl
                    ? deal.brokerSeller.userInfo.imageUrl
                    : tradeSide === "BUY" && deal && deal.brokerBuyer && deal.brokerBuyer.userInfo && deal.brokerBuyer.userInfo.imageUrl
                    ? deal.brokerBuyer.userInfo.imageUrl
                    : ""
                }
                broker={
                  tradeSide === "SELL" && deal && deal.brokerSeller && deal.brokerSeller.brokerName
                    ? deal.brokerSeller.brokerName
                    : tradeSide === "BUY" && deal && deal.brokerBuyer && deal.brokerBuyer.brokerName
                    ? deal.brokerBuyer.brokerName
                    : "Anonymous"
                }
                avatarSmall={true}
              />
            </div>
            <div className="flex items-center gap-2 px-2 py-1 mb-2  text-xs md:text-sm border border-gray-light rounded-xl">
              <span className="text-gray-dark">
                {expRevAgainstInv.clientOnDeal && expRevAgainstInv.clientOnDeal.companyName ? expRevAgainstInv.clientOnDeal.companyName : null}
              </span>
            </div>
          </div>
          <div className="mb-4">
            <Barplot location="dealDetails" data={paymentPerformanceInEur ?? null} />
          </div>
          <CommissionOverview
            deal={deal}
            expRevAgainstInv={expRevAgainstInv}
            currentUserIsAdmin={currentUserIsAdmin}
            tradeSide={tradeSide}
          ></CommissionOverview>
          <SortedPaginatedExpRevWithInv
            expRevAgainstInv={expRevAgainstInv}
            currentUserIsAdmin={currentUserIsAdmin}
            tradeSide={tradeSide}
            dealId={deal.id}
            clientId={expRevAgainstInv.clientOnDeal.id}
            enableChangesOnExpRev={true}
          ></SortedPaginatedExpRevWithInv>
        </div>
      </div>
    </>
  );
}

export default ExpRevList;
