import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_MISSINGDEALNUMBERS from "./query_missingdealnumbers";

function useMissingDealNumbers() {
  const [missingDealNumbers, setMissingDealNumbers] = useState(false);

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_MISSINGDEALNUMBERS, { notifyOnNetworkStatusChange: false, nextFetchPolicy: "standby" });

  useEffect(() => {
    setMissingDealNumbers(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting missindealnumbers");
        return false;
      } else if (data && "missingdealnumbers" in data) {
        return data.missingdealnumbers;
      } else {
        return false;
      }
    });
  }, [loading, error, data, refetch]);

  return [missingDealNumbers, refetch, networkStatus];
}

export default useMissingDealNumbers;
