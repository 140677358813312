import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxHeight: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    position: "relative",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  listItem: {
    backgroundColor: "inherit",
  },
  listItemText: {
    backgroundColor: "red",
  },
}));

function RenderRow(props) {
  // const { data, categoryId, classes} = props;
  const { data, categoryId } = props;

  const item = data ? data : undefined;
  const itemId = data ? parseInt(data.id) : 0;

  return (
    <ListItem key={`item-${categoryId}-${itemId}`}>
      <ListItemText primary={`${item && item.name ? item.name.toString() : ""}`} />
    </ListItem>
  );
}

RenderRow.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object,
  categoryId: PropTypes.number.isRequired,
  // style: PropTypes.object.isRequired,
};

export default function PinnedSubHeaderList({ data, categories }) {
  const classes = useStyles();

  // const dataArray = [{"name": "object1", "description": "hallo"}, {"name": "object2", "description": "gutentag"}];

  return (
    <List className={classes.root} subheader={<li />}>
      {categories.map((category) => (
        <li key={`section-${category.id}`} className={classes.listSecton}>
          <ul className={classes.ul}>
            <ListSubheader>{`${category.name}`}</ListSubheader>
            {data
              .filter((item) => item.category.id.toString() === category.id.toString())
              .map((item) => (
                <RenderRow
                  // item={item}
                  // classes={classes}
                  categoryId={parseInt(item.category.id)}
                  data={item}
                />
              ))}
          </ul>
        </li>
      ))}
    </List>
  );
}
