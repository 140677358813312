import { AnimatePresence } from "framer-motion";
import useComponentVisible from "../../typedHooks/useComponentVisible";

function TotalGeneric({
  countValue,
  wordSingular,
  wordPlural,
  showTooltip,
  notifyWhenAdditionalDataExists,
  additionalDataToDisplay,
  children,
}: {
  countValue: number;
  wordSingular: string;
  wordPlural: string;
  showTooltip: boolean;
  notifyWhenAdditionalDataExists: boolean;
  additionalDataToDisplay: any;
  children: any;
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <>
      {countValue ? (
        <div className="relative" ref={ref}>
          {notifyWhenAdditionalDataExists && additionalDataToDisplay.length > 0 && (
            <div className="absolute top-2 left-[6px] w-[4px] h-[4px] rounded-full bg-[#2196f3]"></div>
          )}
          <button
            onClick={() => {
              setIsComponentVisible(!isComponentVisible);
            }}
            className={`inline-block gap-2 border border-gray-light px-2 py-2 rounded-xl text-base bg-white  ${
              showTooltip && additionalDataToDisplay.length > 0 ? "group hover:bg-[#d8edf4] transition-all 	" : "pointer-events-none"
            }`}
          >
            <span className="text-gray-dark mr-2 group-hover:text-[#364e66] transition-all ">{countValue === 1 ? wordSingular : wordPlural}</span>
            <span className="group-hover:text-[#364e66] transition-all ">{countValue}</span>
          </button>
          <div>
            <AnimatePresence>{isComponentVisible && children}</AnimatePresence>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default TotalGeneric;
