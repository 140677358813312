import React from "react";
import helpers from "../../services/helpers";
import "moment-timezone";

function CommissionOverview({ deal, expRevAgainstInv, currentUserIsAdmin, tradeSide }) {
  const updatedCommission =
    expRevAgainstInv && expRevAgainstInv.expectedRevenue && Array.isArray(expRevAgainstInv.expectedRevenue) && expRevAgainstInv.tradeSide === tradeSide
      ? helpers.getNewCommission(expRevAgainstInv.expectedRevenue)
      : 0;

  // currency of the commission, if there are multiples gives initial currency

  const commissionCurrency =
    expRevAgainstInv &&
    expRevAgainstInv.expectedRevenue &&
    Array.isArray(expRevAgainstInv.expectedRevenue) &&
    helpers.getCommissionCurrency(expRevAgainstInv.expectedRevenue)
      ? helpers.getCommissionCurrency(expRevAgainstInv.expectedRevenue)
      : tradeSide === "SELL"
      ? deal.currencyCommissionSeller
      : deal.currencyCommissionBuyer;

  const sumByCurrency =
    expRevAgainstInv &&
    expRevAgainstInv.expectedRevenue &&
    expRevAgainstInv.expectedRevenue.reduce((acc, item) => {
      const currencyId = item.currency.id;
      const existingItem = acc.find((x) => x.currencyId === currencyId);

      if (existingItem) {
        existingItem.sum += item.totalAmountPlusChanges || 0;
      } else {
        acc.push({
          sum: item.totalAmountPlusChanges || 0,
          currency: helpers.getCurrencyFromId(currencyId),
          currencyId: currencyId,
        });
      }

      return acc;
    }, []);

  const actualCommissionIsInOneCurrency = sumByCurrency.length === 1;

  return (
    <>
      <div className="my-8">
        <div className="flex">
          <div className="w-1 h-2 mr-[0.2rem] "></div>
          <tr className="flex w-full max-w-[320px] justify-between">
            <td>
              <p className="mr-7 label-text">Initial commission</p>
            </td>
            <td>
              <p className="w-[80px]">
                {helpers.formatMoney(
                  tradeSide === "SELL" ? deal.commissionSeller : tradeSide === "BUY" ? deal.commissionBuyer : "0",
                  tradeSide === "SELL" && deal && deal.currencyCommissionSeller
                    ? deal.currencyCommissionSeller.description
                    : tradeSide === "BUY" && deal && deal && deal.currencyCommissionBuyer
                    ? deal.currencyCommissionBuyer.description
                    : ""
                )}
              </p>
            </td>
          </tr>
        </div>
        {sumByCurrency.map((item, index) => (
          <div key={index} className="flex">
            <div className="w-1 h-2 mr-[0.2rem] "></div>
            <tr className="flex w-full max-w-[320px] justify-between">
              <td className="w-[80px]">
                <p className="mr-9 label-text">Actual commission {sumByCurrency.length > 1 ? `(in ${item.currency})` : ""}</p>
              </td>
              <td>
                <p className="w-[80px]">{helpers.formatMoney(item.sum / 100, item.currency)}</p>
              </td>
            </tr>
          </div>
        ))}
        <div className="flex">
          <div className="w-1 h-2 mr-[0.2rem] "></div>
          <tr className="flex w-full max-w-[320px] justify-between">
            <td>
              <p className="mr-9 label-text">Unit commission</p>
            </td>
            <td>
              <p className="w-[80px]">
                {deal.volume && updatedCommission && commissionCurrency
                  ? helpers.formatMoney((updatedCommission / deal.volume).toPrecision(2), commissionCurrency.description)
                  : "-"}
              </p>
            </td>
          </tr>
        </div>
        {actualCommissionIsInOneCurrency && (
          <div className="flex">
            <div className="w-1 h-2 mr-[0.2rem] "></div>
            <tr className="flex w-full max-w-[320px] justify-between">
              <td>
                <p className="mr-4 label-text">% of deal value</p>
              </td>
              <td>
                <p className="w-[80px]">
                  {Number(
                    helpers.getCommissionPercentage(
                      deal,
                      tradeSide === "BUY" ? "buyer" : tradeSide === "SELL" ? "seller" : "",
                      actualCommissionIsInOneCurrency ? sumByCurrency[0].sum / 100 : undefined
                    )
                  )
                    .toFixed(2)
                    .replace(/\.00$/, "")}
                  %
                </p>
              </td>
            </tr>
          </div>
        )}
      </div>
    </>
  );
}

export default CommissionOverview;
