import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, useFormikContext, Field, useField } from "formik";
import NumberFormat from "react-number-format";
import { AnimatePresence, motion } from "framer-motion";

function NumberFormatCustom({ field, form, ...props }) {
  return <NumberFormat {...field} {...props} getInputRef={props.innerRef} isNumericString={false} thousandSeparator={true} />;
}
function TextFieldWithLabel(props) {
  const [isSelected, setIsSelected] = useState(false);
  const inputRef = useRef(null);
  const overlayVariants = {
    onTop: {
      y: "-25px",
      x: "-10px",
      scale: 0.8,
      transition: { ease: [0, 0, 0.2, 1], duration: 0.2 },
    },
    inCenter: {
      y: "0px",
      x: "0px",
      scale: 1,
      transition: { ease: [0, 0, 0.2, 1], duration: 0.2 },
    },
  };

  useEffect(() => {
    if (inputRef && inputRef.current && isSelected) {
      inputRef.current.focus();
    }
  }, [isSelected]);

  return (
    <div
      className="text-field-container"
      onClick={() => {
        setIsSelected(true);
      }}
    >
      <Field
        innerRef={inputRef}
        className="text-field text-primary-blue"
        type="text"
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        onBlur={(e) => {
          props.onBlur(e);
          setIsSelected(false);
        }}
        onFocus={() => {
          setIsSelected(true);
        }}
        value={props.value ?? ""}
        variant="outlined"
        prefix={props.prefix}
        component={props.numberFormatCustom ? NumberFormatCustom : undefined}
      />
      <motion.label
        initial="inCenter"
        animate={isSelected ? "onTop" : props && props.value ? "onTop" : "inCenter"}
        exit="onTop"
        variants={overlayVariants}
        className="field-label"
      >
        {props.label}
      </motion.label>
    </div>
  );
}

export default TextFieldWithLabel;
