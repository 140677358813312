import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { auth } from "../../firebase";
import authentication from "../../services/authentication";
import EmptyState from "../EmptyState";

class HomeContent extends Component {
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(`Signed in as ${displayName || emailAddress}`);
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  render() {
    // Properties
    const { user } = this.props;

    if (user) {
      return <EmptyState title="Welcome" description="Click the menu items above to select a page" />;
    }

    return <EmptyState title="Welcome" description="Sign in to get started. Remember to set a First name and Last Name before entering bids and asks" />;
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomeContent.propTypes = {
  // Properties
  user: PropTypes.object,
};

export default withRouter(HomeContent);
