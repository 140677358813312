import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_DEAL from "./query_deal_single";

function useDeal(props) {
  const [deal, setDeal] = useState(false);

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(GET_DEAL, {
    // notifyOnNetworkStatusChange: false,
    // nextFetchPolicy: "standby",
    variables: { id: props && props.dealId ? props.dealId.toString() : "1" },
  });

  useEffect(() => {
    setDeal(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting deal");
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch, fetchMore, networkStatus]);

  return [deal, refetch, networkStatus, fetchMore, loading, error];
}

export default useDeal;
