import React, { useState, useEffect, useMemo } from "react";
import AddFormGeneric from "../../components/AddFormGeneric/AddFormGeneric";
import { gql } from "@apollo/client";
import { toast } from "react-toastify";
// import { withRouter  } from "react-router-dom";
// import "./AddDealPage.css"

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});
const AddFormClient = (props) => {
  const today = useMemo(() => {
    const todayDate = new Date(Date.now());
    const month = (todayDate.getMonth() + 1).toString();
    const monthFull = month.length === 1 ? "0" + month : month;
    const day = todayDate.getDate().toString();
    const dayFull = day.length === 1 ? "0" + day : day;
    return todayDate.getFullYear() + "-" + monthFull + "-" + dayFull;
  }, []);

  const graphqlQuery = useMemo(
    () => gql`
      query getClientOptions {
        clients {
          id
          companyName
        }
        clientgroups {
          id
          clientGroupName
        }
        brokers {
          id
          brokerName
        }
        countries {
          id
          name
          code
        }
      }
    `,
    []
  );

  const formFieldsArray = [
    { title: "Client Info", fieldType: "titleSection", rank: 0 },
    { name: "companyName", label: "Client Name", rank: 1, fieldType: "text", yupType: "string", required: true, defaultValue: "" },
    { name: "firstDealDate", label: "Date client profile created", rank: 2, fieldType: "date", yupType: "date", required: true, defaultValue: today },
    { name: "newLine", rank: 2.9, fieldType: "newLine" },
    {
      name: "clientGroupId",
      label: "ClientGroup",
      rank: 1.1,
      fieldType: "select",
      yupType: "integer",
      required: false,
      defaultValue: "",
      optionObjects: "clientgroups",
      optionFieldsToDisplay: ["id", "clientGroupName"],
    },
    // {name: "clientGroup", label: "New Client Group (only when Client Group does not yet exist)", rank: 10.2, fieldType:"text", yupType: "string", required: false, defaultValue: "",},
    { name: "newLine", rank: 10.3, fieldType: "newLine" },
    // {name: "brokerageInvoiceEntity", label: "Invoice Entity (Only when different from client itself)", rank: 11, fieldType:"select", yupType: "integer", required: false, defaultValue: "", optionObjects:"clients", optionFieldsToDisplay: ["id", "companyName"]},
    {
      name: "brokerId",
      label: "Introduced by",
      rank: 13,
      fieldType: "select",
      yupType: "integer",
      required: true,
      defaultValue: "",
      optionObjects: "brokers",
      optionFieldsToDisplay: ["id", "brokerName"],
    },
    {
      name: "countryId",
      label: "Country",
      rank: 14,
      fieldType: "select",
      yupType: "integer",
      required: true,
      defaultValue: "",
      optionObjects: "countries",
      optionFieldsToDisplay: ["id", "name"],
    },
    { name: "vatNumber", label: "VAT number", rank: 22.1, fieldType: "text", yupType: "string", required: false, defaultValue: "" },
    { name: "newLine", rank: 30, fieldType: "newLine" },
    { name: "companyNameExact", label: "companyNameExact", rank: 31, fieldType: "text", yupType: "string", required: false, defaultValue: undefined },
    { name: "idExact", label: "idExact", rank: 32, fieldType: "text", yupType: "string", required: false, defaultValue: undefined },
  ];

  const [formFields, setFormFields] = useState(formFieldsArray);
  const [initialValues] = useState(() => (props.initialValues ? props.initialValues : getInitialValues(formFields)), []);

  useEffect(() => {
    if (!props.newInput) {
      setFormFields([{ name: "id", label: "ID", rank: 1, fieldType: "immutable", yupType: "int", required: true, defaultValue: "0" }, ...formFields]);
    }
  });

  const mutationKeys = (values) => {
    const variables = {
      companyName: values.companyName,
      firstDealDate: values.firstDealDate,
      countryId: values.countryId,
      brokerId: values.brokerId,
      clientGroupId: values.clientGroupId ? values.clientGroupId : undefined,
      companyNameExact: values.companyNameExact ? values.companyNameExact : undefined,
      idExact: values.idExact ? values.idExact : undefined,
    };
    return variables;
  };

  const graphqlMutation = gql`
    mutation Client(
      $companyName: String!
      $firstDealDate: DateTime
      $countryId: ID
      $brokerId: ID
      $clientGroupId: ID
      $companyNameExact: String
      $idExact: ID
    ) {
      client(
        companyName: $companyName
        firstDealDate: $firstDealDate
        countryId: $countryId
        brokerId: $brokerId
        companyNameExact: $companyNameExact
        idExact: $idExact
        clientGroupId: $clientGroupId
      ) {
        id
        companyName
      }
    }
  `;

  const afterSubmit = (values) => {
    // console.log("afterSubmit");
    // console.log(values);
    toast.success(`New client submitted: ${values.companyName ? values.companyName : undefined}.`);
    if (props.closeAfterSubmit) {
      // console.log("closeAfterSubmit");
      props.closeAfterSubmit();
    }
    if (props.refetch) {
      props.refetch();
      console.log("props.refetch in AddFormClient");
    }
  };

  return (
    <>
      <AddFormGeneric
        className="addFormGeneric"
        formFields={formFields}
        initialValues={initialValues}
        newInput={true}
        graphqlQuery={graphqlQuery}
        graphqlMutation={graphqlMutation}
        mutationKeys={mutationKeys}
        graphql={true}
        afterSubmit={afterSubmit}
      />
    </>
  );
};

// export default withRouter(AddDealForm)
export default AddFormClient;
