import React, { useState } from "react";
import AddFormGeneric2 from "../../components/AddFormGeneric/AddFormGeneric2";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import format from "date-fns/format";
import useDealOptions from "../../hooks/useDealOptions";
import useUpdateDeal from "../../hooks/useUpdateDeal";

const AddFormDealQuick = (props) => {
  const today = format(new Date(), "yyyy-MM-dd");

  const [dealOptions] = useDealOptions();
  const [updateDeal] = useUpdateDeal();

  const addFunction = (updateDeal, mutationKeys) => (values) => {
    updateDeal({ variables: mutationKeys(values) });
  };

  const sortCompanyNameAlphabetic = (first, second) => {
    if (first && first.companyName && second && second.companyName) {
      return first.companyName.toLowerCase() > second.companyName.toLowerCase();
    }
    return true;
  };
  const sortBrokerNameAlphabetic = (first, second) => {
    if (first && first.brokerName && second && second.brokerName) {
      return first.brokerName.toLowerCase() > second.brokerName.toLowerCase();
    }
    return true;
  };

  const sortNameAlphabetic = (first, second) => {
    if (first && first.name && second && second.name) {
      return first.name.toLowerCase() > second.name.toLowerCase();
    }
    return true;
  };

  const limitProdSpecOptions = (productId, optionObjects, sortingAlgorithm) => {
    let options = [];
    if (productId && ["5", "10", "72"].includes(productId)) {
      options = [...optionObjects.thg];
    } else if (productId && ["36", "73"].includes(productId)) {
      options = [...optionObjects.uer];
    } else if (productId && ["1"].includes(productId)) {
      options = [...optionObjects.uco];
    } else if (productId && ["29", "2"].includes(productId)) {
      options = [...optionObjects.biodiesel];
    } else if (productId && ["32", "78", "79"].includes(productId)) {
      options = [...optionObjects.biomethane];
    } else if (productId && ["34"].includes(productId)) {
      options = [...optionObjects.rtfc];
    } else if (productId && ["77"].includes(productId)) {
      options = [...optionObjects.oerver];
    } else {
      options = [];
    }
    return options.sort((first, second) => (sortingAlgorithm ? sortingAlgorithm(first, second) : second > first));
  };

  // prettier-ignore
  const formFieldsArray = [
      {title: props && props.initialValues && props.initialValues.olyxRef ? "Olyxref: " + props.initialValues.olyxRef : "Deal number undefined", fieldType: "titleSection", rank: 0},
      {name: "newInput", label: "newInput", rank: 0.1, fieldType:"invisible", yupType: "boolean", required: false, defaultValue: false },
      {name: "description", label: "Deal Description", rank: 1, fieldType:"text", yupType: "string", required: false, defaultValue: "",},
      {name: "dealDate", label: "Deal Date as on Deal Conformation", rank: 2, fieldType:"date", yupType: "date", required: true, defaultValue: today,},
      {name: "newLine", rank: 29.9, fieldType: "newLine"},
      {name: "newLine", rank: 9, fieldType: "newLine"},
      {name: "sellerId", label: "Seller", rank: 10, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"clients", optionFieldsToDisplay: ["id", "companyName"], sortingAlgorithm: sortCompanyNameAlphabetic},
      {name: "brokerSellerId", label: "Broker Seller", rank: 11, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"brokers", optionFieldsToDisplay: ["id", "brokerName"], sortingAlgorithm: sortBrokerNameAlphabetic },
      {name: "buyerId", label: "Buyer", rank: 12, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"clients", optionFieldsToDisplay: ["id", "companyName"], sortingAlgorithm: sortCompanyNameAlphabetic },
      {name: "brokerBuyerId", label: "Broker Buyer", rank: 13, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"brokers", optionFieldsToDisplay: ["id", "brokerName"], sortingAlgorithm: sortBrokerNameAlphabetic },
      {name: "newLine", rank: 2.4, fieldType: "newLine"},
      {name: "productId", label: "Product", rank: 2.5, fieldType:"select", yupType: "integer", required: false, defaultValue: "0", optionObjects:"products", optionFieldsToDisplay: ["id", "name"], conditionOn: "category", optionFilter: (item, filterValue) => item.id === filterValue, sortingAlgorithm: sortNameAlphabetic },
      {name: "productSpec", label: "Product and Spec Description", rank: 2.6, fieldType:"textLimitedOptions", yupType: "string", required: false, defaultValue: "1", optionObjects: "prodSpecOptions", showOnCondition: (values) => values && values.productId && ( ["1", "2", "5", "10", "29", "32", "34", "36", "72", "73", "77", "78", "79"].includes( values.productId.toString() )), limitOptions: limitProdSpecOptions},
      {name: "volume", label: "Volume", rank: 3, fieldType:"text", yupType: "integer", required: false, defaultValue: "98",},
      {name: "unitId", label: "Unit", rank: 4, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"units", optionFieldsToDisplay: ["id", "description"]},
      {name: "newLine", rank: 4.5, fieldType: "newLine"},
      {name: "currencyDealId", label: "Currency Deal", rank: 5, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"currencies", optionFieldsToDisplay: ["id", "description"]},
      {name: "pricePerUnit", label: "Price per Unit", rank: 6, fieldType:"textMoney", yupType: "float", required: false, defaultValue: "98",},
      {name: "currencyCommissionSellerId", label: "Currency CommissionSeller", rank: 40, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"currencies", optionFieldsToDisplay: ["id", "description"]},
      {name: "commissionSeller", label: "Total Commission from Seller", rank: 41.1, fieldType:"textMoney", yupType: "float", required: false, defaultValue: "98",},
      {name: "currencyCommissionBuyerId", label: "Currency CommissionBuyer", rank: 42, fieldType:"select", yupType: "integer", required: false, defaultValue: "1", optionObjects:"currencies", optionFieldsToDisplay: ["id", "description"]},
      {name: "commissionBuyer", label: "Total Commission from Buyer", rank: 43.1, fieldType:"textMoney", yupType: "float", required: false, defaultValue: "98",},
      {name: "newLine", rank: 45.0, fieldType: "newLine"},
      {name: "dateExpectedSeller", label: "Estimated Invoice Date Seller", rank: 45.1, fieldType:"date", yupType: "date", required: false, defaultValue: today,},
      {name: "dateExpectedBuyer", label: "Estimated Invoice Date Buyer", rank: 45.2, fieldType:"date", yupType: "date", required: false, defaultValue: new Date("2022-01-01"),},
      {name: "dealStatusId", label: "Deal Status", rank: 51, fieldType:"invisible", yupType: "integer", required: true, defaultValue: "1", optionObjects:"dealstatuses", optionFieldsToDisplay: ["id", "description"]},
      ];

  const [formFields, setFormFields] = useState(formFieldsArray);

  const mutationKeys = (values) => {
    const variables = {
      dealId: values.id,
      newInput: values.newInput ? values.newInput : false,
      dealDate: values.dealDate ? values.dealDate : undefined,
      updatedById: "1", //values.updatedById,
      productId: values.productId ? values.productId : undefined,
      productSpecInput: { description: values.productSpec, productId: values.productId, productSpecPropertyInput: [] },
      termsAgreementId: values.termsAgreementId ? values.termsAgreementId : undefined,
      termsRegionId: values.termsRegionId ? values.termsRegionId : undefined,
      termsRegion: values.termsRegion ? values.termsRegion : undefined,
      currencyDealId: values.currencyDealId ? values.currencyDealId : undefined,
      pricePerUnit: values.pricePerUnit ? values.pricePerUnit : undefined,
      unitId: values.unitId ? values.unitId : undefined,
      volume: values.volume ? values.volume : undefined,
      currencyCommissionBuyerId: values.currencyCommissionBuyerId ? values.currencyCommissionBuyerId : undefined,
      currencyCommissionSellerId: values.currencyCommissionSellerId ? values.currencyCommissionSellerId : undefined,
      commissionBuyer: values.commissionBuyer ? values.commissionBuyer : undefined,
      commissionSeller: values.commissionSeller ? values.commissionSeller : undefined,
      buyerId: values.buyerId ? values.buyerId : undefined,
      sellerId: values.sellerId ? values.sellerId : undefined,
      brokerSellerId: values.brokerSellerId ? values.brokerSellerId : undefined,
      brokerBuyerId: values.brokerBuyerId ? values.brokerBuyerId : undefined,
      dealStatusId: values.dealStatusId ? values.dealStatusId : undefined,
      importCountryId: values.importCountryId ? values.importCountryId : undefined,
      exportCountryId: values.exportCountryId ? values.exportCountryId : undefined,
      description: values.description ? values.description : undefined,
      dateExpectedSeller: values.dateExpectedSeller ? values.dateExpectedSeller : undefined,
      dateExpectedBuyer: values.dateExpectedBuyer ? values.dateExpectedBuyer : undefined,
    };
    return { input: { ...variables } };
  };

  const getCurrencyFromId = (currencyId) => {
    if (currencyId === undefined || currencyId === null) {
      return "";
    } else if (currencyId === "1") {
      return "USD";
    } else if (currencyId === "2") {
      return "EUR";
    } else if (currencyId === "3") {
      return "GBP";
    } else if (currencyId === "4") {
      return "JPY";
    } else if (currencyId === "5") {
      return "CNY";
    } else {
      return "";
    }
  };

  function formatMoney(number, currencyId) {
    // hardcode currency data // to do
    const currency = getCurrencyFromId(currencyId);
    return Number(number).toLocaleString("en-US", currency ? { style: "currency", currency: currency } : undefined);
  }

  const afterSubmit = (values, history) => {
    // toast.success(`Changes in deal submitted ${values.olyxRef ? "for deal olyxRef " + values.olyxRef : undefined}.`);
    toast.dismiss();
    toast.success(`Thanks for submitting info ${values.olyxRef ? "for deal olyxRef " + values.olyxRef : undefined}!`, { autoClose: 8000 });
    if (values.commissionSeller && values.currencyCommissionSellerId) {
      toast.success(`Your deal just added ${formatMoney(values.commissionSeller, values.currencyCommissionSellerId)} to the weekly commission target. 💸`, {
        delay: 600,
        autoClose: 8000,
      });
    }
    if (values.commissionBuyer && values.currencyCommissionBuyerId) {
      toast.success(`Your deal added another ${formatMoney(values.commissionBuyer, values.currencyCommissionBuyerId)} to the weekly commission target. 💰`, {
        delay: 1600,
        autoClose: 8000,
      });
    }
    if (props.closeAfterSubmit) {
      props.closeAfterSubmit();
    }
    if (props.refetch) {
      props.refetch();
    }
  };

  const dealOptionsWithAdditionalHardcodedOptions = (dealOptions) => {
    if (dealOptions) {
      const thg = ["22 C", "22 A", "22 O", "22 B", "23 C", "23 A", "23 O", "23 B", "24 C", "24 A", "24 O", "24 B", "25 C", "25 A", "25 O", "25 B"];
      const uer = ["22 German", "23 German", "22 FQD", "23 FQD", "24 German", "25 German"];
      const oerver = ["2022", "2023", "2024", "2025"];
      const uco = ["FFA 5", "FFA 3", "FFA 7"];
      const biodiesel = ["CFPP -20", "CFPP -15", "CFPP -10", "CFPP -5", "CFPP -3", "CFPP 0", "CFPP +3", "CFPP +5", "CFPP +10"];
      const biomethane = ["Biomethane with THG-Quote", "22", "22 Uncertified waste", "22 Certified waste", "23", "23 Uncertified waste", "23 Certified waste"];
      const rtfc = ["22 NC", "23 NC", "24 NC", "25 NC"];
      const newDealOptions = {
        ...dealOptions,
        prodSpecOptions: { thg: thg, uco: uco, biodiesel: biodiesel, uer: uer, biomethane: biomethane, rtfc: rtfc, oerver: oerver },
      };
      return newDealOptions;
    }
    return undefined;
  };

  return (
    <>
      <AddFormGeneric2
        className="addFormGeneric"
        formFields={formFields}
        formOptions={dealOptionsWithAdditionalHardcodedOptions(dealOptions)}
        addFunction={addFunction(updateDeal, mutationKeys)}
        initialValues={props.initialValues}
        buttonAddProdSpecProp={true}
        newInput={props.newInput}
        afterSubmit={afterSubmit}
        header={false}
      />
    </>
  );
};

export default withRouter(AddFormDealQuick);
