import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useField } from "formik";

export default function DateInput({ label, defaultValue, ...props }) {
  const [field, meta, helpers] = useField({ ...props, value: defaultValue });
  const { value } = meta;
  const { setValue } = helpers;
  // const [selectedDate, setSelectedDate] = React.useState(new Date(props.defaultValue));

  const handleDateChange = (date, value) => {
    setValue(value);
    // setSelectedDate("2015-02-03");
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {/* <div style={{padding: 0}}> */}
        <label htmlFor={props.id || props.name}>
          {label}
          {props.required && "*"}
        </label>
        {/* <div/> */}
        {/* <input className="date-input" */}
        {/*       {...field} */}
        {/*       {...props} */}
        {/*       value={defaultValue} */}
        {/*       /> */}
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <Grid container justify="space-around"> */}
            <KeyboardDatePicker
              disableToolbar
              variant="outlined"
              fullWidth
              format="yyyy-MM-dd"
              margin="normal"
              id={props.name}
              label={props.label}
              value={field.value}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ maxWidth: 300 }}
              inputProps={{ style: { color: "#22b0ff", borderBottomColor: "red" } }}
            />
            {/* </Grid> */}
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
    </>
  );
}
