import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import helpers from "../../services/helpers";

const PaymentStatusIcon = ({ size, ...props }) => {
  const [isHover, setHover] = useState(false);
  const getLayoutForPaymentStatus = helpers.getLayoutForPaymentStatus;

  const sizeVariants = {
    small: "inline-block w-2 h-2 mr-2 ",
    medium: "inline-block w-3 h-3 mr-2",
    large: "inline-block w-4 h-4 mr-2",
  };

  return (
    <div
      className="relative w-full h-full flex items-center justify-center rounded-sm"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div className={size && sizeVariants[size] ? sizeVariants[size] : `${sizeVariants.small}`}>
        <div className={`rounded-sm ${getLayoutForPaymentStatus(props.invoiceStatusSummary)}`}></div>
      </div>
      <AnimatePresence>
        {isHover && (
          <motion.div
            className="absolute z-50 p-2 text-center text-white transform -translate-x-1/2 bg-gray-800 rounded-lg -top-10 left-1/2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {props.invoiceStatusSummary}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PaymentStatusIcon;
