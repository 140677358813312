import CommentsCount from "../CommentsCount/CommentsCount";
import SingleDealNote from "../../components/SingleDealNote/SingleDealNote";
import { motion, Variants, AnimatePresence } from "framer-motion";
import EmptyState from "../EmptyState/EmptyState";
import { DealNote } from "../../codegen/gql/graphql";

const CommentsColumn = ({ dealNotes, title, emptyState }: { dealNotes: DealNote[]; title: string; emptyState: string }) => {
  const container: Variants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1,
      },
    },
  };

  const item: Variants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <div className="rounded-2xl bg-white p-4 w-full h-fit relative">
        <div className="flex items-center gap-2">
          <h2 className="my-2 text-xl">{title}</h2>
          <CommentsCount title={title} count={dealNotes ? dealNotes.length : 0}></CommentsCount>
        </div>
        <div className="w-full ml-auto comment-block comment-block-65vh ">
          <AnimatePresence>{dealNotes && dealNotes.length < 1 && <EmptyState title={emptyState} />}</AnimatePresence>
          <motion.div
            className="timeline-container-scroll"
            variants={container}
            initial="hidden"
            animate={dealNotes && dealNotes.length > 0 ? "visible" : "hidden"}
            exit="hidden"
          >
            <AnimatePresence>
              {dealNotes &&
                dealNotes
                  .filter((dn: DealNote) => dn.deal)
                  .map((dealNote: DealNote, index: number) => {
                    return (
                      <motion.div variants={item} key={dealNote.id + dealNote.deal.olyxRef}>
                        <SingleDealNote dealNote={dealNote} olyxRef={dealNote.deal.olyxRef} dealId={dealNote.deal.id} />
                      </motion.div>
                    );
                  })}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
    </>
  );
};
export default CommentsColumn;
