import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import helpers from "../../services/helpers";

const FilterExpRevStatus = ({ data, categoryFilters, setCategoryFilters, setCurrentPage }) => {
  const [isHover, setIsHover] = useState({});
  const uniqueInvoiceStatusSummary = [...new Set(data.map((expRev) => expRev.invoiceStatusSummary))];
  const getLayoutForPaymentStatus = helpers.getLayoutForPaymentStatus;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="border-gray-200 border rounded-xl p-2 w-32 bg-white "
    >
      <div className="w-11/12 mx-auto ">
        <p>Filter:</p>
        <div className="flex gap-2 mt-2">
          {uniqueInvoiceStatusSummary.map((status) => {
            return (
              <div key={status} className="relative w-3 h-3 flex">
                <button
                  onMouseEnter={() => {
                    setIsHover({
                      ...isHover,
                      [status]: true,
                    });
                  }}
                  onMouseLeave={() => {
                    setIsHover({ ...isHover, [status]: false });
                  }}
                  className={`inline-block box-border rounded-sm ${getLayoutForPaymentStatus(status)} ${
                    categoryFilters === status ? "border-2 border-grey" : ""
                  } `}
                  onClick={() => {
                    setCurrentPage(1);
                    setCategoryFilters(status);
                  }}
                ></button>
                <AnimatePresence>
                  {isHover[status] && (
                    <motion.div
                      className="absolute z-50 p-2 text-center text-white transform -translate-x-1/2 bg-gray-800 rounded-lg -top-10 left-1/2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {status}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center mt-4">
          {categoryFilters !== "all" && (
            <button
              className="bg-primary-blue text-white rounded w-20"
              onClick={() => {
                setCategoryFilters("all");
              }}
            >
              clear all
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default FilterExpRevStatus;
