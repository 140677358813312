import { gql } from "@apollo/client";

const GET_USERS = gql`
  query getUsers($input: UsersInput!) {
    users(input: $input) {
      id
      userUid
      fullName
      imageUrl
      taggable
    }
  }
`;

export default GET_USERS;
