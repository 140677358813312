import { useMutation } from "@apollo/client";
import MUTATION_MARKINVOICESASDELETED from "./mutation_markinvoicesasdeleted";

function useMutationMarkInvoicesAsDeleted(props) {
  const [markInvoicesAsDeleted, { data, loading, error }] = useMutation(MUTATION_MARKINVOICESASDELETED, {
    variables: {
      ids: props && props.invoiceId ? [props.invoiceId] : [],
    },
    refetchQueries: ["Invoices"],
  });

  return { markInvoicesAsDeleted, data, loading, error };
}

export default useMutationMarkInvoicesAsDeleted;
