import React from "react";
import Moment from "react-moment";
import DescriptionIcon from "@material-ui/icons/Description";
import helpers from "../../services/helpers";

function DealDetailCard({ deal }) {
  return (
    <div className="w-full p-4 mb-8 md:mb-16 bg-white rounded-2xl md:w-max">
      {deal && deal !== null && (
        <>
          <div className="px-4">
            <div className="flex justify-between">
              <h2 className="mt-2 text-2xl text-primary-blue">{deal.olyxRef ? deal.olyxRef : ""}</h2>
              <Moment className="mt-2 text-sm" format="D MMM YYYY">
                {deal.dealDate}
              </Moment>
            </div>
            <div className="flex justify-center my-4" style={{ textAlign: "center", color: "#171720", marginTop: -15 }}>
              {helpers.getCategorySymbol(deal.product && deal.product.category && deal.product.category.name ? deal.product.category.name : "no product", {
                height: 20,
                width: 20,
                marginRight: 5,
                marginBottom: -3,
              })}
            </div>
            <div className="flex items-end justify-center gap-1 mb-4 flex-wrap">
              <h2 className="text-2xl">{deal.productSpec && deal.productSpec.product ? deal.productSpec.product.name : null} </h2>
              <p className="mb-1.5 text-xs">
                {deal.productSpec && deal.productSpec.description && deal.productSpec.description !== "no description" ? deal.productSpec.description : null}
              </p>
            </div>
            <div className="flex items-end justify-center gap-1 mb-6">
              <p className="">
                {deal && deal.pricePerUnit ? helpers.formatMoney(deal.pricePerUnit, deal.currencyDeal ? deal.currencyDeal.description : "") : ""}
              </p>
              <p className="">-</p>
              <p className="">
                {" "}
                {deal && deal.volume ? Number(deal.volume).toLocaleString("en-US") : null}{" "}
                {deal && deal.unit && deal.unit.id.toString() !== "2" && deal.unit.id.toString() !== "6"
                  ? deal.unit.descriptionShort
                    ? deal.unit.descriptionShort
                    : deal.unit.description ?? ""
                  : ""}
              </p>
            </div>
            <div className="mx-auto flex flex-wrap justify-center gap-4">
              <div className="inline-block gap-2 px-2 py-1 mb-2 text-sm border border-gray-light rounded-xl">
                <span className="mr-2 text-gray-dark">Deal Status</span>
                <span className="">{deal.dealStatus && deal.dealStatus.description}</span>
              </div>
              <a
                href={`https://drive.google.com/drive/u/0/search?q=${deal.olyxRef}`}
                rel="noreferrer"
                target="_blank"
                className="md:ml-4 mb-2 inline-block gap-2 border border-gray-light px-2 py-0.5 rounded-xl text-sm"
              >
                <span className="mr-2 text-gray-dark">Deal Confirmation</span>
                <span className="">
                  {" "}
                  <a href={`https://drive.google.com/drive/u/0/search?q=${deal.olyxRef}`} rel="noreferrer" target="_blank">
                    <DescriptionIcon />
                  </a>
                </span>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DealDetailCard;
