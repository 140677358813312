import { motion } from "framer-motion";

const EmptyState = ({ title, description }: { title: string; description?: string }) => {
  return (
    <motion.div
      className="w-11/12 p-10 mx-auto mt-3 text-center bg-gray-100 rounded-xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="block text-gray-500">
        <h1 className="text-3xl">{title}</h1>
        <span> {description}</span>
      </div>
    </motion.div>
  );
};
export default EmptyState;
