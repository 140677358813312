import React, { useState, useEffect } from "react";
import AddFormDealQuick from "../../components/AddFormDeal/AddFormDealQuick";
import AddFormDeal from "../../components/AddFormDeal/AddFormDeal";
import SelectDeal from "../../components/AddFormDeal/SelectDeal";
import AddClientModal from "../../components/Modals/AddClientModal";
import TermsRegionModal from "../../components/Modals/TermsRegionModal";
import AddProductModal from "../../components/Modals/AddProductModal";
import Grid from "@material-ui/core/Grid";
// import useDealOptions from "../../hooks/useDealOptions";
import SyncIcon from "@material-ui/icons/Sync";
import Button from "@material-ui/core/Button";
import useDeal from "../../hooks/useDeal";
import helpers from "../../services/helpers";
import AddIcon from "@material-ui/icons/Add";

function AddDealSelect(props) {
  const [initialValues, setInitialValues] = useState(undefined);
  const getInitialValues = helpers.getInitialValuesDeal;

  const [singleDeal, refetchDeal] = useDeal({ dealId: "99999" });

  // const [dealOptions, refetch, networkStatus] = useDealOptions();
  // temp disable dealOptions and refetch
  const refetch = () => null;

  const [showNewDealForm, setShowNewDealForm] = useState(false);

  useEffect(() => {
    if (singleDeal && singleDeal.deals && singleDeal["deals"][0] !== undefined) {
      setInitialValues(() => getInitialValues(singleDeal["deals"][0]));
    }
  }, [singleDeal]);

  return (
    <>
      <div style={styles.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={8} md={4} lg={4}>
            <div style={styles.contentWrapper}>
              <SelectDeal singleDeal={{ refetch: refetchDeal }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <div style={styles.contentWrapper}>
              <Button
                onClick={() => {
                  setInitialValues(null);
                  setShowNewDealForm(true);
                }}
                variant="outlined"
              >
                <AddIcon />
                New Deal
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={styles.groupWrapper}>
              {props.extensive && initialValues && (
                <div style={styles.contentWrapper}>
                  <AddClientModal refetch={refetch} />
                </div>
              )}
              {props.extensive && initialValues && (
                <div style={styles.contentWrapper}>
                  <TermsRegionModal refetch={refetch} />
                </div>
              )}
              {props.extensive && initialValues && (
                <div style={styles.contentWrapper}>
                  <AddProductModal refetch={refetch} />
                </div>
              )}
              <div style={styles.contentWrapper}>
                <Button type="submit" onClick={() => refetch()} color="default" variant="text" fullWidth>
                  <SyncIcon />
                  Refetch dealOptions
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {props.extensive && initialValues && (
              <AddFormDeal
                newInput={false}
                initialValues={initialValues}
                // dealOptions={dealOptions}
              />
            )}
            {!props.extensive && initialValues && <AddFormDealQuick newInput={false} initialValues={initialValues} />}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {showNewDealForm && !initialValues && (
              <AddFormDeal
                newInput={true}
                initialValues={{ newInput: true }}
                // dealOptions={dealOptions}
              />
            )}
            {!props.extensive && initialValues && <AddFormDealQuick newInput={false} initialValues={initialValues} />}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default AddDealSelect;

const styles = {
  contentWrapper: {
    paddingLeft: "3em",
    textAlign: "left",
    paddingRight: "1em",
    paddingTop: "1em",
    paddingBottom: "1em",
    backgroundColor: "#115980",
    marginLeft: "auto",
    marginRight: "auto",
  },
  root: {
    paddingTop: "3em",
    paddingLeft: "auto",
    paddingRight: "0em",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "0em",
    width: "100%",
    backgroundColor: "#115980",
    // height: "18.5em",
    height: "100%",
  },
  groupWrapper: {
    paddingLeft: "0em",
    textAlign: "left",
    paddingRight: "auto",
    paddingTop: "auto",
    paddingBottom: "auto",
    backgroundColor: "#115980",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
