import FormGenerator from "../../components/FormGenerator/FormGenerator";
import NetworkGraph from "../../components/NetworkGraph/NetworkGraph";
import useBrokers from "../../typedHooks/queries/queryBrokers";
import useClients from "../../typedHooks/queries/queryClients";
import useProduct from "../../typedHooks/queries/queryProducts";
import helpersMyDeals from "../../components/MyDeals/helpersMyDeals";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import Product from "../../interfaces/Products";
import { Broker } from "../../interfaces/Broker";
import Client from "../../interfaces/Clients";
import useNetworkGraph from "../../typedHooks/queries/queryNetworkGraph";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FormField } from "../../interfaces/FormGeneratorProps";

const NetworkGraphPage = () => {
  const { windowDimensions } = useWindowDimensions();
  const periods = helpersMyDeals.periods;
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const [broker, setBroker] = useState<string>(typeof queryParams.broker !== "string" ? "0" : queryParams.broker);
  const [product, setProduct] = useState<string>(typeof queryParams.product !== "string" ? "0" : queryParams.product);
  const [client, setClient] = useState<string>(typeof queryParams.client !== "string" ? "0" : queryParams.client);
  const [dateBeforeAndAfter, setDateBeforeAndAfter] = useState(
    queryParams.dateBefore && queryParams.dateAfter
      ? {
          before: queryParams.dateBefore,
          after: queryParams.dateAfter,
        }
      : {
          before: periods.find((period) => period.label === "2020-Today")!.dateBefore,
          after: periods.find((period) => period.label === "2020-Today")!.dateAfter,
        }
  );
  const allBrokers = useBrokers();
  const allProducts = useProduct();
  const allClients = useClients();

  const queryDealsInput = {
    brokerIds: broker === "0" ? [] : [broker],
    clientIds: client === "0" ? [] : [client],
    productIds: product === "0" ? [] : [product],
    after: dateBeforeAndAfter.after,
    before: dateBeforeAndAfter.before,
  };

  const networkGraphData = useNetworkGraph(queryDealsInput);

  const nodes = networkGraphData?.dataFromQuery?.networkgraphdata?.nodes ?? [];
  const links = networkGraphData?.dataFromQuery?.networkgraphdata?.links ?? [];

  const newQueries = {
    client: client,
    broker: broker,
    product: product,
    dateAfter: dateBeforeAndAfter.after,
    dateBefore: dateBeforeAndAfter.before,
  };

  history.replace({ search: queryString.stringify(newQueries) });

  const formFields: {
    [key: string]: FormField;
  } = {
    brokerFields: {
      type: "dropdown",
      initialValue: broker,
      label: "Broker",
      onChange: (brokerId: string) => {
        setBroker(brokerId ? brokerId : "0");
      },
      // to match options interface
      options: allBrokers ? allBrokers.brokers.map((broker: Broker) => ({ id: String(broker.id), name: broker.brokerName })) : [],
    },
    productFields: {
      type: "dropdown",
      initialValue: product,
      label: "Products",
      onChange: (productId: string) => {
        setProduct(productId ? productId : "0");
      },
      options: allProducts
        ? allProducts.products
            .filter((product: Product) => ("isActive" in product ? product.isActive : true))
            .map((product: Product) => ({ id: String(product.id), name: product.name }))
        : [],
    },
    clientsFields: {
      type: "dropdown",
      initialValue: client,
      label: "Clients",
      onChange: (clientId: string) => {
        setClient(clientId ? clientId : "0");
      },
      options: allClients ? allClients.clients.map((client: Client) => ({ id: String(client.id), name: client.companyName })) : [],
    },
    dateFields: {
      type: "dropdown",
      initialValue: periods.find((period) => period.dateAfter === dateBeforeAndAfter.after && period.dateBefore === dateBeforeAndAfter.before)
        ? periods.find((period) => period.dateAfter === dateBeforeAndAfter.after && period.dateBefore === dateBeforeAndAfter.before)?.label
        : "",
      label: "Deal Date",
      onChange: (dateBeforeAndAfterLabel: any) => {
        const period = periods.find((period) => period.label === dateBeforeAndAfterLabel);
        if (!period && dateBeforeAndAfterLabel !== "0") setDateBeforeAndAfter({ before: dateBeforeAndAfter.before, after: dateBeforeAndAfter.after });
        else
          setDateBeforeAndAfter(
            period ? { before: period.dateBefore, after: period.dateAfter } : { before: dateBeforeAndAfter.before, after: dateBeforeAndAfter.after }
          );
      },
      options: periods.map(({ label }) => ({ id: label, name: label })),
    },
  };

  return (
    <>
      <div className="bg-white">
        <div className="py-2">
          <FormGenerator
            formFields={[...Object.values(formFields)]}
            layoutOptions={{
              hasLabelsOnTop: true,
              hasCloseButton: false,
            }}
            customFormStyles={{
              parent: "h-full  w-full pt-[20px] border-box flex flex-wrap gap-x-[1%] bg-white p-2 justify-start md:justify-between",
              children: "w-full p-[10px] pt-[24px] lg:p-[0px] lg:w-[24%] lg:pt-[0px]",
            }}
          ></FormGenerator>
          {nodes && links && nodes?.length !== 0 && links?.length !== 0 && (
            <NetworkGraph
              location="networkgraph"
              nodes={[...nodes]}
              links={[...links]}
              height={windowDimensions.height}
              width={windowDimensions.width}
            ></NetworkGraph>
          )}
        </div>
      </div>
    </>
  );
};

export default NetworkGraphPage;
