import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import helpers from "../../services/helpers";
import { Client } from "../../codegen/gql/graphql";

const CommissionPerClientList = ({ barColor, clientList }: { barColor: string; clientList: { commissionInEur: number; client: Client }[] }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [parentWidth, setParentWidth] = useState(0);

  const sortedClientList = [...clientList].sort((a, b) => b.commissionInEur - a.commissionInEur);

  const highestCommission = sortedClientList[0]?.commissionInEur ?? 0;

  const updateDimensions = () => {
    if (ref.current) {
      const parentRect = ref.current.getBoundingClientRect();
      setParentWidth(parentRect.width - 240);
    }
  };

  useEffect(() => {
    if (ref.current) updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const xScale = d3
    .scaleLinear()
    .domain([0, highestCommission])
    .range([0, parentWidth]);
  return (
    <div ref={ref} className="flex w-full h-full justify-center rounded-xl flex-wrap items-start p-4 bg-white overflow-auto">
      <div className="relative flex flex-wrap gap-1 w-full">
        {sortedClientList.map((client) => {
          const barWidth = xScale(client.commissionInEur);
          return (
            <div key={client.client.id} className={`${client.commissionInEur >= 0 ? "unset" : "hidden"} flex justify-start relative w-full`}>
              <div className="w-[120px] h-[24px] relative">
                <p className="z-10 pt-[2px] px-[8px] h-full w-full text-ellipsis border-[1px] border-white overflow-auto overflow-x-clip whitespace-nowrap rounded-[5px] hover:scale-105 hover:border-[#DDDDDD] hover:bg-white hover:w-auto hover:overflow-visible hover:absolute hover:cursor-default">
                  {client.client.companyName}
                </p>
              </div>
              <div className="flex-grow">
                <div
                  className={"relative rounded-tr-[5px] rounded-br-[5px] z-0 top-0 transition-all"}
                  style={{
                    width: barWidth + "px",
                    backgroundColor: barColor,
                    height: 24 + "px",
                    left: "0px",
                  }}
                ></div>
              </div>
              <span className="absolute text-xs right-[10px] top-1 text-slate-600" style={{ left: xScale(client.commissionInEur) + 130 + "px" }}>
                {helpers.centsToFormattedAmount(client.commissionInEur, "EUR")}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CommissionPerClientList;
