const calculatePaymentPerformanceHasNegativeValue = (data) => {
  const hasNegativeValue = data
    ? data.some((paidYear) => {
        const result = Object.entries(paidYear).some(([key, value]) => {
          if (key.includes("paidEur")) {
            return value.totalSum < 0;
          }
          return false;
        });
        return result;
      })
    : false;

  return hasNegativeValue;
};

export default calculatePaymentPerformanceHasNegativeValue;
