import { gql } from "@apollo/client";

const GET_CLIENTS = gql`
  query getClients {
    clients {
      id
      companyName
    }
  }
`;

export default GET_CLIENTS;
