import { useRef, useEffect } from "react";
import * as d3 from "d3";
import helpers from "../../services/helpers";

function NetworkGraph({ location, nodes, links, height, width }) {
  const graphRef = useRef(null);

  function forceGraph(nodes, links, height, width, ref) {
    let olyxRefsActive = new Set();
    let clickedNodes = new Set();

    const margin = { top: 20, right: 40, bottom: 20, left: 40 };
    const graphHeight = location === "reporting" ? height : height - 180;

    const maxValue = d3.max(nodes, (d) => d.value);
    const minValue = d3.min(nodes, (d) => d.value);

    let sqrtScale = d3
      .scalePow()
      .exponent(0.7)
      .domain([minValue, maxValue])
      .range([5, 20]);

    const svg = d3.select(ref.current);

    const drag = (simulation) => {
      const dragstarted = (event) => {
        if (!event.active) simulation.alphaTarget(0.1).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      };

      const dragged = (event) => {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      };

      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };

    // identify which nodes are linked to only a single other node
    nodes.forEach((n) => {
      // retrieve all links containing node n
      const relevantLinks = links.filter((l) => l.source === n.name || l.target === n.name);
      if (relevantLinks.length === 1) {
        n.hasSingleLink = true;
      } else {
        n.hasSingleLink = false;
      }
    });

    const forceNodes = d3.forceManyBody().strength(function(n) {
      // small number of nodes
      if (nodes.length < 500) {
        // client nodes connected to a single broker exhibit weak repulsion
        if (n.hasSingleLink) {
          return -10;
        } // other nodes exhibit strong repulsion
        else {
          return -300;
        }
      }
      // large number of nodes
      else {
        return -30;
      }
    });
    const forceLinks = d3.forceLink(links).id((d) => d.name);
    const simulation = d3
      .forceSimulation(nodes)
      .force("link", forceLinks)
      .force("charge", forceNodes)
      .force(
        "collide",
        d3
          .forceCollide()
          .strength(1)
          .radius((d) => (d.type === "BROKER" ? 25 : sqrtScale(d.value) * 1.2))
          .iterations(1)
      )
      .force("center", d3.forceCenter((width - margin.left - margin.right) / 2, (graphHeight - margin.top - margin.bottom) / 2));

    svg.selectAll("*").remove();

    const board = svg
      .append("svg")
      .attr("width", width)
      .attr("height", graphHeight)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic; width: intrinsic;")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const link = board
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 1)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("opacity", 1);

    link.exit().remove();

    const node = board
      .append("g")
      .selectAll("circle")
      .data(nodes)
      .enter()
      .append("g")
      .call(drag(simulation))
      .attr("opacity", 1);

    node
      .append("pattern")
      .attr("id", (d) => "image" + d.img)
      .attr("width", 1)
      .attr("height", 1)
      .append("svg:image")
      .attr("xlink:href", (d) => d.img)
      .attr("width", 30)
      .attr("height", 30);

    // adding images
    node
      .append("svg:circle")
      .attr("fill", (d) => (d.type === "BROKER" && d.img !== "" ? "url(#image" + d.img + ")" : "#20abfb"))
      .attr("r", (d) => (d.type === "BROKER" ? 15 : sqrtScale(d.value)));

    const labels = node
      .append("text")
      .text((d) =>
        d.type === "BROKER"
          ? `${d.name}, deals # ${d.olyxRefs.length}`
          : `${d.name}, deals # ${d.olyxRefs.length}, ${helpers.formatMoneyKMB(d.value / 100, "EUR")}`
      )
      .style("visibility", "hidden")
      .style("margin-left", "10px")
      .style("stroke", "transparent")
      .attr("pointer-events", "none");

    node
      .on("mouseover", (_, d) => {
        // {*commented out, want to look at transition error later*}
        // node
        //   .transition()
        //   .duration(200)
        //   .style("opacity", (node) =>
        //     d.olyxRefs.some((olyxRef) => node.olyxRefs.includes(olyxRef) || node.olyxRefs.some((olyxRef) => [...olyxRefsActive].includes(olyxRef))) ? 1 : 0.1
        //   );
        labels.style("visibility", (label) => (d === label ? "visible" : "hidden"));
      })
      .on("mouseout", () => {
        // {*commented out, want to look at transition error later*}
        // node
        //   .transition()
        //   .duration(200)
        //   .style("opacity", (node) => (node.olyxRefs.some((olyxRef) => [...olyxRefsActive].includes(olyxRef)) ? 1 : 0.4))
        //   .style("stroke", (node) => (clickedNodes.has(node.id) ? "black" : "transparent"));

        labels.style("visibility", "hidden");
      });

    node.on("click", (_, d) => {
      if (clickedNodes.has(d.id)) {
        olyxRefsActive = new Set();
        clickedNodes = new Set();
      } else {
        clickedNodes.add(d.id);
        d.olyxRefs.forEach(olyxRefsActive.add, olyxRefsActive);
      }

      // {*commented out, want to look at transition error later*}
      // node
      //   .transition()
      //   .duration(200)
      //   .style("opacity", (node) => (node.olyxRefs.some((olyxRef) => [...olyxRefsActive].includes(olyxRef)) ? 1 : 0.4))
      //   .style("stroke", (node) => (clickedNodes.has(node.id) ? "black" : "transparent"));

      // link
      //   .style("opacity", (link) =>
      //     [...olyxRefsActive].some((olyxRef) => link.source.olyxRefs.includes(olyxRef) && link.target.olyxRefs.includes(olyxRef)) ? 1 : 0.4
      //   )
      //   .attr("stroke-opacity", (link) =>
      //     [...olyxRefsActive].some((olyxRef) => link.source.olyxRefs.includes(olyxRef) && link.target.olyxRefs.includes(olyxRef)) ? 1 : 0.3
      //   );
    });

    node.exit().remove();

    simulation.on("tick", () => {
      node.attr("transform", function(d) {
        d.x = Math.max(10, Math.min(width - margin.left - margin.right - sqrtScale(d.value), d.x));
        d.y = Math.max(10, Math.min(graphHeight - margin.top - margin.bottom - sqrtScale(d.value), d.y));
        return "translate(" + [d.x, d.y] + ")";
      });

      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
    });
  }

  //when ref is populated, fire the function
  useEffect(() => {
    forceGraph(
      nodes.map((node) => ({ ...node, type: node.id.split("-")[1] })),
      links.map((link) => ({ ...link })),
      height,
      width,
      graphRef
    );
  }, [graphRef, height, width, nodes, links]);

  return (
    <>
      <div className="relative mx-auto bg-transparent h-full block">
        <div className={`mx-auto h-full relative border bg-white border-gray-light rounded-xl`} id="graph" ref={graphRef}></div>
      </div>
    </>
  );
}

export default NetworkGraph;
