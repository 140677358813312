import { useState } from "react";
import OAuth2Login from "react-simple-oauth2-login";
import olyxbase from "../../services/olyxbase";

const ExactLogin = () => {
  const [expirationDateRefresh, setExpirationDateRefresh] = useState<string>("");
  const [expirationDateAccess, setExpirationDateAccess] = useState<string>("");
  const redirectUri = process.env.REACT_APP_REDIRECTED_URI || "";
  const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID || "";

  olyxbase.getExpirationDate().then((res) => {
    setExpirationDateRefresh(res.body.refreshTokenExpDate);
    setExpirationDateAccess(res.body.accessTokenExpDate);
  });

  return (
    <div>
      <p>Refresh token expiration date: {new Date(expirationDateRefresh).toLocaleString()}</p>
      <p>Access token expiration date: {new Date(expirationDateAccess).toLocaleString()}</p>
      <div className="bg-[#2196f3] text-white rounded h-8 text-center w-24 p-1.5 shadow-inner">
        <OAuth2Login
          authorizationUrl="https://start.exactonline.nl/api/oauth2/auth"
          responseType="code"
          clientId={clientId}
          redirectUri={redirectUri}
          onSuccess={(e) => {
            olyxbase.saveExactCode(e.code);
          }}
          onFailure={(e) => console.log(e)}
          isCrossOrigin={false}
          extraParams={{ force_login: 1 }}
          buttonText={"Login Exact"}
        />
      </div>
      <button className="bg-[#2196f3] text-white rounded h-8 text-center w-46 p-2 shadow-inner" onClick={() => olyxbase.getAccessToken()}>
        Access Token
      </button>
    </div>
  );
};

export default ExactLogin;
