import React, { useMemo } from "react";
import "./AutoCompleteInput.css";
import { useField, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CssTextField from "./CssTextField";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

function AutoCompleteInput({ label, optionObjects, optionFieldsToDisplay, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  // const [field2, meta2, helpers2] = useField(props.conditionOn)
  const { values } = useFormikContext();

  // console.log("value autocompleteinpu")
  // console.log(value)
  //console.log(field)
  // console.log(field)

  //    const optionObjects = useMemo(() => optionObjects2, [optionObjects2])

  //        if (optionObjects) {

  // console.log(`OptionObjects: ${optionObjects}`)
  // console.log({...optionObjects})
  // console.log(optionFieldsToDisplay)

  // const optionObjectsMemo = useMemo( () => [...optionObjects, optionFieldsToDisplay
  //     // .filter( optionFieldToDisplay => optionFieldToDisplay !== "__typename" )
  //     .reduce(
  //     (acc,key) => {
  //         const emptyOption = {...acc};//, key: ""};
  //         emptyOption[key] = ""
  //         console.log(emptyOption)
  //         return(emptyOption)
  //         }
  //         ,{}
  //     )],[] )

  // create const options
  const options = useMemo(
    () =>
      // optionObjectsMemo
      [...optionObjects]
        .sort((first, second) =>
          props.sortingAlgorithm ? props.sortingAlgorithm(first, second) : props.sortByIdDesc ? second.id - first.id : first.id - second.id
        )
        // .sort((first, second) => (props.sortByIdDesc ? second.id - first.id : first.id - second.id))
        .filter((item) => {
          if (props.conditionOn && item.id && values[props.conditionOn + "Id"]) {
            return item[props.conditionOn].id.toString() === values[props.conditionOn + "Id"];
          }
          return item;
        })
        .filter((item) => (Object.keys(item).includes("isActive") && item.isActive === true) || !Object.keys(item).includes("isActive"))
        .map((item) => item.id.toString()),
    [values, props.conditionOn, optionObjects, props.sortByIdDesc] //optionObjectsMemo]
  );

  const getCustomOptionLabel = (option) => {
    // console.log("getOptionLabel")
    // console.log(option)
    //console.log(options)
    //    console.log(optionObjects)
    //    console.log(optionObjects.filter(item => item.id.toString() === option.toString()))

    const optionObj = optionObjects.filter((item) => item.id.toString() === option.toString());
    // console.log("optionObj")
    // console.log(optionObj)
    // console.log(optionFieldsToDisplay)
    return optionFieldsToDisplay
      .filter((field) => field !== "id")
      .reduce(
        (acc, field) =>
          optionObj[0] !== undefined //&& optionObj[0].length >= 1
            ? optionObj[0][field] === undefined || optionObj[0][field] === null || optionObj[field] === 0
              ? acc //'Choose an optionObj'
              : acc + optionObj[0][field].toString() + " "
            : "", //'Choose an option',//undef op[field]',
        ""
      );
  };

  //    const defVal = useMemo(() => optionFieldsToDisplay.reduce( (acc,item) => {
  //                const obj1 = {}
  //                obj1[item] = 0
  //                return({...acc, ...obj1})} ,{}),[])
  //
  //    useEffect(() => {
  //        console.log("useeffect autocompleteinput")
  //        setOptions([...options,defVal])
  //            },[defVal])

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {/* <label htmlFor={props.id || props.name}>{label}{props.required && "*"}</label> */}
        <Typography variant="body2">
          {label}
          {props.required && "*"}
        </Typography>
        <AutoComplete
          id={props.name}
          value={value === undefined ? "" : value.toString()}
          fullWidth={true}
          options={["", ...options]}
          //defaultValue={(value) => value}
          //getOptionLabel={(option) => {
          //    console.log(option)
          //    console.log(optionFieldsToDisplay)
          //    return(optionFieldsToDisplay
          //    .filter(field => field !== "id")
          //        .reduce((acc, field) => option[field] !== undefined ? option[field] === 0 ? "Choose an option" : acc + option[field].toString() + " " : "undef op[field]" ,""))}}
          getOptionLabel={(option) => getCustomOptionLabel(option)}
          // style={{ maxWidth: 300}}
          onChange={(event, value) => setValue(value !== null ? value : "")}
          // style={{style: {color: "red"}}}
          //{...field}
          {...props}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label={props.label}
              // label={"hardcoded label"}
              inputProps={{ ...params.inputProps, style: { color: "#22b0ff" } }}
              variant="outlined"
              color="primary"
            />
          )}
          renderOption={(option) => {
            //console.log("option")
            if (props.name === "importCountryId" || props.name === "exportCountryId") {
              // console.log(option)
              // console.log('countryflagcod3')
              const optionObj = optionObjects.filter((item) => item.id.toString() === option.toString());
              // console.log(optionObj)
              if (optionObj.length !== 0) {
                return (
                  <React.Fragment>
                    <span>{countryToFlag(optionObjects.filter((item) => item.id.toString() === option.toString())[0]["code"].toString())}</span>
                    {optionObj[0]["name"].toString()} ({optionObj[0]["code"].toString()})
                  </React.Fragment>
                );
              } else {
                return getCustomOptionLabel(option);
              }
            } else {
              return getCustomOptionLabel(option);
            }
          }}
          includeInputInList={true}
          autoSelect={true}
          autoComplete={true}
        />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </Grid>
    </>
  );

  //        } else {
  //            return(<>
  //                    <Grid item sm={4} xs={12}>
  //                    <div>No optionObjects provided</div>
  //                    </Grid>
  //                    </>
  //            )
  //        }
}

export default AutoCompleteInput;
