import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_USERS from "./query_users";

function useUsers(args) {
  const [users, setUsers] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_USERS, {
    notifyOnNetworkStatusChange: false,
    nextFetchPolicy: "standby",
    variables: {
      input: {
        fullName: args && args.fullname ? args.fullName : "",
        taggable: true,
      },
    },
  });

  useEffect(() => {
    setUsers(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting users");
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch]);

  return { users };
}

export default useUsers;
