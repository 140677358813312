import useTypedQuery from "../helpers/useTypedQuery";
import { BrokerData } from "../../interfaces/Broker";

const GET_BROKERS = `
  query getBrokers {
    brokers {
      id
      brokerName
    }
  }
`;

const useBrokers = () => useTypedQuery<BrokerData, undefined>(GET_BROKERS)?.dataFromQuery;

export default useBrokers;
