import React from "react";
// import styles from './CommissionCounter.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import useCommission from "../../hooks/useCommission";

function CommissionCounter(props) {
  const [commission, refetch] = useCommission({ currency: props.currency });
  console.log("commission");
  console.log(commission);
  console.log(props);

  const price = {
    productId: "id1",
    timestamp: new Date(),
    agent: "TO",
    price: 5,
    priceUsd: 50,
    info: "EUR",
    product: "Weekly Commission",
    outlined: "outlined",
    order: 0,
    priceHistory: [],
  };
  return (
    <div style={styles.root}>
      <div className={styles.base}>
        <Card
          key={props.currency}
          className={commission.commission > 0 ? "priceCard d-flex flex-column cardAgeStage1GreenBorder" : "priceCard d-flex flex-column cardAgeStage3"}
          raised={true}
          style={{ backgroundColor: "#142a41" }}
        >
          <CardContent style={{ height: "100%" }}>
            <Typography style={{ fontSize: 35, fontWeight: "bold" }} align="center">
              {price.product.toUpperCase()}
            </Typography>
            {commission && (
              <Typography style={{ fontSize: 35, marginTop: 0 }} align="center">
                {/* <div> */}
                {(commission.commission / 100).toLocaleString("en-US", { style: "currency", currency: props.currency })}
                {/* &nbsp; */}
                {/* </div> */}
                {/* <div> */}
                {/*   &nbsp; {(data.usd / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })} USD &nbsp; */}
                {/* </div> */}
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
const styles = {
  root: {
    paddingTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#115980",
  },
  control: {
    padding: 10,
  },
  contentWrapper: {
    padding: 20,
  },
  middlePriceTitle: {
    marginLeft: 10,
  },
  chartContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 220,
  },
  selectControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 350,
    marginTop: 3,
    zIndex: 99,
  },
  entryBar: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

// CommissionCounter.defaultProps = {};

// CommissionCounter.propTypes = {
// };

export default CommissionCounter;
