import React, { useState, useContext } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { sortedDataContext } from "../../contexts/TableContexts";

const Pagination = ({ ...props }) => {
  const context = useContext(sortedDataContext);
  const sortedData = context && context.sortedData ? context.sortedData : [];

  //set limit to amount of expRev's per page
  const numberOfItemsPerPage = 11;
  //get the max amount of pages
  const maxPageLimit = Math.ceil(sortedData.length / numberOfItemsPerPage);
  // get the min amount of pages
  const minPageLimit = 1;
  // // slice the array based on the exprev per page, to show the right expRev's per page
  const lastSlice = context.currentPage * numberOfItemsPerPage;
  const firstSlice = lastSlice - numberOfItemsPerPage;
  const paginatedData = [...sortedData].slice(firstSlice, lastSlice);

  // when page number is clicked set new active page
  const onPageChange = (e) => {
    context.setCurrentPage(Number(e.target.innerText));
  };

  // go one page back
  const onPrevClick = () => {
    if (context.currentPage === minPageLimit) {
      context.setCurrentPage(1);
    } else {
      context.setCurrentPage((prev) => prev - 1);
    }
  };

  // go one page forward
  const onNextClick = () => {
    if (context.currentPage === maxPageLimit) {
      context.setCurrentPage(maxPageLimit);
    } else {
      context.setCurrentPage((prev) => prev + 1);
    }
  };

  // get array with page numbers
  const pageNumbers = Array.from(Array(maxPageLimit).keys(), (x, i) => i + 1);

  return (
    <>
      <sortedDataContext.Provider value={paginatedData}>{props.children}</sortedDataContext.Provider>
      {maxPageLimit > 1 && (
        <div className="flex gap-4 mt-4 text-[#7E8085] ">
          <button className="hover:text-primary-blue" onClick={onPrevClick}>
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
          </button>
          {pageNumbers.map((pageNumber) => {
            return (
              <button
                key={pageNumber}
                className={`${pageNumber === context.currentPage ? "text-primary-blue" : "text-[#7E8085]"} hover:text-primary-blue`}
                onClick={onPageChange}
              >
                {pageNumber}
              </button>
            );
          })}
          <button className="hover:text-primary-blue" onClick={onNextClick}>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </button>
        </div>
      )}
    </>
  );
};

export default Pagination;
