import { gql } from "@apollo/client";

const GET_BROKERS = gql`
  query getBrokers {
    brokers {
      id
      brokerName
    }
  }
`;

export default GET_BROKERS;
