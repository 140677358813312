import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_DEALSTATISTICS from "./query_dealstatistics";

function useDealStatistics(args) {
  const [dealStatistics, setDealStatistics] = useState(false);

  const input = {
    brokerIds: args && args.brokerIds ? args.brokerIds : undefined,
    clientIds: args && args.clientIds ? args.clientIds : undefined,
    productIds: args && args.productIds ? args.productIds : undefined,
    olyxRefs: args && args.olyxRefs ? args.olyxRefs : undefined,
    after: args && args.after ? args.after : undefined,
    before: args && args.before ? args.before : undefined,
    dealInvoiceStatus: args && args.dealInvoiceStatus ? args.dealInvoiceStatus : undefined,
    hasOpenDealNote: args && args.hasOpenDealNote ? args.hasOpenDealNote : false,
  };

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_DEALSTATISTICS, {
    // notifyOnNetworkStatusChange: false,
    // nextFetchPolicy: "standby",
    // fetchPolicy: "cache-and-network",
    // pollInterval: 15000,
    variables: {
      input: input,
    },
  });

  useEffect(() => {
    setDealStatistics(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch]);

  return { dealStatistics, refetch, networkStatus, loading };
}

export default useDealStatistics;
