import React from "react";
import helpers from "../../services/helpers";
import { IcicleLabelProps } from "../../interfaces/ReportingIcicle";
import * as d3 from "d3";

const IcicleLabel = ({ leaf }: IcicleLabelProps) => {
  const sumValues = (leaf: any) => {
    if (leaf.data.type === "leafChild") {
      return helpers.formatMoneyKMB(leaf.data.value / 100, "EUR");
    }
    if ("category" in leaf.data) {
      return helpers.formatMoneyKMB(
        d3.sum(leaf.data.children, (d: any) => d.value / 100),
        "EUR"
      );
    }
    if (leaf.data.type === "node") {
      return helpers.formatMoneyKMB(leaf.value / 100, "EUR");
    }
    return null;
  };

  return (
    <div>
      <span className="font-bold text-white ml-1">{leaf.data.name}</span>
      <span className="font-light text-white text-xs ml-1">{sumValues(leaf)}</span>
    </div>
  );
};

export default IcicleLabel;
