import React, { useState } from "react";
import Button from "@material-ui/core/Button";

function ButtonNewInvoice() {
  const [invoiceBuyerCreated, setInvoiceBuyerCreated] = useState(false);
  const [invoiceSellerCreated, setInvoiceSellerCreated] = useState(false);
  return (
    <div>
      {!invoiceBuyerCreated && (
        <Button
          size="small"
          color="primary"
          onClick={() => {
            console.log("click");
            setInvoiceBuyerCreated(() => true);
          }}
        >
          Create Invoice Buyer
        </Button>
      )}
      <div></div>
      {invoiceBuyerCreated && <div>Invoice Buyer created</div>}
      {!invoiceSellerCreated && (
        <Button
          size="small"
          color="primary"
          onClick={() => {
            console.log("click");
            setInvoiceSellerCreated(() => true);
          }}
        >
          Create Invoice Seller
        </Button>
      )}
      {invoiceSellerCreated && <div>Invoice Seller created</div>}
    </div>
  );
}

export default ButtonNewInvoice;
