const CommentsCount = ({ count, title }: { count: number; title: string }) => {
  return (
    <div className="text-[#7E8085] bg-[#F3F4F6] px-2.5 rounded-md border border-[#E1E1E1] self-center pt-1 pb-0.5 leading-none text-xs">
      <span>
        {count} {title === "To do" && count === 200 ? "+" : (title === "In progress" || title === "Done") && count === 50 ? "+" : ""}
      </span>
    </div>
  );
};
export default CommentsCount;
