import { useMutation } from "@apollo/client";
import MUTATION_DEALNOTES from "./mutation_dealnotes";

function useMutationDealNotes(props) {
  const [updateDealNotes, { data, loading, error }] = useMutation(MUTATION_DEALNOTES, {
    variables: {
      input: {},
    },
    refetchQueries: ["Deal", "DealNotes", "DealsList", "OpenDealNoteCount", "DealNotesOverview"],
  });

  return { updateDealNotes, data, loading, error };
}

export default useMutationDealNotes;
