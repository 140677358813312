import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// import { spacing } from "@material-ui/system";
import CssTextField from "./CssTextField";
import NumberFormat from "react-number-format";
import helpers from "../../services/helpers";

function NumberFormatCustom(props) {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const { values } = useFormikContext();
  return (
    <NumberFormat
      value={value}
      className={props.className}
      style={props.style}
      isNumericString={true}
      onValueChange={(values, sourceInfo) => setValue(values.value)}
      thousandSeparator={true}
      prefix={getPrefix(field.name, values)}
    />
  );
}

const getPrefix = (fieldName, values) => {
  if (!fieldName || !values) {
    return "";
  }
  if (fieldName === "pricePerUnit" && values.currencyDealId) {
    return currencyIdToSymbol(values.currencyDealId);
  } else if (fieldName === "commissionSeller" && values.currencyCommissionSellerId) {
    return currencyIdToSymbol(values.currencyCommissionSellerId);
  } else if (fieldName === "commissionBuyer" && values.currencyCommissionBuyerId) {
    return currencyIdToSymbol(values.currencyCommissionBuyerId);
  }
  return "";
};

const currencyIdToSymbol = (currencyId) => {
  if (currencyId.toString() === "1") return "$";
  if (currencyId.toString() === "2") return "€";
  if (currencyId.toString() === "3") return "£";
  return "";
};

function TextInput({ label, ...props }) {
  const [field, meta] = useField(props);
  const { value } = meta;

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <div style={{ padding: 0 }}>
          <label htmlFor={props.id || props.name}>
            {label}
            {props.required && "*"}
          </label>
          <div />
          <div>
            <CssTextField
              // className="text-input"
              fullWidth={true}
              // style={{ maxWidth: 300 }}
              {...field}
              {...props}
              variant="outlined"
              inputProps={{ style: { color: "#22b0ff" } }}
              InputProps={{ inputComponent: NumberFormatCustom }}
            />
          </div>
          {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
      </Grid>
    </>
  );
}

export default TextInput;
