import React from "react";
import { useParams } from "react-router-dom";
import DealDetails from "../../components/DealDetails";
import "./MyDealsDetailPage.scss";

function MyDealsDetailPage() {
  let { dealId } = useParams();

  return (
    <>
      <DealDetails id={dealId} />
    </>
  );
}

export default MyDealsDetailPage;
