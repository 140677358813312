import React, { useEffect, useState } from "react";
import olyxbase from "../../services/olyxbase";
import ProductHistoryChart from "./ProductHistoryChart";
import { useQuery, useQueryClient } from "react-query";
import { Avatar } from "@material-ui/core";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { motion } from "framer-motion";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";

function ProductInfoModal(props) {
  let historyRouter = useHistory();
  const queryClient = useQueryClient();

  const [days, setDays] = useState(30);
  const [avatars, setAvatars] = useState([]);
  const [comments, setComments] = useState([]);

  let history = useQuery(["productHistory", props.productId, days], async () => {
    return olyxbase.getProductHistoryLong(props.productId, days);
  });

  useEffect(() => {
    queryClient.invalidateQueries("productHistory");
    queryClient.invalidateQueries("productHistory", props.productId, days);
  }, [props.productId]);

  useEffect(() => {
    if (history.data) {
      const uniqueAvatars = history.data.reduce((avatars, item) => {
        if (avatars.map((avatar) => avatar.id).includes(item.id)) {
          // avatar is already in uniqueAvatars, return unmodified accumulator avatars
          return avatars;
        } else {
          // append new item to uniqueAvatars
          return [...avatars, { id: item.id, fullName: item.fullName, imageUrl: item.imageUrl }];
        }
      }, []);
      setAvatars(uniqueAvatars);

      const commentist = history.data.filter((item) => item.extraInfo != "" && item.extraInfo != null && item.extraInfo != undefined).reverse();
      setComments(commentist);
    }
  }, [history.data]);

  useEffect(() => {}, [days]);

  return (
    <div className="w-full h-full p-2 overflow-hidden rounded-lg modal-product">
      <div className="m-2 modal-header">
        <motion.button whileHover={{ rotate: 90 }} whileTap={{ scale: 0.95 }} className="modal-button" onClick={() => props.setIsProductInfoModalOpen(false)}>
          <CloseIcon />
        </motion.button>
      </div>
      <div className="grid grid-cols-1 gap-8 p-8 pt-0 m-2 overflow-y-auto rounded-lg md:grid-cols-3 max-h-70vh modal-product">
        <div className="col-span-1 md:col-span-2 ">
          <h2 className="mb-2 text-2xl">
            {history && history.data && history.data[history.data.length - 1] ? history.data[history.data.length - 1].product : ""}{" "}
            {history && history.data && history.data[history.data.length - 1] ? history.data[history.data.length - 1].info : ""}
          </h2>
          <div className="p-4 bg-white rounded-lg">
            <div>
              <div className="flex flex-wrap gap-4">
                <button
                  className={`${days === 1 ? "bg-blue-100" : "bg-white"} link-deal px-4 py-1`}
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => setDays(1)}
                >
                  1D
                </button>
                <button
                  className={`${days === 7 ? "bg-blue-100" : "bg-white"} link-deal px-4 py-1`}
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => setDays(7)}
                >
                  1W
                </button>
                <button
                  className={`${days === 30 ? "bg-blue-100" : "bg-white"} link-deal px-4 py-1`}
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => setDays(30)}
                >
                  1M
                </button>
                <button
                  className={`${days === 365 ? "bg-blue-100" : "bg-white"} link-deal px-4 py-1 `}
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => setDays(365)}
                >
                  1Y
                </button>
              </div>
            </div>
            <div className="w-full">
              {history.isSuccess && history.data && history.data.length > 1 && (
                <motion.div key={days} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  <ProductHistoryChart data={history.data} />
                </motion.div>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-1 ">
          {" "}
          <h2 className="mb-2 text-2xl">Info</h2>
          <div className="p-4 bg-white rounded-lg">
            {" "}
            {history.data && history.data[history.data.length - 1] && (
              <table class="my-5 border-collapse">
                <tbody>
                  <tr>
                    <td>
                      <p class="label-text">Name </p>
                    </td>
                    <td>
                      <p>
                        {" "}
                        {history && history.data ? history.data[history.data.length - 1].product : ""}{" "}
                        {history && history.data ? history.data[history.data.length - 1].info : ""}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="label-text">Decimals </p>
                    </td>
                    <td>
                      <p> {history.data[history.data.length - 1].decimals}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="label-text">Tick Size </p>
                    </td>
                    <td>
                      <p> {history.data[history.data.length - 1].tickSize ?? "n/a"}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="label-text">Type </p>
                    </td>
                    <td>
                      <p>
                        {" "}
                        {history.data[history.data.length - 1].priceType == 2
                          ? "Auto"
                          : history.data[history.data.length - 1].inputCategory > 0
                          ? "BidAsk"
                          : "Normal"}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <div>
              <h2 className="mt-2 ">Broker:</h2>
              <div className="flex flex-wrap gap-4 mt-2 mb-10">
                {" "}
                {avatars.map((avatar, index) => (
                  <>
                    <div className="w-7 h-7">
                      {" "}
                      <AvatarTooltip
                        img={avatar && avatar.imageUrl ? avatar.imageUrl : ""}
                        broker={avatar && avatar.fullName ? avatar.fullName.replace(/ .*/, "") : ""}
                        hover={true}
                      ></AvatarTooltip>
                    </div>
                  </>
                ))}{" "}
              </div>
            </div>
          </div>
          <div className="h-auto mt-8">
            {" "}
            <h2 className="my-2 text-2xl">Comments</h2>
            <div className="p-4 mb-10 bg-white rounded-lg">
              <div>
                {comments.map((comment, index) => (
                  <motion.div className="comment-card" key={comment.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                    <div className="comment-card-content">
                      <div className="author">
                        <div style={{ margin: 10, float: "left", width: 45, height: 45 }}>
                          <AvatarTooltip
                            img={comment && comment.imageUrl ? comment.imageUrl : ""}
                            broker={comment && comment.fullName ? comment.fullName.replace(/ .*/, "") : ""}
                            hover={true}
                          ></AvatarTooltip>
                        </div>
                        <div>
                          <p className="author-name">{comment.fullName ? comment.fullName : ""}</p>
                          <span>
                            <Moment fromNow>{new Date(comment.timestamp ?? null)}</Moment>
                          </span>
                        </div>
                      </div>
                      <p>{comment.extraInfo}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfoModal;
