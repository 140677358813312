import { gql } from "@apollo/client";

const MUTATION_MARKINVOICESASDELETED = gql`
  mutation markInvoicesAsDeleted($ids: [ID!]!) {
    markinvoicesasdeleted(ids: $ids) {
      ... on InvoiceExact {
        id
        invoiceNumber
        orderNumber
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

export default MUTATION_MARKINVOICESASDELETED;
