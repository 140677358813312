import React from "react";
import helpers from "../../services/helpers";
import "moment-timezone";

function ExpRevChanges({ expRev, currentUserIsAdmin, showAllocatedFromParentInvoice }) {
  return (
    <>
      <div className="text-xs">
        <table className="my-15 small-table">
          <tbody>
            <tr>
              <td>
                <p title="Initial expected amount." class=" text-[#a6a6a6]">
                  Initial Expected
                </p>
              </td>
              <td>
                <p class="">{helpers.formatMoney(expRev.totalAmount ? expRev.totalAmount / 100 : "0", expRev.currency.description)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p title="Sum of all changes related with this expected revenue." class="text-[#a6a6a6] mr-12">
                  Changes
                </p>
              </td>
              <td>
                <p class="">{helpers.formatMoney(helpers.getTotalAmountOfChanges(expRev), expRev.currency.description)}</p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="td-long-child">
                <div class="w-full">
                  {expRev.expectedRevenueChanges && [
                    ...expRev.expectedRevenueChanges.map((expRevChange) => {
                      return (
                        <tr>
                          <td class="">
                            <p class="ml-6">{helpers.formatMoney(expRevChange.amount / 100, expRev.currency.description)}</p>
                          </td>
                          <td class="td-long-child">
                            <p class="">
                              <p class="text-xs">{expRevChange.reason ?? ""}</p>
                            </p>
                          </td>
                        </tr>
                      );
                    }),
                  ]}
                </div>
              </td>
            </tr>
            {showAllocatedFromParentInvoice && expRev?.allocatedFromParentInvoice && (
              <tr>
                <td>
                  <p title="Amount allocated to this e from this specific invoice." class=" text-[#a6a6a6]">
                    Allocated
                  </p>
                </td>
                <td>
                  <p class="">
                    {helpers.formatMoney(expRev?.allocatedFromParentInvoice ? expRev.allocatedFromParentInvoice / 100 : "0", expRev.currency.description)}
                  </p>
                </td>
              </tr>
            )}
            {(currentUserIsAdmin || props.showInvoiceAllocation) && "allocatedFromAllInvoices" in expRev && (
              <tr>
                <td>
                  <p title="Sum of all the amounts allocated from all invoices." class="text-[#a6a6a6]">
                    Allocated Total
                  </p>
                </td>
                <td>
                  <p class="">{helpers.formatMoney(expRev.allocatedFromAllInvoices / 100, expRev.currency.description)}</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ExpRevChanges;
