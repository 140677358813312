import React, { useState } from "react";

const BalanceModal = (props) => {
  return (
    <table className="w-[100%]">
      <thead>
        <tr>
          <th className="w-[25%]"></th>
          <th className="w-[50%]">
            <p className="text-xl text-white font-text text-center my-2">Balances</p>
          </th>
          <th className="w-[25%]">
            <p className="text-xl text-white font-text text-center my-2">Value in €</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p className="mb-2 text-xl text-white text-center font-text">Long</p>
          </td>
          <td>
            <div className="group bg-gray-200 h-4 rounded-r-full w-[100%] mb-2">
              <div className={`bg-green-500 h-4 rounded-r-full text-center`} style={{ width: props.barData.longProp + "%" }}></div>
            </div>
          </td>
          <td>
            <p className="text-xl text-white text-center font-text">{props.barData.long}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p className="mb-2  text-xl text-white text-center font-text">Short</p>
          </td>
          <td>
            <div className="group bg-gray-200 h-4 rounded-r-full w-[100%] mb-2">
              <div className={`bg-purple-400 h-4 rounded-r-full text-center`} style={{ width: props.barData.shortProp + "%" }}></div>
            </div>
          </td>
          <td>
            <p className="text-xl text-white text-center font-text">{props.barData.short}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p className="mb-2 text-xl text-white text-center font-text">€ Cash</p>
          </td>
          <td>
            <div className="group bg-gray-200 h-4 rounded-r-full w-[100%] mb-2">
              <div className={`bg-primary-blue h-4 rounded-r-full text-center`} style={{ width: props.barData.EURProp + "%" }}></div>
            </div>
          </td>
          <td>
            <p className="text-xl text-white text-center font-text">{props.barData.EUR}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BalanceModal;
