import React from "react";
// import { Field, useField, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";

function AutoCompleteInput(props) {
  const getCustomOptionLabel = (option) => {
    const optionObject = props.options.filter((propsOption) => propsOption.id === option)[0];
    return optionObject && optionObject.brokerName ? optionObject.brokerName : "";
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {/* <label htmlFor={props.id || props.name}> */}
        {/*   {props.label} */}
        {/*   {props.required && "*"} */}
        {/* </label> */}
        {/* <div> */}
        <AutoComplete
          id={props.name}
          value={props.value}
          fullWidth={true}
          options={props.options.map((option) => option.id)}
          getOptionLabel={(option) => getCustomOptionLabel(option)}
          style={{ maxWidth: 300 }}
          onChange={props.onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              // inputProps={{ ...params.inputProps }}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
          includeInputInList={true}
          autoSelect={true}
          autoComplete={true}
        />
        {/* </div> */}
      </Grid>
    </>
  );
}

export default AutoCompleteInput;
