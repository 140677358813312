import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CommentIcon from "@material-ui/icons/Comment";
import { ClapIcon } from "./icons";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import TextField from "@material-ui/core/TextField";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "70%",
    minWidth: 400,
    margin: "auto",
    marginBottom: 20,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  up: {
    backgroundColor: "green",
  },
  down: {
    backgroundColor: "red",
  },
  same: {
    backgroundColor: "lightblue",
  },
  actor: {
    fontSize: 12,

    marginLeft: 10,
  },
}));

export default function TimelinePostStandard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Avatar src={`${process.env.REACT_APP_API_URL}avatars/${props.timelineData.avatar}`}></Avatar>}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.timelineData.title}
        subheader={<Moment fromNow>{props.timelineData.timeago}</Moment>}
      />
      {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
      <Divider className={props.timelineData.direction == "up" ? classes.up : props.timelineData.direction == "down" ? classes.down : classes.same} />

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <b>{props.timelineData.title}</b>
          <div dangerouslySetInnerHTML={{ __html: props.timelineData.text }} />
        </Typography>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <ClapIcon />{" "}
          <span className={classes.actor} color={props.timelineData.direction == "up" ? "secondary" : ""}>
            APPLAUD
          </span>
        </IconButton>
        <IconButton aria-label="share">
          <CommentIcon /> <span className={classes.actor}>DISCUSS</span>
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon /> <span className={classes.actor}>SHARE</span>
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="James Test" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText primary="James Test" secondary={<React.Fragment>{"Awesome - Lets Go Party!"}</React.Fragment>} />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="James AnotherTest" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText primary="James AnotherTest" secondary={<React.Fragment>{"I'm too tired to party :("}</React.Fragment>} />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <TextField id="standard-basic" label="Add Your Comment.." />
            </ListItem>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}
