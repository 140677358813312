const helpersTimelineDealNotes = {};

helpersTimelineDealNotes.sortDealNotes = (a, b) => {
  if (!a && !b) {
    return 0;
  }
  if (!a || !("createdAt" in a) || !a.createdAt) {
    return 1;
  }
  if (!b || !("createdAt" in b) || !b.createdAt) {
    return -1;
  }
  if ("createdAt" in a && a.createdAt && "createdAt" in b && b.createdAt) {
    const bMinusA = new Date(b.createdAt) - new Date(a.createdAt);
    if (bMinusA > 0) {
      return 1;
    } else if (bMinusA < 0) {
      return -1;
    } else {
      // // check updatedAt instead
      // check id instead
      return parseInt(b.id) - parseInt(a.id);
    }
  }
  // currently not used
  if ("updatedAt" in a && a.createdAt && "updatedAt" in b && b.createdAt) {
    const bMinusA = new Date(b.updatedAt) - new Date(a.updatedAt);
    if (bMinusA > 0) {
      return 1;
    } else if (bMinusA < 0) {
      return -1;
    } else {
      // createdAt ===, updatedAt ===, just return default
      return -1;
    }
  }
  return -1;
};

export default helpersTimelineDealNotes;
