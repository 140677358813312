import React from "react";

const Feed = (props) => {
  return (
    <div className="sticky top-5">
      <h1 className="text-4xl text-center my-5">Recent Trades</h1>
      <div className="rounded-3xl bg-white shadow-xl drop-shadow-xl">
        <div className="overflow-auto max-h-[48rem] rounded-3xl">
          <table>
            <thead>
              <tr className="sticky top-0 bg-white">
                <th className="p-4 text-lg w-[16%]">
                  <b>Seller</b>
                </th>
                <th className="p-4 text-lg w-[4%]">
                  <b></b>
                </th>
                <th className="p-4 text-lg w-[16%]">
                  <b>Buyer</b>
                </th>
                <th className="p-4 text-lg w-[16%]">
                  <b>Product</b>
                </th>
                <th className="p-4 text-lg w-[16%]">
                  <b>Price</b>
                </th>
                <th className="p-4 text-lg w-[16%]">
                  <b>Volume</b>
                </th>
                <th className="p-4 text-lg w-[16%]">
                  <b>Time</b>
                </th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {props.feed.map((row, i) => (
                <tr className="border-t border-solid odd:bg-slate-100" key={i}>
                  <td className="p-2">
                    <img
                      className="m-auto w-12 h-12 rounded-full border border-black border-solid bg-gray-light"
                      alt="robohash of selling party"
                      src={`https://robohash.org/${row.seller}`}
                    />
                    <p className="text-black text-center font-text text-base">{props.aliasMap[row.seller] || "-"}</p>
                  </td>
                  <td className="py-2">
                    <p className="text-3xl text-center">→</p>
                  </td>
                  <td className="p-2">
                    <img
                      className="m-auto w-12 h-12 rounded-full border border-black border-solid bg-gray-light"
                      alt="robohash of buying party"
                      src={`https://robohash.org/${row.buyer}`}
                    />
                    <p className="text-black text-center font-text text-base">{props.aliasMap[row.buyer] || "-"}</p>
                  </td>
                  <td className="text-center font-text text-lg p-2">{row.product}</td>
                  <td className="text-center font-text text-lg p-2">
                    {row.currency_symbol}
                    {row.price}
                  </td>
                  <td className="text-center font-text text-lg p-2">{row.volume}</td>
                  <td className="text-center font-text text-lg p-4">{row.time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Feed;
