import React, { useState } from "react";

import BalanceTable from "./BalanceTable";
import PlayerAvatar from "./PlayerAvatar";
import { motion } from "framer-motion";
import BalanceModal from "./BalanceModal";

const PlayerCard = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // function to toggle modal
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <div>
        <button
          className={`w-[100%] py-4 mt-5 rounded-3xl shadow-xl drop-shadow-xl relative z-10
                                    bg-gradient-to-r ${props.fromColor} ${props.viaColor} ${props.toColor} 
                                    group hover:scale-[1.03] transition duration-300 overflow-hidden`}
          onClick={() => toggleModal()}
        >
          <div className="grid grid-cols-12">
            <div className="flex justify-center items-center col-span-4">
              <p className="text-5xl">{props.rank}</p>
            </div>
            <div className="col-span-4">
              <PlayerAvatar address={props.address} alias={props.alias} />
            </div>
            <div className="flex justify-center items-center col-span-2">
              <div className="text-4xl font-title">{props.weeklyBalance}</div>
            </div>
            <div className="flex justify-center items-center col-span-2">
              <div className="text-4xl font-title">{props.overallBalance}</div>
            </div>
          </div>
          <div
            className={`${props.visibility} absolute top-0 -inset-full h-full w-1/4 z-5 block transform -skew-x-12 
                       bg-gradient-to-r from-white to-white opacity-50 group-hover:animate-shine`}
          />
        </button>
      </div>

      {modalIsOpen && (
        <motion.div
          onClick={() => {
            toggleModal();
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="modal-overlay"
        >
          <div className="modal-content relative z-0">
            <div className="mx-auto rounded-b-3xl bg-zinc-700 w-[94%] drop-shadow-xl p-3">
              <BalanceModal barData={props.barData}></BalanceModal>
              <BalanceTable data={props.assetData} />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default PlayerCard;
