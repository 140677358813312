import { useMutation } from "@apollo/client";
import CREATE_EXP_REV_CHANGE from "./mutation_expectedrevenuechange";

const useCreateExpectedRevenueChange = () => {
  const [createExpRevChange] = useMutation(CREATE_EXP_REV_CHANGE, {
    refetchQueries: ["Deal", "PaymentPerformanceInEur"],
  });

  return (input) => createExpRevChange(input);
};

export default useCreateExpectedRevenueChange;
