import React, { useState } from "react";
import AddFormProduct from "../../components/AddFormProduct/AddFormProduct";
import { withRouter } from "react-router-dom";
import "./AddProductPage.css";
import ProductList from "../../components/Sandbox/ProductList";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

function AddProductPage() {
  const [searchInput, setSearchInput] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  return (
    <div style={styles.root}>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={3}>
          <div style={styles.contentWrapper}>
            <ProductList searchInput={searchInput} />
            <p>If the product is not listed in the above list, you can add it by using the form below. Please be careful not to add double entries.</p>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div style={styles.contentWrapper}>
            <div style={styles.fieldWrapper}>
              <TextField
                type="text"
                placeholder="Search product"
                onChange={handleChange}
                value={searchInput}
                fullWidth={true}
                // style={{ width: 300 }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <div>
            <AddFormProduct newInput={true} searchInput={searchInput} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(AddProductPage);

const styles = {
  root: {
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#115980",
  },
  contentWrapper: {
    padding: 40,
  },
  fieldWrapper: {
    padding: 20,
    paddingTop: 20,
    "text-align": "center",
    textAlign: "center",
  },
  custom1: {
    padding: 100,
  },
};
