import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { QueryDealNotesOverviewInput, DealnotesoverviewQuery, DealNotesOverview, Exact } from "../../codegen/gql/graphql";

const GET_DEALNOTESOVERVIEW = graphql(`
  query dealnotesoverview($input: QueryDealNotesOverviewInput!) {
    dealnotesoverview(input: $input) {
      dealNotesTodo {
        id
        createdAt
        createdBy {
          id
          fullName
          imageUrl
        }
        note
        inProgressAt
        inProgressBy {
          id
          fullName
          imageUrl
        }
        doneAt
        doneBy {
          id
          fullName
          imageUrl
        }
        status
        deal {
          id
          olyxRef
        }
        mentions {
          userInfo {
            id
            userUid
          }
        }
      }
      dealNotesInProgress {
        id
        createdAt
        createdBy {
          id
          fullName
          imageUrl
        }
        note
        inProgressAt
        inProgressBy {
          id
          fullName
          imageUrl
        }
        doneAt
        doneBy {
          id
          fullName
          imageUrl
        }
        status
        deal {
          id
          olyxRef
        }
        mentions {
          userInfo {
            id
            userUid
          }
        }
      }
      dealNotesDone {
        id
        createdAt
        createdBy {
          id
          fullName
          imageUrl
        }
        note
        inProgressAt
        inProgressBy {
          id
          fullName
          imageUrl
        }
        doneAt
        doneBy {
          id
          fullName
          imageUrl
        }
        status
        deal {
          id
          olyxRef
        }
        mentions {
          userInfo {
            id
            userUid
          }
        }
      }
    }
  }
`);

const useDealNotesOverview = (input: QueryDealNotesOverviewInput) =>
  useGeneratedTypeQuery<DealnotesoverviewQuery, QueryDealNotesOverviewInput>(GET_DEALNOTESOVERVIEW, input);

export default useDealNotesOverview;
