import React, { useState } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import useComponentVisible from "../../typedHooks/useComponentVisible";

//pickUpDealNote is plain js, kept saying that the expression is not callable
function IssueStatusButton({ status, pickUpDealNote, id }: { status: string; pickUpDealNote: any; id: number }) {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const pickedUpStatus: Variants = {
    TODO: { backgroundColor: "#dc2334", scale: 1 },
    INPROGRESS: { backgroundColor: "rgb(255 255 255)", scale: 1, borderColor: "#22b0ff" },
    DONE: { backgroundColor: "#22b0ff", scale: 2 },
  };

  const animateIn = {
    pathLength: 1,
    pathOffset: 0,
    opacity: 1,
  };
  const animateOut = {
    pathLength: 0,
    pathOffset: 0,
    opacity: 0,
  };

  const transition = {
    duration: 0.7,
  };

  return (
    <div
      ref={ref}
      onClick={() => {
        if (!isComponentVisible) setIsComponentVisible(!isComponentVisible);
      }}
      className="relative"
    >
      <motion.button
        animate={status}
        variants={pickedUpStatus}
        className="absolute -top-0 -left-0 bg-white w-2.5 h-2.5 rounded-full border border-white"
        onClick={() => {
          setOpenPopUp(true);
        }}
      >
        <motion.svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className="checkmark">
          <motion.path
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
            fill="none"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="white"
            strokeDasharray="0 1"
            initial={{ pathLength: 1, pathOffset: 0, opacity: 1 }}
            animate={status === "DONE" ? animateIn : animateOut}
            transition={transition}
          />
        </motion.svg>
      </motion.button>
      <AnimatePresence>
        {openPopUp && isComponentVisible && status !== "DONE" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute -top-1.5 -left-1 bg-white  p-2 px-2.5 rounded-md border border-[#E1E1E1] z-[60] w-32 shadow-sm	"
          >
            <span className="block ml-1 text-xs">Move to:</span>
            {status === "TODO" && (
              <button
                className="flex gap-2 mt-1 bg-white hover:bg-[#E1E1E1] rounded-md px-1 w-full"
                onClick={() => {
                  pickUpDealNote({ variables: { id: id, status: "INPROGRESS" } });
                  setOpenPopUp(false);
                }}
              >
                {" "}
                <motion.button
                  animate={"INPROGRESS"}
                  variants={pickedUpStatus}
                  className="bg-white w-3 h-3 rounded-full border border-white mt-1"
                ></motion.button>
                In progress
              </button>
            )}
            <button
              className="flex gap-2 mt-1 bg-white hover:bg-[#E1E1E1] rounded-md px-1 w-full"
              onClick={() => {
                pickUpDealNote({ variables: { id: id, status: "DONE" } });
              }}
            >
              {" "}
              <motion.button animate={"DONE"} variants={pickedUpStatus} className="bg-white w-1.5 h-1.5 rounded-full ml-1 mt-1.5 mr-1">
                <motion.svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className="checkmark">
                  <motion.path
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    fill="none"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="white"
                    strokeDasharray="0 1"
                    initial={animateIn}
                    transition={transition}
                  />
                </motion.svg>
              </motion.button>
              Done
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default IssueStatusButton;
