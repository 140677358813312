export const BarplotTodayLine = ({ xScale, data }) => {
  const xPosition = xScale(data.cumulative) + xScale(data.expectedRevenue) + 10;

  return (
    <>
      {data.label.length > 0 && (
        <div className="absolute top-4 text-gray-darker z-10" style={{ left: xPosition + "px" }}>
          <span className="text-title absolute -top-0 -left-7">Today</span>
          <div className="stroke-2 w-[10px] h-[100px] top-[20px] border-l-[3px] border-[#8dd7ff] border-dashed	absolute -right-[0px] z-[99999999999999999]" />
        </div>
      )}
    </>
  );
};

export default BarplotTodayLine;
