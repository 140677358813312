import { useMutation } from "@apollo/client";
import { graphql } from "../../../src/codegen/gql";

const CREATE_OR_UPDATE_INVOICES_ON_EXP_REV = graphql(`
  mutation createorupdateinvoicesonexpectedrevenue($input: CreateOrUpdateInvoicesOnExpectedRevenueInput!) {
    createorupdateinvoicesonexpectedrevenue(input: $input) {
      ... on Error {
        errorCode
        message
      }
      ... on InvoicesOnExpectedRevenue {
        invoiceAmount
        invoice {
          id
          invoiceNumber
          description
          totalAmount
          totalAmountEur
          dateInvoice
          dateDue
          dateEntry
          dateOrder
          orderNumber
          allocationStatus
          allocatedToParentExpectedRevenue
          allocatedToAllExpectedRevenue
          idExact
          vatAmountDc
          vatAmountFc
          createdAt
          updatedAt
          isDeleted
          invoiceStatusSummary
          expectedRevenue {
            id
            totalAmount
            dateExpected
            allocatedFromParentInvoice
            paidEur
            totalAmountPlusChanges
            invoiceSumEurAllocatedToExpectedRevenue
            invoiceStatusSummary
          }
        }
        assignedAt
        expectedRevenue {
          id
          totalAmount
        }
      }
    }
  }
`);

const useCreateOrUpdateInvoicesOnExpRev = () => {
  const [updateExpectedRevenue] = useMutation(CREATE_OR_UPDATE_INVOICES_ON_EXP_REV);

  return updateExpectedRevenue;
};

export default useCreateOrUpdateInvoicesOnExpRev;
