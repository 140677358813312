import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

const NavigationButtons = ({
  additionalStyle,
  navigateBack,
  navigateForward,
}: {
  additionalStyle: string;
  navigateBack: () => void;
  navigateForward: () => void;
}) => {
  return (
    <div>
      <div className={`flex gap-2 mb-5 ml-2 lg:justify-start ${additionalStyle}`}>
        <div>
          <button
            className="bg-white rounded-full cursor-pointer"
            onClick={() => {
              navigateBack();
            }}
          >
            <NavigateBeforeIcon />
          </button>
        </div>
        <div>
          <button
            className="ml-4 bg-white rounded-full cursor-pointer"
            onClick={() => {
              navigateForward();
            }}
          >
            <NavigateNextIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavigationButtons;
