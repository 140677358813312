import Button from "@material-ui/core/Button";
import EditIcon from "@mui/icons-material/Edit";
import { motion } from "framer-motion";
import "moment-timezone";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AddFormDealNote from "../../components/AddFormDealNote";
import { auth } from "../../firebase";
import useDeal from "../../hooks/useDeal";
import helpers from "../../services/helpers";
import DealConfirmation from "../DealConfirmation/DealConfirmation";
import ExpRevList from "../ExpRevList";
import AddDealModal from "../Modals/AddDealModal";
import SingleDealNote from "../SingleDealNote/SingleDealNote";
import helpersTimelineDealNotes from "../TimelineDealNotes/helpersTimelineDealNotes";
import DealDetailCard from "./DealDetailsCard.js";

const sortDealNotes = helpersTimelineDealNotes.sortDealNotes;

function DealDetails(props) {
  const history = useHistory();
  const queryParams = queryString.parse(window.location.search);
  const [deal] = useDeal({ dealId: props && props.id ? props.id : null });
  const [showAddDealModal, setShowAddDealModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(auth ? auth.currentUser : false);
  const [adminClaim, setAdminClaim] = useState(false);
  const [adminTurnedOff, setAdminTurnedOff] = useState(false);

  const getInitialValues = helpers.getInitialValuesDeal;

  const expectedRevenueAgainstInvoices =
    deal.deals && deal.deals.length === 1 && deal.deals[0] !== null && deal.deals[0].expectedRevenueAgainstInvoices
      ? deal.deals[0].expectedRevenueAgainstInvoices
      : undefined;

  const expectedRevenueAgainstInvoicesSell =
    expectedRevenueAgainstInvoices && expectedRevenueAgainstInvoices.length > 0
      ? expectedRevenueAgainstInvoices.find((el) => el?.tradeSide === "SELL")
      : undefined;

  const expectedRevenueAgainstInvoicesBuy =
    expectedRevenueAgainstInvoices && expectedRevenueAgainstInvoices.length > 0
      ? expectedRevenueAgainstInvoices.find((el) => el?.tradeSide === "BUY")
      : undefined;

  const confirmationSell =
    deal.deals && deal.deals[0] && deal.deals[0].seller && deal.deals[0].dealConfirmationSell
      ? {
          clientName: deal.deals[0].seller.companyName,
          confirmation: deal.deals[0].dealConfirmationSell ?? undefined,
        }
      : undefined;

  const confirmationBuy =
    deal.deals && deal.deals[0] && deal.deals[0].buyer && deal.deals[0].dealConfirmationBuy
      ? {
          clientName: deal.deals[0].buyer.companyName,
          confirmation: deal.deals[0].dealConfirmationBuy ?? undefined,
        }
      : undefined;

  const handleCloseForm = () => {
    setShowAddDealModal(false);
    history.replace({ search: queryString.stringify({ openModal: "false" }) });
  };

  useEffect(() => {
    if (deal && "deals" in deal && deal.deals[0].dealStatus.id === "4" && queryParams?.openModal === "true") {
      setShowAddDealModal(true);
    } else if (deal && "deals" in deal && deal.deals[0].dealStatus.id !== "4") {
      handleCloseForm();
    }
  }, [queryParams?.openModal, deal]);

  useEffect(() => {
    if (process.env.REACT_APP_GRAPHQL_LINK === "http://localhost:4000/") {
      setAdminClaim(true);
    } else if (currentUser) {
      const idTokenResult = currentUser.getIdTokenResult().then((idTokenResult) => {
        setAdminClaim(idTokenResult.claims.admin ?? false);
      });
    }
  }, [currentUser]);

  const slashMotion = {
    rest: { scale: 1, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: {
      scale: 1,
      rotate: 20,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  return (
    <>
      <div className="p-5 md:p-10 mx-auto">
        {adminClaim && (
          <Button
            type="submit"
            onClick={() => setAdminTurnedOff(!adminTurnedOff)}
            variant="contained"
            color="primary"
            style={{ color: "#ffffff", marginBottom: "1.5rem" }}
          >
            {adminTurnedOff ? "turn on admin" : "turn off admin"}
          </Button>
        )}
        <DealDetailCard deal={deal && deal.deals && deal.deals.length === 1 && deal.deals[0] !== null ? deal.deals[0] : null}></DealDetailCard>
        <div className="flex flex-wrap justify-between w-full mt-5 xl:gap-10 xl:flex-nowrap">
          <div className="w-full">
            <div className="w-full p-4 mb-10 bg-white invoice-card rounded-2xl">
              {deal && "deals" in deal && deal.deals[0].dealStatus.id === "4" && (
                <>
                  <AddDealModal
                    open={showAddDealModal}
                    handleClose={() => {
                      handleCloseForm();
                    }}
                    initialValues={getInitialValues(deal.deals[0])}
                    closeAfterSubmit={() => {
                      handleCloseForm();
                    }}
                  />
                  <motion.button
                    initial="rest"
                    whileHover="hover"
                    animate="rest"
                    className={
                      "rounded ml-4 p-2 text-primary-blue underline underline-offset-4 decoration-1 opacity-90 hover:text-gray-dark text-sm ease-in-out duration-300"
                    }
                    onClick={() => {
                      setShowAddDealModal(true);
                    }}
                  >
                    Update deal information
                    <motion.button variants={slashMotion} className="transform">
                      <EditIcon className="pb-2" />
                    </motion.button>
                  </motion.button>
                </>
              )}
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {expectedRevenueAgainstInvoicesSell && (
                  <ExpRevList deal={deal.deals[0]} expRevAgainstInv={expectedRevenueAgainstInvoicesSell} currentUserIsAdmin={adminClaim && !adminTurnedOff} />
                )}
                {expectedRevenueAgainstInvoicesBuy && (
                  <ExpRevList deal={deal.deals[0]} expRevAgainstInv={expectedRevenueAgainstInvoicesBuy} currentUserIsAdmin={adminClaim && !adminTurnedOff} />
                )}
              </div>
            </div>
          </div>
          <div className="w-full xl:w-2/5 title flex flex-wrap gap-y-5 content-start">
            <div className="w-full relative self-start">
              <aside className="-rotate-90 absolute top-[148px] -left-[28px]">
                <h1 className="bg-[#86CEF3] w-[132px] h-[28px] absolute top-0 whitespace-nowrap rounded-tl-[6px] rounded-tr-[6px] px-[4px] py-[8px] pt-[10px] text-[14px] text-center leading-none text-white">
                  Deal checklist
                </h1>
              </aside>
              <div className="w-full h-full min-h-[160px] flex flex-wrap gap-y-[16px] px-[16px] py-[24px] bg-white rounded-xl flex-col">
                {confirmationSell && <DealConfirmation clientName={confirmationSell.clientName} confirmation={confirmationSell.confirmation} />}
                {confirmationBuy && <DealConfirmation clientName={confirmationBuy.clientName} confirmation={confirmationBuy.confirmation} />}
                {!confirmationBuy && !confirmationSell && (
                  <div class="bg-gray-100 px-4 py-8 rounded-lg text-center">
                    <span className="text-lg text-center text-gray-400">No pending items</span>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full relative h-fit">
              <aside className="-rotate-90 absolute top-[148px] -left-[28px]">
                <h1 className="bg-[#86CEF3] w-[132px] h-[28px] absolute top-0 whitespace-nowrap rounded-tl-[6px] rounded-tr-[6px] px-[4px] py-[8px] pt-[10px] text-[14px] text-center leading-none text-white">
                  Open comments
                </h1>
              </aside>
              <div className="w-full comment-block-deal-detail rounded-2xl">
                <AddFormDealNote initialValues={{ dealId: props.id ?? null, dateAction: undefined }} />
                <div className="w-full ml-auto comment-block h-fit">
                  <div className="timeline-container-scroll">
                    {deal &&
                      deal.deals &&
                      deal.deals.length === 1 &&
                      deal.deals[0].dealNotes &&
                      [...deal.deals[0].dealNotes].sort(sortDealNotes).map((dealNote, index) => <SingleDealNote dealNote={dealNote} key={dealNote.id} />)}
                    {deal && deal.deals && deal.deals.length === 1 && deal.deals[0].dealNotes.length < 1 && (
                      <div class="bg-gray-100 px-4 py-8 rounded-lg text-center mt-10">
                        <span className="text-lg text-center text-gray-400">Be the first to leave a comment on this deal</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DealDetails;
