import React, { useState } from "react";
// import styles from './SelectDeal.css';
import { Formik, Form } from "formik";
import AutoCompleteInput from "../FormFields/AutoCompleteInput";
import { gql, useQuery } from "@apollo/client";
// import AddFormGeneric from "../../components/AddFormGeneric/AddFormGeneric";
import Button from "@material-ui/core/Button";

const QUERY_DEAL = gql`
  query Deal($dealStatusId: ID) {
    deals(dealStatusId: $dealStatusId) {
      id
      olyxRef
      description
    }
  }
`;
// const QUERY_DEAL_SINGLE = gql`
//     query Deal($id: String!) {
//         deals(id: $id) {
//             id
//             olyxRef
//             description
//         }
// }
// `;

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});

function SelectDeal(props) {
  const { loading, error, data, refetch } = useQuery(QUERY_DEAL, {
    variables: { dealStatusId: "4" },
    pollInterval: 1500,
  }); //, {variables: { olyxRef: "101" } })

  // const singleDeal = useQuery(QUERY_DEAL_SINGLE, {variables: { id: "5374" } })
  // const singleDeal = props.singleDeal;
  // const [singleDeal, setSingleDeal] = useState(props.singleDeal);
  const refetchSingle = props.singleDeal.refetch;

  // useEffect( () => {
  //     console.log("singleDeal.data")
  //     // console.log(singleDeal.data)
  //     console.log(props.singleDeal.data)
  //     setSingleDeal(props.singleDeal)
  // },[props.singleDeal] )
  // if (data){
  //     console.log("DATA")
  //     console.log(data)
  // }
  // if (singleDeal){
  //     console.log("DATA singleDeal")
  //     console.log(singleDeal.data)
  // }

  // const formField ={name: "olyxRef", label: "Olyx Ref Number", rank: 1, fieldType:"text", yupType: "integer", required: true, defaultValue: "0",};
  const formFields = [
    {
      name: "olyxRef",
      label: "Olyx Ref Number",
      rank: 1,
      fieldType: "select",
      yupType: "integer",
      required: true,
      defaultValue: "",
      optionObjects: "deals",
      optionFieldsToDisplay: ["id", "olyxRef", "description"],
    },
  ];
  const formField = formFields[0];

  const [initialValues, setInitialValues] = useState(() => (props.initialValues ? props.initialValues : getInitialValues(formFields)), []);

  return (
    <div style={styles.root}>
      <h3>Select a deal you'd like to edit</h3>
      {data && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            // const {data}=useQuery(QUERY_DEAL,{ variables: { olyxRef: "101" }} )
            console.log("onsubmit");
            console.log(values);
            // singleDeal.refetch({variables: { olyxRef: "102" }})
            refetchSingle({ id: values.olyxRef });
            console.log("submitted");
            //
            // const result = singleDeal.refetch({variables: { olyxRef: "102" }})
            // console.log(result)
          }}
        >
          <Form>
            {/* <Grid container spacing={3}> */}
            {data && (
              <AutoCompleteInput
                id={formField.name}
                label={formField.label}
                name={formField.name}
                optionObjects={[...data[formField.optionObjects]]}
                optionFieldsToDisplay={[...formField.optionFieldsToDisplay]}
                required={formField.required}
                conditionOn={formField.conditionOn ? formField.conditionOn : undefined}
                sortByIdDesc={true}
                style={{ width: 300 }}
              />
            )}
            {/* </Grid> */}
            {/* <Grid item sm={12} xs={12}> */}
            <div style={styles.contentWrapper}>
              {data && (
                <Button type="submit" color="secondary" variant="outlined">
                  Complete this deal
                </Button>
              )}
            </div>
            {/* </Grid> */}
          </Form>
        </Formik>
      )}
    </div>
  );
}

SelectDeal.defaultProps = {};

SelectDeal.propTypes = {};

const styles = {
  root: {
    // paddingTop: 30,
    // paddingLeft: 40,
    // paddingRight: 40,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // width: '100%',
    // backgroundColor: '#115980'
    backgroundColor: "#1f3a56",
    marginLeft: -10,
    padding: 10,
  },
  contentWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
};

export default SelectDeal;
