import React from "react";
import Marquee from "react-fast-marquee";
import PropTypes from "prop-types";
import TickerPriceChange from "../Ticker/TickerPriceChange";

function Ticker(props) {
  let items = props.items;

  return (
    <div style={{ width: "100%", marginTop: -20, backgroundColor: "white" }}>
      <Marquee pauseOnHover={true} speed={20} gradient={false} style={{ boxShadow: "0 4px 2px -2px gray" }}>
        {items !== undefined &&
          items.map((item) => {
            return <TickerPriceChange tickerData={item} />;
          })}
      </Marquee>
    </div>
  );
}

Ticker.propTypes = {
  user: PropTypes.object,
};

export default Ticker;
