import { useState, useEffect } from "react";
// import { gql, useQuery, InMemoryCache } from "@apollo/client";
import { useQuery } from "@apollo/client";
import GET_COMMISSION from "./query_commission";

function useCommission(props) {
  const [commission, setCommission] = useState(false);

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(GET_COMMISSION, {
    // notifyOnNetworkStatusChange: false,
    // nextFetchPolicy: "standby",
    variables: { currency: props && props.currency ? props.currency.toString() : "EUR" },
  });

  useEffect(() => {
    // refetch()
    console.log("useEffect useCommission");
    // console.log(networkStatus);
    console.log(data);
    console.log(loading);
    setCommission(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting commission");
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch, fetchMore, networkStatus]);

  return [commission, refetch, networkStatus, fetchMore, loading, error];
  // return [data, refetch, networkStatus];
  // return ({ loading, error, data, refetch, networkStatus })
}

export default useCommission;
