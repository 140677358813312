import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function BooleanInput({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  // const { value } = meta;
  // const { setValue } = helpers;
  // const { values } = useFormikContext();

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Typography variant="body2">
          {label}
          {props.required && "*"}
        </Typography>
        <Checkbox
          {...field}
          checked={meta && meta.value ? meta.value : false}
          inputProps={{ "aria-label": "primary checkbox" }}
          id={props.name}
          color="primary"
        />
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </Grid>
    </>
  );
}

export default BooleanInput;
