import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
// import VirtualizedList from './../Sandbox/VirtualizedList';
import PinnedSubHeaderList from "./../Sandbox/PinnedSubHeaderList";
import useProduct from "../../hooks/useProduct";

// import styles from './ProductList.css';
const styles = {};

function ProductList(props) {
  const [data, refetch] = useProduct();

  const [dataFormatted, setDataFormatted] = useState([]);
  const [categories, setCategories] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data && data.products) {
      const dataNew = [...data.products];
      dataNew.sort((firstEl, secondEl) => {
        return parseInt(firstEl.category.id) - parseInt(secondEl.category.id);
      });
      console.log("dataNew");
      console.log(dataNew);
      setDataFormatted(() => dataNew);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.products) {
      // const categoriesNew = [ {id:1, name: "cat1"}, {id:2, name: "cat2"},
      // {id:3, name: "cat3"}, {id:4, name: "cat4"}, {id:5, name: "cat5"}, {id:6, name: "cat6"} ]
      const categoriesNew = data.products.map((product) => product.category).filter((value, index, self) => self.indexOf(value) === index);
      setCategories(() => categoriesNew);
    }
  }, [data]);

  useEffect(() => {
    let listItems;
    if (dataFormatted.length > 0 && props.searchInput !== "") {
      listItems = dataFormatted.filter((data) => data.name.toLowerCase().match(props.searchInput.toLowerCase()));
    }
    if (dataFormatted.length > 0 && props.searchInput === "") {
      listItems = [...dataFormatted];
    }
    setList(() => listItems);
  }, [dataFormatted, props.searchInput]);

  return (
    <div className={styles.base}>
      <h3>Products</h3>
      {list && categories && <PinnedSubHeaderList data={list} categories={categories} />}
    </div>
  );
}

ProductList.defaultProps = {};

ProductList.propTypes = {};

export default ProductList;
