import { gql } from "@apollo/client";

import eachYearOfInterval from "date-fns/eachYearOfInterval";
import addYears from "date-fns/addYears";
import startOfYear from "date-fns/startOfYear";
import format from "date-fns/format";

const startDate = startOfYear(new Date("2019-01-01"));
const endDate = new Date();

const years = [...eachYearOfInterval({ start: startDate, end: endDate })];
const paidEurPerYear = years.reduce((acc, year) => {
  const yearAfter = year;
  const yearBefore = addYears(year, 1);
  // prettier-ignore
  const finalString = `paidEur${format(year, "yyyy")}:
paidEur(dateInvoiceAfter: "${format(yearAfter, "yyyy-MM-dd")}", dateInvoiceBefore: "${format(yearBefore, "yyyy-MM-dd")}", numberOfClients: 10) {
  ... on TotalSumAndClients {
    clients {
      client {
        id
        companyName
      }
      sum
    }
    totalSum
  }
  ... on Error {
    errorCode
    message
  } }`;
  return acc + finalString + "\n";
}, "");

const GET_PAYMENTPERFORMANCEINEUR = gql`
  query PaymentPerformanceInEur($input: QueryPaymentPerformanceInput!) {
    paymentperformanceineur(input: $input) {
      expectedAfter
      expectedBefore
      ${paidEurPerYear}
      expectedAmountEur(numberOfClients: 10) {
        ... on TotalSumAndClients {
          clients {
            client {
              id
              companyName
            }
            sum
          }
          totalSum
        }
        ... on Error {
          errorCode
          message
        }
      }
      paidEur: paidEur(numberOfClients: 10) {
        ... on TotalSumAndClients {
          clients {
            client {
              id
              companyName
            }
            sum
          }
          totalSum
        }
        ... on Error {
          errorCode
          message
        }
      }
      overdue: dueEur(minDays: 1, maxDays: 90, numberOfClients: 10) {
        ... on TotalSumAndClients {
          clients {
            client {
              id
              companyName
            }
            sum
          }
          totalSum
        }
        ... on Error {
          errorCode
          message
        }
      }
      overdue90: dueEur(minDays: 91, maxDays: 10000, numberOfClients: 10) {
        ... on TotalSumAndClients {
          clients {
            client {
              id
              companyName
            }
            sum
          }
          totalSum
        }
        ... on Error {
          errorCode
          message
        }
      }
      due: dueEur(minDays: -1000, maxDays: 0, numberOfClients: 10) {
        ... on TotalSumAndClients {
          clients {
            client {
              id
              companyName
            }
            sum
          }
          totalSum
        }
        ... on Error {
          errorCode
          message
        }
      }
    }
  }
`;

export default GET_PAYMENTPERFORMANCEINEUR;
