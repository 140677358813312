import React, { useState } from "react";
import helpers from "./helpers.js";
import { EUR } from "./constants";

const BalanceTable = (props) => {
  // sort assets based on absolute magnitude of Value, place 0-Value last
  let totalValue = props.data[EUR]["Value"];
  let assetItems = Object.keys(props.data)
    .filter(function(asset) {
      return asset !== EUR;
    })
    .map(function(asset) {
      totalValue += props.data[asset]["Value"];
      return [asset, props.data[asset]];
    });

  assetItems.sort(function(a, b) {
    if (a[1]["Value"] == b[1]["Value"]) {
      if (a[1]["Product"] < b[1]["Product"]) {
        return -1;
      } else {
        return 1;
      }
    } else if (a[1]["Value"] > b[1]["Value"]) {
      if (a[1]["Value"] < 0) {
        return 1;
      } else {
        return -1;
      }
    } else if (a[1]["Value"] < b[1]["Value"]) {
      if (b[1]["Value"] < 0) {
        return -1;
      } else {
        return 1;
      }
    }
  });

  let positiveItems = [];
  let negativeItems = [];
  let neutralItems = [];
  assetItems.forEach((e) => {
    if (e[1]["Net"] > 0) {
      positiveItems.push(e);
    } else if (e[1]["Net"] < 0) {
      negativeItems.push(e);
    } else {
      neutralItems.push(e);
    }
  });

  return (
    <table className="bg-white rounded-xl m-2 rounded-b-xl">
      <thead>
        <tr className="border-b-2 border-zinc-700">
          <th className="text-lg w-[15%]">Product</th>
          <th className="text-lg w-[8%]">
            <p>Current</p>
          </th>
          <th className="text-lg w-[8%]">
            <p>Initial</p>
          </th>
          <th className="text-lg w-[20%]">Net = Current - Initial</th>
          <th className="text-lg w-[8%]">€ Price</th>
          <th className="text-lg w-[15%]">Value = Net x Price</th>
        </tr>
      </thead>
      <tbody>
        {positiveItems.map((item) => (
          <tr className="bg-green-500" key={item[0]}>
            <td className="text-center text-base">{item[1]["Product"]}</td>
            <td className="text-center text-base">
              <p>{helpers.formatMoneyKMB(item[1]["Current"])}</p>
            </td>
            <td className="text-center text-base">
              <p>{helpers.formatMoneyKMB(item[1]["Initial"])}</p>
            </td>
            <td className="text-center text-base">{helpers.formatMoneyKMB(item[1]["Net"])}</td>
            <td className="text-center text-base">{item[1]["Current Price"]}</td>
            <td className="text-center text-base">{helpers.formatMoneyKMB(item[1]["Value"], 3)}</td>
          </tr>
        ))}
        {negativeItems.map((item) => (
          <tr className="bg-purple-400" key={item[0]}>
            <td className="text-center text-base">{item[1]["Product"]}</td>
            <td className="text-center text-base">
              <p>{helpers.formatMoneyKMB(item[1]["Current"])}</p>
            </td>
            <td className="text-center text-base">
              <p>{helpers.formatMoneyKMB(item[1]["Initial"])}</p>
            </td>
            <td className="text-center text-base">{helpers.formatMoneyKMB(item[1]["Net"])}</td>
            <td className="text-center text-base">{item[1]["Current Price"]}</td>
            <td className="text-center text-base">{helpers.formatMoneyKMB(item[1]["Value"], 3)}</td>
          </tr>
        ))}
        <tr className="bg-primary-blue border-b-2 border-zinc-700">
          <td className="text-center text-base">€ Cash</td>
          <td className="text-center text-base">
            <p>{helpers.formatMoneyKMB(props.data[EUR]["Current"])}</p>
          </td>
          <td className="text-center text-base">
            <p>{helpers.formatMoneyKMB(props.data[EUR]["Initial"])}</p>
          </td>
          <td className="text-center text-base">{helpers.formatMoneyKMB(props.data[EUR]["Net"])}</td>
          <td className="text-center text-base">1</td>
          <td className="text-center text-base">{helpers.formatMoneyKMB(props.data[EUR]["Value"], 3)}</td>
        </tr>
        <tr className="border-t-2 border-zinc-700 border-b-8">
          <td className="text-center text-lg">
            <b>Total</b>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className="text-center text-lg">
            <b>{helpers.formatMoneyKMB(totalValue, 3)}</b>
          </td>
        </tr>
        {neutralItems.map((item) => (
          <tr className="odd:bg-zinc-100" key={item[0]}>
            <td className="text-center text-base">{item[1]["Product"]}</td>
            <td className="text-center text-base">
              <p>{helpers.formatMoneyKMB(item[1]["Current"])}</p>
            </td>
            <td className="text-center text-base">
              <p>{helpers.formatMoneyKMB(item[1]["Initial"])}</p>
            </td>
            <td className="text-center text-base">{item[1]["Net"]}</td>
            <td className="text-center text-base">{item[1]["Current Price"]}</td>
            <td className="text-center text-base">{helpers.formatMoneyKMB(item[1]["Value"])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BalanceTable;
