import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import IcicleLabel from "./IcicleLabel";
import IcicleLeaf from "./IcicleLeaf";
import { ReportingIcicleProps, Tree } from "../../interfaces/ReportingIcicle";
import IcicleLegend from "./IcicleLegend";

const ReportingIcicleTree = ({ data, colorSetter, idSetter, timeFrame }: ReportingIcicleProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // update height and width on window resize
  const [width, setWidth] = useState(ref && ref.current ? (ref.current.clientWidth as number) : null);
  const [height, setHeight] = useState(ref && ref.current ? (ref.current.clientHeight as number) : null);

  const updateDimensions = () => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight - 140);
      setWidth(ref.current.clientWidth - 40);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setHeight(ref.current?.clientHeight ? ref.current.clientHeight - 140 : null);
    setWidth(ref.current?.clientWidth ? ref.current.clientWidth - 40 : null);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    setClickedLeaf(null);
    idSetter([]);
    colorSetter("#CCCCCC");
  }, [timeFrame]);

  const [clickedLeaf, setClickedLeaf] = useState<any>(null);
  const colorScale = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));

  /**
   * Calculates the hierarchy of the data using d3.hierarchy.
   * The hierarchy is sorted based on height and value.
   */
  const hierarchy = useMemo(() => {
    return d3
      .hierarchy(data)
      .sum((d) => d.value || 0) // Fix: Provide a default value of 0 for the 'value' property
      .sort((a, b) => b.height - a.height || (b.value || 0) - (a.value || 0));
  }, [data]);

  /**
   * Generates a root tree using the d3.partition function.
   *
   * @returns The generated root tree.
   */
  const root = useMemo(() => {
    const treeGenerator = d3.partition<Tree>().size([height || 0, ((hierarchy.height + 1) * (width || 0)) / 3]);
    return treeGenerator(hierarchy);
  }, [hierarchy, width, height]);

  const clicked = (event: any, leaf: any) => {
    setClickedLeaf(leaf);
    if (clickedLeaf != null && clickedLeaf.data.type === "node" && leaf.data.type === "node") return;

    let ids;

    if (clickedLeaf && leaf.data.name === clickedLeaf.data.name) {
      if (leaf.parent.data.type === "node") {
        idSetter([]);
        colorSetter(leaf.parent.data.color);
      } else if (leaf.parent.data.type === "leaf") {
        ids = leaf.parent.data.children.map((child: { id: any }) => child.id);
        idSetter(ids);
      }
      setClickedLeaf(clickedLeaf.parent);
    } else {
      if (leaf.data.type === "leaf") {
        colorSetter(leaf.data.color);
        ids = leaf.data.children.map((child: { id: any }) => child.id);
        idSetter(ids);
      } else if (leaf.data.type === "leafChild") {
        colorSetter(leaf.data.color);
        idSetter([leaf.data.id]);
      }
      setClickedLeaf(leaf);
    }
  };

  return (
    <div ref={ref} className="w-full h-full relative">
      <div className="min-h-[100px] flex">
        <IcicleLegend data={root} setClickedLeaf={setClickedLeaf} colorSetter={colorSetter} idSetter={idSetter} clickedLeaf={clickedLeaf} />
      </div>
      <div className="p-[20px] bg-white rounded-xl">
        <div className="overflow-hidden relative rounded-md" style={{ width: `${width}px`, height: `${height}px`, minHeight: "400px" }}>
          {/* /**
           * Renders a collection of rectangles representing data points in an icicle chart.
           */}
          {root.descendants().map((leaf, i) => {
            if (leaf.value === 0) {
              return null;
            }
            return (
              <div key={leaf.data.name + leaf.parent?.data.name} onClick={(event: any) => clicked(event, leaf)}>
                <IcicleLeaf leaf={leaf} clickedLeaf={clickedLeaf} width={width} height={height} colorScale={colorScale}>
                  <IcicleLabel leaf={leaf} />
                </IcicleLeaf>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReportingIcicleTree;
