import React from "react";
import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import { Field, Form, Formik, FormikProps } from "formik";
import TextField from "@material-ui/core/TextField";

function TextInput(props) {
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        {/* <label htmlFor={props.id || props.name}> */}
        {/*   {props.label} */}
        {/*   {props.required && "*"} */}
        {/* </label> */}
        <div>
          <TextField
            disableToolbar
            variant="outlined"
            fullWidth
            margin="normal"
            id={props.name}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            varaint="outlined"
            style={{ maxWidth: 300 }}
          />
        </div>
      </Grid>
    </>
  );
}

export default TextInput;
