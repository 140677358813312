import React from "react";
import MissingDealNumbers from "../../components/MissingDealNumbers/";
import "./DealManagementPage.scss";
import OverviewComments from "../../components/OverviewComments/OverviewComments";

function DealManagementPage() {
  return (
    <div className="root">
      <OverviewComments />
      <MissingDealNumbers />
    </div>
  );
}

export default DealManagementPage;
