import useTypedQuery from "../helpers/useTypedQuery";
import { ClientData } from "../../interfaces/Clients";

const GET_CLIENTS = `
	query getClients {
		clients {
			id
			companyName
		}
	}
`;

const useClients = () => useTypedQuery<ClientData, undefined>(GET_CLIENTS)?.dataFromQuery;

export default useClients;
