import React from "react";
import AddFormClient from "../AddFormClient/AddFormClient";
import FormModal from "./FormModal.js";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

function AddClientModal(props) {
  return (
    <FormModal buttonText="Create new client" ButtonIcon={PersonAddIcon}>
      <AddFormClient newInput={true} refetch={props.refetch} />
    </FormModal>
  );
}

export default AddClientModal;
