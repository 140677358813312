import React, { useState, useEffect, useMemo } from "react";
import AddFormGeneric from "../../components/AddFormGeneric/AddFormGeneric";
import { gql } from "@apollo/client";
import { toast } from "react-toastify";
// import { withRouter  } from "react-router-dom";
// import "./AddDealPage.css"

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});

const AddFormTermsRegion = (props) => {
  const graphqlQuery = useMemo(
    () => gql`
      query getProductOptions {
        categories {
          id
          name
        }
        brokers {
          id
          brokerName
        }
      }
    `,
    []
  );

  const formFieldsArray = [
    // {title: "Product Info", fieldType: "titleSection", rank: 0},
    { name: "description", label: "Terms Region", rank: 1, fieldType: "text", yupType: "string", required: true, defaultValue: "" },
    { name: "newLine", rank: 2.9, fieldType: "newLine" },
  ];

  const [formFields, setFormFields] = useState(formFieldsArray);
  const [initialValues, setInitialValues] = useState(() => (props.initialValues ? props.initialValues : getInitialValues(formFields)), []);

  useEffect(() => {
    let newInitialValues;
    if (props.searchInput || props.searchInput === "") {
      newInitialValues = Object.keys(initialValues).reduce((acc, initialValueKey) => {
        if (initialValueKey === "name") {
          return { ...acc, name: props.searchInput };
        }
        if (initialValueKey === "newLine") {
          return { ...acc };
        }
        const fieldValuePair = {};
        fieldValuePair[initialValueKey] = initialValues[initialValueKey];
        return { ...acc, ...fieldValuePair };
      }, {});
      setInitialValues(() => newInitialValues);
    }
  }, [props.searchInput]);

  const mutationKeys = (values) => {
    const variables = {
      description: values.description,
    };
    return variables;
  };

  const graphqlMutation = gql`
    mutation TermsRegion($description: String!) {
      termsregion(description: $description) {
        id
        description
      }
    }
  `;

  const afterSubmit = (values) => {
    console.log("afterSubmit");
    toast.success(`New TermsRegon submitted: ${values.description ? values.description : undefined}.`);
    if (props.closeAfterSubmit) {
      props.closeAfterSubmit();
    }
    if (props.refetch) {
      props.refetch();
    }
  };

  return (
    <>
      <AddFormGeneric
        className="addFormGeneric"
        formFields={formFields}
        initialValues={initialValues}
        newInput={true}
        graphqlQuery={graphqlQuery}
        graphqlMutation={graphqlMutation}
        mutationKeys={mutationKeys}
        graphql={true}
        afterSubmit={afterSubmit}
      />
    </>
  );
};

// export default withRouter(AddDealForm)
export default AddFormTermsRegion;
