import helpers from "../../services/helpers";

export const PaymentPerformanceClientlist = ({ data, clientListPosition, containerPosition, barWidth, clickLocation, xScale, lastOfTypePaid }) => {
  const clientListWidth = 280;

  const indicator = 210;

  const indicatorOffset = barWidth - indicator;

  const clientListOffset = barWidth - clientListWidth;

  const legendPosition = data.label === "Paid" ? xScale(lastOfTypePaid.cumulative) : xScale(data.cumulative);

  const position = () => {
    if (clickLocation === "bar") {
      if (clientListOffset < clientListPosition.x) {
        return clientListOffset;
      } else {
        return clientListPosition.x - containerPosition;
      }
    }
    if (clickLocation === "legend") {
      if (clientListOffset < legendPosition) {
        return clientListOffset;
      } else {
        return legendPosition;
      }
    }
  };

  const indicatorPosition = () => {
    if (clickLocation === "bar") {
      if (indicatorOffset < clientListPosition.x) {
        return clientListPosition.x - containerPosition - clientListOffset;
      } else {
        return 4;
      }
    }
    if (clickLocation === "legend") {
      if (clientListOffset < legendPosition) {
        return legendPosition - containerPosition - clientListOffset + 80;
      } else {
        return 4;
      }
    }
  };

  return (
    <div className="w-40 absolute bottom-19 z-[9999999] transition-all h-auto" style={{ left: position() }}>
      <div
        className="w-0.5 h-2 ml-2"
        style={{ background: data.label === "?" || data.type === "future" ? "#202017" : `${data.color}`, marginLeft: indicatorPosition() }}
      ></div>
      <div className=" ClientList">
        <div className="client-list-heading">
          {data.label === "?" ? <p>-</p> : <p> {data.clients ? data.clients?.length : 0} clients</p>}
          <p>Revenue:</p>
          <p style={{ background: data.type === "future" ? "#b5b5b5" : `${data.color}`, color: data.label === "?" ? "#202017" : "#FFFFFF" }}>{data.label}</p>
        </div>
        <div className="client-list-container">
          {data.clients &&
            data.clients.map((client, index) => {
              return (
                <div key={index} className="client-list-item">
                  <p>{client.client.companyName}</p>
                  <p>{helpers.centsToFormattedAmount(client.sum, "EUR")}</p>
                </div>
              );
            })}
          {data.label === "?" && (
            <div className="client-list-item">
              <p>Unknown</p>
              <p>{helpers.formatMoney(data.expectedRevenue, "EUR")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPerformanceClientlist;
