import LegendItem from "./LegendItem";

export const PaymentPerfomanceLegend = ({ data, paidEur, location, setIsClicked, thisYear, hasNegativeValue }) => {
  const groupedData = data?.reduce((result, item) => {
    let label;
    let color;
    if ((item.type === "thisyear" && item.label === thisYear) || item.type === "past" || item.label === "Paid") {
      label = "paid";
      color = "#22B0FF";
    } else if (item.label === "?" || item.label === "Overdue") {
      label = item.label;
      color = item.label === "?" ? "#e8e8e8" : "#FF0000";
    } else if (item.label === "Due") {
      label = item.label;
      color = "#007bff";
    } else if (item.label === "Overdue > 90 days") {
      label = item.label;
      color = "#d80000";
    } else {
      label = item.type;
      color = "#FFFFFF";
    }

    const group = result.find((group) => group.label === label);

    if (group) {
      group.data.push(item);
    } else {
      result.push({ label, color, data: [item] });
    }

    return result;
  }, []);

  return (
    <>
      <div className="flex gap-2 lg:gap-4 items-center border border-gray-light px-2 py-0.5 rounded-xl flex-wrap h-9 relative max-w-fit mt-8">
        {groupedData?.map((data, i) => (
          <div
            key={data.id}
            onClick={(e) => {
              e.preventDefault();

              if (data.label !== "future") {
                setIsClicked(data.label === "paid" ? paidEur : data.data[0]);
              }
            }}
          >
            <LegendItem data={data} location={location} hasNegativeValue={hasNegativeValue}></LegendItem>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaymentPerfomanceLegend;
