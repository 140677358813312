import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const TablePageNavigation = (
  props
  // gotoPage,
  // canPreviousPage,
  // previousPage,
  // nextPage,
  // canNextPage,
  // pageCount,
  // pageIndex,
  // pageOptions,
  // pageSize,
  // setPageSize
) => {
  // console.log( gotoPage,
  // canPreviousPage,
  // previousPage,
  // nextPage,
  // canNextPage,
  // pageCount,
  // pageIndex,
  // pageOptions,
  // pageSize,
  // setPageSize)

  if (props) {
    return (
      <div className="pagination clientPagePagination flex flex-wrap">
        <div className="w-full lg:w-1/2 flex items-end justify-between">
          <div>
            <span>
              {/* Page{" "} */}
              <strong>{props.pageIndex + 1}</strong>
              {" of "}
              <strong>{props.pageOptions.length}</strong>
            </span>
          </div>
          <span>go to page: </span>
          <TextField
            className="number-input"
            id="outlined-number"
            defaultValue={props.pageIndex + 1}
            label=""
            type="number"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              props.gotoPage(page);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="outlined-select-currency"
            select
            label=" "
            value={props.pageSize}
            onChange={(e) => {
              props.setPageSize(Number(e.target.value));
            }}
            helperText=""
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "orange" },
              "& .MuiInput-underline:after": { borderBottomColor: "orange" },
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                Show {pageSize}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
          <button onClick={() => props.gotoPage(0)} disabled={!props.canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => props.previousPage()} disabled={!props.canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => props.nextPage()} disabled={!props.canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => props.gotoPage(props.pageCount - 1)} disabled={!props.canNextPage}>
            {">>"}
          </button>{" "}
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default TablePageNavigation;
