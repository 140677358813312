import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { QueryDealsInput, DealstatisticsQuery } from "../../codegen/gql/graphql";

const GET_DEALSTATISTICS = graphql(`
  query dealstatistics($input: QueryDealsInput) {
    dealstatistics(input: $input) {
      after
      before
      brokers {
        id
        brokerName
        userInfo {
          imageUrl
        }
      }
      clientStatistics {
        client {
          id
          companyName
          createdAt
          broker {
            id
            brokerName
          }
        }
        commissionInEur
      }
      commissionTotalEur
      dealCount
      distinctBrokerCount
      distinctClientCount
      distinctProductCount
      productStatistics {
        product {
          id
          category {
            id
            name
          }
          name
        }
        commissionInEur
      }
      totalVolumePerUnit {
        product {
          name
          id
        }
        volumePerUnit {
          unit {
            descriptionShort
            description
          }
          totalVolume
        }
      }
    }
  }
`);

const useDealNotesOverview = (input: QueryDealsInput) => useGeneratedTypeQuery<DealstatisticsQuery, QueryDealsInput>(GET_DEALSTATISTICS, input);

export default useDealNotesOverview;
