import * as React from "react";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import BidOfferEntry from "../BidOfferEntry";
import olyxbase from "../../services/olyxbase";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Button from "@material-ui/core//Button";
import Dialog from "@material-ui/core//Dialog";
import DialogActions from "@material-ui/core//DialogActions";
import DialogContent from "@material-ui/core//DialogContent";
import DialogContentText from "@material-ui/core//DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BidOfferEntryModal() {
  const [open, setOpen] = React.useState(false);
  const [stacks, setStacks] = React.useState({});
  const [lastUpdate, setLastUpdate] = React.useState(new Date().toLocaleString());
  const [dhl, setDhl] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogError, setDialogError] = React.useState("");

  const handleCloseError = () => {
    setDialogOpen(false);
  };

  const handleRefresh = () => {
    document.location.reload();
  };

  const queryClient = useQueryClient();

  const updateBo = async (product, amt, extraInfo, sound, priceType = "price") => {
    console.log(amt);

    updateBidOfferMutation.mutate({
      product,
      valInt: amt,
      extraInfo,
      priceType,
      lastUpdateTimestamp: priceType == "bid" ? product.bidUpdateTimeStamp : product.askUpdateTimeStamp,
    });
  };

  /**
   * @param  {product, valInt, extraInfo, priceType, lastUpdateTimestamp} updateData
   */
  const updateBidOfferMutation = useMutation(
    (updateData) => {
      return olyxbase.updatePrice(updateData.product, updateData.valInt, updateData.extraInfo, updateData.priceType, updateData.lastUpdateTimestamp);
    },
    {
      onMutate: async (updateData) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries("prices");

        const previousPrices = queryClient.getQueryData("prices");

        let updatedTimeStamp = new Date();

        const modifiedPrices = previousPrices.map((x) =>
          x.productId === updateData.product.productId
            ? {
                ...x,
                priceInt: updateData.priceType == "bid" ? x.askInt / updateData.valInt / 2 + updateData.valInt : updateData.valInt / x.bidInt / 2 + x.bidInt,
                bidInt: updateData.priceType == "bid" ? updateData.valInt : x.bidInt,
                askInt: updateData.priceType == "ask" ? updateData.valInt : x.askInt,
                extraInfo: updateData.extraInfo,
                lastUpdateTimestamp: updateData.lastUpdateTimestamp,
                bidUpdateTimeStamp: updateData.priceType == "bid" ? updatedTimeStamp : x.bidUpdateTimeStamp,
                askUpdateTimeStamp: updateData.priceType == "ask" ? updatedTimeStamp : x.askUpdateTimeStamp,
                outlined: "outlined",
              }
            : x
        );

        // Optimistically update to the new value
        queryClient.setQueryData("prices", modifiedPrices);

        //TODO: Do we need to update candles on this screen ?
        // Update candle
        const oldCandles = queryClient.getQueryData("candles");
        // let newCandles = updateTemporaryStateCandle(oldCandles, updateData.product.productId, updateData.valInt / (10 ** 2));
        // queryClient.setQueryData('candles', newCandles)

        // Return a context with the previous and new todo
        return { previousPrices, oldCandles, updateData };
      },
      // If the mutation fails, use the context we returned above
      onError: (err, modifiedPrices, context) => {
        console.log("ON error");
        console.log(err);
        setDialogError(err.toString());
        setDialogOpen(true);
        queryClient.setQueryData("prices", context.previousPrices);
        queryClient.setQueryData("candles", context.oldCandles);
      },
      onSettled: (modifiedPrices) => {
        queryClient.invalidateQueries("ticker");
        queryClient.invalidateQueries("prices");
        queryClient.invalidateQueries("candles");
      },
    }
  );

  const setDisableHoverListen = (dhld) => {
    setDhl(dhld);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pricesQ = useQuery("prices", olyxbase.getPrices);

  React.useEffect(() => {
    if (pricesQ.isSuccess && pricesQ.data) {
      let prices = pricesQ.data;

      let tmpStacks = {
        group1: {},
        group2: {},
        group3: {},
        group4: {},
        group5: {},
        group6: {},
      };

      let thgPrices = prices.filter((price) => price.productName && price.inputCategory === 1);
      let hbePrices = prices.filter((price) => price.productName && price.inputCategory === 2);
      let rtfcPrices = prices.filter((price) => price.productName && price.inputCategory === 3);
      let vtgPrices = prices.filter((price) => price.productName && price.inputCategory === 4);
      let biomPrices = prices.filter((price) => price.productName && price.inputCategory === 5);
      let hvoPrices = prices.filter((price) => price.productName && price.inputCategory === 6);

      let dates = prices.map((e) => new Date(e.priceUpdateTimestamp));
      let latestUpdateTime = new Date(Math.max(...prices.map((e) => new Date(e.priceUpdateTimestamp ?? 0))));
      setLastUpdate(latestUpdateTime.toLocaleDateString() + " " + latestUpdateTime.toLocaleTimeString());

      tmpStacks.group1.category = "THG-Quote (€/mt)";
      tmpStacks.group1.products = thgPrices;

      tmpStacks.group2.category = "HBE (€/Gj)";
      tmpStacks.group2.products = hbePrices;

      tmpStacks.group3.category = "RTFC (p/RTFC)";
      tmpStacks.group3.products = rtfcPrices;

      tmpStacks.group4.category = "VertiCer (€/MWh HHV)";
      tmpStacks.group4.products = vtgPrices;

      tmpStacks.group5.category = "German biomethane (€/MWh HHV)";
      tmpStacks.group5.products = biomPrices;

      tmpStacks.group6.category = "HVO ($/m3)";
      tmpStacks.group6.products = hvoPrices;

      setStacks(tmpStacks);
    }
  }, [pricesQ.data]);

  return (
    <>
      <div>
        <IconButton color="inherit" onClick={handleOpen}>
          <ImportExportIcon />
        </IconButton>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ width: "90%", height: "80%", margin: "auto", paddingTop: 20, color: "#000" }}
        >
          <div>
            <BidOfferEntry stacks={stacks} updateBo={updateBo} lastUpdate={lastUpdate} dhl={dhl} setDisableHoverListen={setDisableHoverListen} />
          </div>
        </Modal>
      </div>

      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Update Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your last update was not succesful due to an error, and will be reverted. Refreshing the page can often help.
            <br />
            {dialogError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>Close</Button>
          <Button onClick={handleRefresh} autoFocus>
            Refresh Page
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
