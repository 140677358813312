import React, { useState } from "react";

const PlayerAvatar = (props) => {
  return (
    <div>
      <img
        className="m-auto h-24 w-24 rounded-full border border-black border-solid bg-gray-light transition
                            group-hover:scale-105 group-hover:-translate-y-1 group-hover:shadow-[1px_4px_3px_2px_rgba(0,0,0,0.3)] duration-300"
        alt="robohash of participant"
        src={`https://robohash.org/${props.address}`}
      />
      <p className="text-2xl text-black text-center font-title">{props.alias || "-"}</p>
    </div>
  );
};

export default PlayerAvatar;
