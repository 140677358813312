import React from "react";
import FormModal from "./FormModal.js";
import AddFormTermsRegion from "../AddFormOther/AddFormTermsRegion";
// import AddLocationAltIcon from '@material-ui/icons/AddLocationAlt';
import AddLocationIcon from "@material-ui/icons/AddLocation";

function TermsRegionModal(props) {
  return (
    <FormModal buttonText="Create new terms region" ButtonIcon={AddLocationIcon}>
      <AddFormTermsRegion newInput={true} refetch={props.refetch} />
    </FormModal>
  );
}

export default TermsRegionModal;
