import { withRouter } from "react-router-dom";
import data from "./scopeOneBidAsk.json";
import { StreamGraph } from "./StreamGraph/StreamGraph";
import { StackedAreaChart } from "./StackedAreaChart/StackedAreaChart";
import React, { useState } from "react";
import { format } from "date-fns";
import { LineGraph } from "./LineGraph/LineGraph";

function SpreadVisualiserPage() {
  let combinedProducts = [
    { productName: "VertiCer 24 ISCC Sub", color: "#94B54E", type: "ticket" },
    { productName: "VertiCer 24 ISCC Unsub", color: "#AD7449", type: "ticket" },
    { productName: "HBE 24 A", color: "#373E54", type: "ticket" },
    { productName: "HBE 24 B", color: "#68486c", type: "ticket" },
    { productName: "HBE 24 O", color: "#3d575d", type: "ticket" },
    { productName: "HVO Premium UCO Spot", color: "#696A70", type: "physical" },
    { productName: "THG 24 O", color: "#161F1F", type: "ticket" },
    { productName: "THG 24 C", color: "#67C4E1", type: "ticket" },
    { productName: "THG 24 A (DC)", color: "#ff960a", type: "ticket" },
    { productName: "UER 24", color: "#AF8B77", type: "ticket" },
    { productName: "RTFC 24 NC", color: "#2196f3", type: "ticket" },
    { productName: "Biomethane -100 GER 24", color: "#E64D9A", type: "physical" },
    { productName: "HVO Premium POME Spot", color: "#ffd000", type: "physical" },
  ];

  const targetProducts = combinedProducts.map((product) => product.productName);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleCheckboxChange = (productObj) => {
    setSelectedProducts((prevSelected) => {
      const isAlreadySelected = prevSelected.some((sp) => sp.productName === productObj.productName);
      if (isAlreadySelected) {
        return prevSelected.filter((sp) => sp.productName !== productObj.productName);
      } else {
        return [...prevSelected, productObj];
      }
    });
  };

  const formattedData = (data, targetProducts) => {
    const groupedByProduct = targetProducts.reduce((acc, product) => {
      const filteredData = data.filter((item) => item.product === product);

      // Add to accumulator if filteredData is not empty
      if (filteredData.length) {
        acc[product] = filteredData;
      }
      return acc;
    }, {});

    const allDates = [...new Set(data.map((item) => item.date))].sort();

    const spreadPercentageByDate = allDates.reduce((acc, date) => {
      // Initialize an object for this date
      acc[date] = {};

      Object.keys(groupedByProduct).forEach((product) => {
        // Find the entry for this product on this date
        const entry = groupedByProduct[product].find((e) => e.date === date);
        if (entry) {
          // Calculate the spread percentage
          const spreadPercentage = +(((entry.ask - entry.bid) / entry.mid) * 100).toFixed(2);
          // Store the spread percentage for this product on this date
          acc[date][product] = spreadPercentage;
          acc[date][`${product}-mid`] = entry.mid;
        }
      });

      return acc;
    }, {});

    const spreadPercentageArray = Object.keys(spreadPercentageByDate).map((date, index) => {
      // Initialize the object for this entry with x being the index + 1
      let entry = { x: index + 1 };
      entry.date = format(new Date(date), "yyyy-MM-dd");
      // Add each product's spread percentage to the entry
      Object.keys(spreadPercentageByDate[date]).forEach((product) => {
        entry[product] = spreadPercentageByDate[date][product];
      });
      return entry;
    });
    return { spreadPercentageArray };
  };

  const { spreadPercentageArray } = formattedData(data, targetProducts);

  return (
    <>
      <div className="w-[100vw] bg-white flex gap-[16px] justify-center p-8">
        <section className="shadow-md w-[392px] text-[20px] top-20 left-20 rounded-xl bg-[#3C3C3C] p-8 pt-4 px-6">
          <span className="mb-2 text-white">Spread visualiser</span>
          <p className="text-[#e3e3e3] text-[16px]" style={{ fontFamily: "Gilroy Medium" }}>
            This page visualises the spread percentages of different products over time. Select the products you want to compare by clicking on the toggles on
            either side.
          </p>
        </section>
        <section className="shadow-md w-[392px] text-[20px] top-20 left-20 rounded-xl bg-[#3C3C3C] p-8 pt-4 px-6">
          <p className="text-[#e3e3e3] text-[14px] pt-7" style={{ fontFamily: "Gilroy Medium" }}>
            For a long period of time we have been submitting bids and asks while only logging the mid price in our databases, since quite recently we started
            logging the bid and ask seperately allowing for different visualisations to indicate liquidity.
          </p>
        </section>
      </div>
      <div className="relative bg-white min-h-fit w-[100vw] pt-[20px] pb-[120px] flex justify-start flex-wrap items-center">
        <h3 className="rounded-tl-xl rounded-tr-xl bg-[#eaeaea] w-[7600px] text-[24px] mx-[calc(50%-400px)] px-4 pt-4">Spread percentages</h3>
        <div className="w-[100vw] h-[300px] fixed top-[300px]">
          <div className="w-[240px] relative left-[calc(50%-640px)]">
            <h2 className="text-[20px] w-full text-center pb-4">Tickets</h2>
            {combinedProducts.map(({ productName, color, type }) => (
              <>
                {type === "ticket" && (
                  <label className={"w-[240px] flex h-6 flex-wrap items-center justify-between gap-2"} key={productName}>
                    <input
                      className="hidden"
                      type="checkbox"
                      id={productName}
                      name={productName}
                      value={productName}
                      checked={selectedProducts.some((sp) => sp.productName === productName)}
                      onChange={() => handleCheckboxChange({ productName, color })}
                    />
                    {productName}
                    <div className="bg-[#eaeaea] relative w-[48px] h-[20px] rounded-tl-[12px] rounded-bl-[12px]">
                      <div
                        style={{ backgroundColor: selectedProducts.some((sp) => sp.productName === productName) ? color : "#303535" }}
                        className={`transition-all  absolute top-[3px] h-[14px] w-[14px] rounded-full ${
                          selectedProducts.some((sp) => sp.productName === productName) ? "left-[30px]" : "left-[4px]"
                        }`}
                      ></div>
                    </div>
                  </label>
                )}
              </>
            ))}
          </div>
          <div className="w-[240px] absolute right-[calc(50%-640px)] top-0">
            <h2 className="text-[20px] w-full text-center pb-4">Physical</h2>
            {combinedProducts.map(({ productName, color, type }) => (
              <>
                {type === "physical" && (
                  <label className={"w-[240px] flex h-6 flex-wrap items-center justify-start gap-2"} key={productName}>
                    <div className="bg-[#eaeaea] relative w-[48px] h-[20px] rounded-tr-[12px] rounded-br-[12px]">
                      <div
                        style={{ backgroundColor: selectedProducts.some((sp) => sp.productName === productName) ? color : "#303535" }}
                        className={`transition-all  absolute top-[3px] h-[14px] w-[14px] rounded-full ${
                          selectedProducts.some((sp) => sp.productName === productName) ? "left-[4px]" : "left-[30px]"
                        }`}
                      ></div>
                    </div>
                    {productName}
                    <input
                      className="hidden"
                      type="checkbox"
                      id={productName}
                      name={productName}
                      value={productName}
                      checked={selectedProducts.some((sp) => sp.productName === productName)}
                      onChange={() => handleCheckboxChange({ productName, color })}
                    />
                  </label>
                )}
              </>
            ))}
          </div>
        </div>

        <div className=" shadow-sm mx-[calc(50%-400px)] overflow-hidden flex justify-start items-center">
          <StackedAreaChart data={spreadPercentageArray} products={selectedProducts} width={800} height={300} />
        </div>
        <h3 className="bg-[#eaeaea] w-[800px] text-[24px] mx-[calc(50%-400px)] px-4">Stacked spreads</h3>
        <div className="w-fit shadow-sm overflow-hidden flex justify-start items-center mx-[calc(50%-400px)]">
          <StreamGraph data={spreadPercentageArray} products={selectedProducts} width={800} height={200} />
        </div>
        <h3 className="bg-[#eaeaea] w-[800px] text-[24px] mx-[calc(50%-400px)] px-4">Mid price</h3>

        <div className="rounded-bl-xl rounded-br-xl w-fit shadow-sm overflow-hidden flex justify-start items-center mx-[calc(50%-400px)]">
          <LineGraph data={spreadPercentageArray} products={selectedProducts} width={800} height={300} />
        </div>
      </div>
    </>
  );
}

export default withRouter(SpreadVisualiserPage);
