import React from "react";
import "./BidOfferEntryPriceRow.css";

import PriceChangePopup from "../PriceChangePopup/PriceChangePopup";
import "../../pages/Dashboard/Dashboard.css";

export default function Bidaskentrypricerow({ product, updateBo, dhl, setDisableHoverListen }) {
  return (
    <div className="pricerow-row">
      <div className="pricerow-product pricerow ">
        {product.shortName ? product.shortName : product.productName} {product.shortName ? "" : product.productInfo}
      </div>
      <div className="pricerow-price pricerow">
        <PriceChangePopup
          product={product}
          lastTimeStamp={product.priceUpdateTimestamp}
          disableHoverListen={dhl}
          setDisableHoverListen={setDisableHoverListen}
          nudgePrice={updateBo}
          priceType="bid"
        />
      </div>
      <div className="pricerow-price pricerow ">
        <PriceChangePopup
          product={product}
          lastTimeStamp={product.priceUpdateTimestamp}
          disableHoverListen={dhl}
          setDisableHoverListen={setDisableHoverListen}
          nudgePrice={updateBo}
          priceType="ask"
        />
      </div>
    </div>
  );
}
