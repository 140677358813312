import { gql } from "@apollo/client";

const MUTATION_PICKUP_DEALNOTE = gql`
  mutation PickUpDealNote($id: ID!, $status: DealNoteStatus!) {
    pickupdealnote(id: $id, status: $status) {
      ... on Error {
        errorCode
        message
      }
      ... on DealNote {
        id
        createdAt
        createdBy {
          id
          fullName
          imageUrl
          initials
        }
        inProgressAt
        inProgressBy {
          id
          fullName
          imageUrl
        }
        doneAt
        doneBy {
          id
          fullName
          imageUrl
        }
        status
        note
        deal {
          id
          olyxRef
        }
        mentions {
          userInfo {
            id
            userUid
          }
        }
      }
    }
  }
`;

export default MUTATION_PICKUP_DEALNOTE;
