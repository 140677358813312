import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import AddchartIcon from "@mui/icons-material/Addchart";
import { ReactComponent as OlyxInternationaldiversity } from "../../assets/customicons/OlyxInternationaldiversity.svg";

function TotalBrokers(props) {
  const [isHovered, setIshovered] = useState(false);

  const overlayVariants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        duration: 0.3,
        delayChildren: 0.4,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        duration: 0.3,
        delay: 0.4,
      },
    },
  };

  const buttonVariants = {
    normal: {
      scale: 1,
    },
    hover: { scale: 1 },
    tap: {
      scale: 0.95,
    },
  };

  return (
    <>
      {props && props.dealStatistics && props.dealStatistics.distinctBrokerCount >= 0 && (
        <>
          <div className="inline-block relative" onClick={props.onClick}>
            <motion.div
              variants={buttonVariants}
              whileTap="tap"
              whileHover="hover"
              className={`cursor-pointer inline-block gap-2 border border-gray-light p-2 rounded-xl text-base bg-white
              }`}
              onMouseEnter={(e) => {
                setIshovered(true);
              }}
              onMouseLeave={(e) => {
                setIshovered(false);
              }}
            >
              <span className="text-gray-dark mr-2 inline-block">{props.dealStatistics.distinctBrokerCount === 1 ? "Broker" : "Brokers"}</span>
              <span className={`inline ${props.dealStatistics.distinctBrokerCount > 0 ? `mr-2 ` : ""}`}>{props.dealStatistics.distinctBrokerCount}</span>
              <motion.div variants={overlayVariants} whileHover={{ rotate: -90 }} className=" align-middle inline-block mr-2">
                <OlyxInternationaldiversity style={{ hight: 20, width: 20 }}></OlyxInternationaldiversity>
              </motion.div>
            </motion.div>
            <AnimatePresence>
              {isHovered && (
                <motion.div
                  className="bg-black absolute z-40 p-2 h-auto bg-opacity-60 rounded-xl avatarHover"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 3 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="text-white  text-center align-middle flex-wrap h-full w-full mx-auto">
                    <span>view network graph</span>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </>
      )}
    </>
  );
}

export default TotalBrokers;
