import React from "react";
import "./BidOfferEntryTable.css";

import BidOfferEntryPriceHeader from "../BidOfferEntryPriceHeader";
import BidOfferEntryPriceRow from "../BidOfferEntryPriceRow";

export default function BidOfferEntryTable({ category, products, updateBo, dhl, setDisableHoverListen }) {
  return (
    <div className="price-header-table">
      <BidOfferEntryPriceHeader category={category} />

      {products
        .sort((a, b) => (a ? a.inputOrder : 0) - (b ? b.inputOrder : 0))
        .map((product) => {
          return (
            <BidOfferEntryPriceRow key={product.productId} product={product} updateBo={updateBo} dhl={dhl} setDisableHoverListen={setDisableHoverListen} />
          );
        })}
    </div>
  );
}
