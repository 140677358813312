import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CssTextField from "./CssTextField";

function Immutable({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <div style={{ padding: 0 }}>
          <label htmlFor={props.id || props.name}>{label}</label>
          <div />
          <div>
            <CssTextField
              // className="text-input"
              fullWidth={true}
              // style={{ maxWidth: 300 }}
              disabled={true}
              {...field}
              {...props}
              variant="outlined"
              inputProps={{ style: { color: "#22b0ff" } }}
            />
          </div>
          {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </div>
      </Grid>
    </>
  );
}

export default Immutable;
