import React from "react";

function TotalDeals(props) {
  return (
    <>
      {props && "dealCount" in props && (
        <>
          <div className="inline-block gap-2 border border-gray-light px-2 py-2 rounded-xl text-base bg-white">
            <span className="text-gray-dark mr-2">{props.dealCount === 1 ? "Deal" : "Deals"}</span>
            <span>{props.dealCount}</span>
          </div>
        </>
      )}
    </>
  );
}

export default TotalDeals;
