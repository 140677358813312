import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

function FormModal(props) {
  const [open, setOpen] = useState(props.open ? props.open : false);
  const [userIsWarned, setUserIsWarned] = useState(false);
  const [toastId, setToastId] = useState("");

  const handleClose = () => {
    if (userIsWarned) {
      setOpen(false);
      setUserIsWarned(false);
      toast.dismiss(toastId);
      setToastId("");
    } else {
      setToastId(() =>
        toast("Are you sure? Closing modal will result in loss of any unsubmitted data", {
          autoClose: false,
        })
      );
      setUserIsWarned(true);
    }
  };

  const handleOpen = () => {
    setUserIsWarned(false);
    setOpen(true);
  };

  const closeAfterSubmit = () => {
    // assuming submit succeeded, modal will be closed. Note: does not check for data loss
    setOpen(false);
    setUserIsWarned(false);
    setToastId("");
  };

  return (
    <div>
      <Button type="submit" color="default" variant="text" onClick={handleOpen} fullWidth>
        {props.ButtonIcon ? <props.ButtonIcon /> : ""}
        {props.buttonText ? props.buttonText : "Add"}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          width: "60%",
          height: "80%",
          margin: "auto",
          paddingTop: 20,
          color: "#000",
        }}
      >
        {/* <AddFormClient newInput={true} /> */}
        {React.cloneElement(props.children, {
          closeAfterSubmit: closeAfterSubmit,
        })}
      </Modal>
    </div>
  );
}

export default FormModal;
