import React, { useRef, useState, useEffect } from "react";
import { IcicleLeafProps } from "../../interfaces/ReportingIcicle";

const IcicleLeaf = ({ leaf, clickedLeaf, colorScale, width, height, children }: IcicleLeafProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [heigtOfRect, setHeight] = useState(ref && ref.current ? (ref.current.clientWidth as number) : null);
  const parentName = leaf.data?.category || "";
  const color = colorScale(parentName);

  /**
   * Retrieves the category of the clicked leaf, if available.
   */
  const clickedCategory: string = clickedLeaf && leaf.data && leaf.data.category && clickedLeaf.data && clickedLeaf.data.category;

  /**
   * Calculates the height of a rectangle based on its data.
   */
  const rectHeight = (d: any) => {
    return d.x1 - d.x0 - Math.min(1, (d.x1 - d.x0) / 2);
  };

  const updateDimensions = () => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setHeight(ref.current?.clientHeight ? ref.current.clientHeight : null);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const showLabel = (clickedCategory ? ((leaf.x1 - clickedLeaf.x0) / (clickedLeaf.x1 - clickedLeaf.x0)) * (height ?? 0) : rectHeight(leaf) + 1) > 20;

  return (
    <div
      ref={ref}
      style={{
        width: `${leaf.y1 - leaf.y0 - 1}px`,
        height: `${clickedCategory ? ((leaf.x1 - clickedLeaf.x0) / (clickedLeaf.x1 - clickedLeaf.x0)) * (height ?? 0) : rectHeight(leaf) + 1}px`,
        top: `${
          clickedCategory ? (clickedLeaf.x0 > leaf.x0 ? 0 : ((leaf.x0 - clickedLeaf.x0) / (clickedLeaf.x1 - clickedLeaf.x0)) * (height ?? 0)) : leaf.x0
        }px`,
        left: `${
          clickedCategory && clickedLeaf.data.type === "leaf"
            ? leaf.y0 - (width != null ? width * 0.33 : 0) - 1
            : clickedCategory && clickedLeaf.data.type === "leafChild"
            ? leaf.y0 - (width != null ? width * 0.66 : 0) - 2
            : leaf.y0
        }px`,
        backgroundColor: `${leaf.data.color}`,
        position: "absolute",
      }}
      className={"transition ease-in-out cursor-pointer border-t border-white d" + (clickedCategory ? " zoomed " : " zoomedOut ")} // Apply zoomed class if clicked
    >
      {showLabel && children}
    </div>
  );
};

export default IcicleLeaf;
