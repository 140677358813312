import React from "react";
import AddFormClient from "../../components/AddFormClient/AddFormClient";
import { withRouter } from "react-router-dom";
import "./AddClientPage.css";

function AddClientPage() {
  return <AddFormClient newInput={true} />;
}

export default withRouter(AddClientPage);
