import helpers from "../../services/helpers";

export const BarplotTooltip = ({ data, tooltipPosition, containerPosition }) => {
  const paddingOffset = 71;

  return (
    <div
      className="absolute -top-0 bg-black/75 px-4 py-2 rounded-lg	text-white z-[999999999] w-40"
      style={{ left: tooltipPosition.x - paddingOffset - containerPosition }}
    >
      <div className=" text-xs text-center">
        <span className="block">{data.label}</span>
        <span className="block">{helpers.formatMoney(data.expectedRevenue, "EUR")}</span>
      </div>
    </div>
  );
};

export default BarplotTooltip;
