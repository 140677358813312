import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

function Toggle(props) {
  const onSubmit = (values) => {};
  const validationSchema = { after: Yup.date().min("2010-01-01", "Must be in 2010 or later") };

  return (
    <Formik
      initialValues={undefined}
      validationSchema={Yup.object(validationSchema)}
      // enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form>
        {props && props.formFields && props.formFields.hasOpenDealNote && (
          <>
            <div className="mt-3 mr-4 toggle">
              <input
                id="switch"
                type="checkbox"
                checked={props.formFields.hasOpenDealNote.value}
                onChange={props.formFields.hasOpenDealNote.onChange}
                name={props.formFields.hasOpenDealNote.name}
              />
              <label label={props.formFields.hasOpenDealNote.label} htmlFor="switch">
                {props.formFields.hasOpenDealNote.value === false ? "Requires attent" : "See all"}
              </label>
            </div>
          </>
        )}
      </Form>
    </Formik>
  );
}

export default Toggle;
